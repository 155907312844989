import React, { useEffect } from "react";
import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import * as constant from "../Constants/constant";
import { useSelector, useDispatch } from "react-redux";
import "../style.css";
import Popover from "@mui/material/Popover";
import UserPopover from "../userpopover";
import { setShow } from "../../store/appslice";
import { sanitizeUserInput } from "../../Helper/sanitize";
import { Bars } from 'react-loader-spinner';
import RemoveAppAccess from "../Popup/RemoveAppAccess";

function ListUser() {
  const dispatch = useDispatch();
  const[data,setData] = useState([]);
  const[app,setApp] = useState([]);
  const{role,appList} = useSelector((state) => state.dashboard);
  let user = localStorage.getItem("username");
  const [modalIsOpen, setIsOpen] = useState(false);
  //let firstletter = user?.slice(0,1).toUpperCase();


  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  React.useEffect(() => {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-all-user-list`,
          {},
          {
            headers: { ...constant.headers},
          }
        )
        .then((response) => {
            setData(response.data.data);
        })
        .catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
  }, []);

  return (
    <div className="mx-4">
        <h3 className="text-primary fz-bold">Users</h3>

        {data.length === 0 ?  <Bars
            height="80"
            width="80"
            color="#0063c3"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />

            :

        data?.map((item) => 
        <div class="w-100 mb-2" style={{height:"8vh"}} 
            onClick={()=>{
                setApp(item);
                openModal();
            }}>
            <div class="btn  shadow-sm w-100 h-100 pt-0" type="button">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="d-flex mb-2">
                            <div className="rounded-circle circle d-flex  pe-none mt-2">{item.email.slice(0,1).toUpperCase()}</div>
                            <div className="ms-3 text-start">
                                <div className="text-dark" style={{}}>{item.email}</div>
                                <div className="">{item.role}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            
        </div>
        )
        }
        <RemoveAppAccess 
                modalIsOpen={modalIsOpen} 
                closeModal={closeModal} 
                data={app}/>
    </div>
  );
}

export default ListUser;
