import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: "",
  instruction:{
    preload:[]
}
};

export const info = createSlice({
  name: "info",
  initialState,
  reducers: {
    setInformation: (state, actions) => {
      state.info = actions.payload;
    },
    setPreloadInstruction:(state,actions)=>{
      state.instruction.preload = actions.payload;
    }
  },
});

export const {
    setInformation,setPreloadInstruction
} = info.actions;
export default info.reducer;
