import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import onboardReducer from "./slice";
import emailTemplateReducer from "./mailslice";
import dashboardReducer from "./dashboardslice";
import whitelistReducer from "./whitelistslice";
import frmailsReducer from "./frmails";
import appReducer from "./appslice";
import tempReducer from './tempslice';
import infoReducer from './info';
import onboardfinalReducer from './onboardfinal';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

export const store  = configureStore({
  reducer: {
    onboard : persistReducer(persistConfig,onboardReducer),
    mailtemplate:persistReducer(persistConfig,emailTemplateReducer),
    dashboard:persistReducer(persistConfig,dashboardReducer),
    whitelist:persistReducer(persistConfig,whitelistReducer),
    frmails:persistReducer(persistConfig,frmailsReducer),
    app:persistReducer(persistConfig,appReducer),
    info:persistReducer(persistConfig,infoReducer),
    onboardFinal:persistReducer(persistConfig,onboardfinalReducer),
    temp:tempReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
  })
})

export const persistor = persistStore(store);