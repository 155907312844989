import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import AmgenLogo from '../Assets/Group1.svg';
import Sidebar from "../Sidebar/Sidebar";
import MyComponent from "../Table/Table";
import axios from 'axios';
import * as constant from '../Constants/constant';
import { updateLoader } from "../../store/slice";
import { useSelector, useDispatch } from "react-redux";
import MessagePopup from "../Popup/Messagepopup";
import { resetLanguage,resetCountry, setCountry, setLanguage } from '../../store/frmails';
import { sanitizeUserInput } from "../../Helper/sanitize";
import FileUpload from "../Popup/fileUploadPopup";


function UpdateTran() {
  const{allowedApp,country,laguage, appValue} = useSelector((state) => state.frmails);
  const{ role,appList} = useSelector((state) => state.dashboard);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const { csrf } = useSelector((state) => state.app);
  const [message,setMessage]=useState({
    title:"",
    body:"",
    success:false
})
const [appname, setAppname]=useState("");
const [languageloc, setLanguageloc]=useState("");
const [countryloc, setCountryloc]=useState("");
const [go, setGo]=useState(false);
const [submit, setSubmit]=useState(false);
const [fileUpload, setFileUpload]=useState(false);
const [value, setValue]=useState("");
axios.defaults.withCredentials = true;


function validateURL(url,appname,languageloc,countryloc) { 
  const allowedApps = [ "AmgenISS","BeyondstatinsAU","agGMIUS","agGMIFrance","agGMISpain","AmgenCompass","demoTwo","demoOne"], allowedLanguages = Object.values(constant.allowedLanguages), allowedCountry = Object.values(constant.allowedCountry);
  if(constant.urlFormat.test(url) && allowedApps.includes(appname) && allowedLanguages.includes(languageloc) && allowedCountry.includes(countryloc) ) {
     return true;
   } else {
       return false;
   }
}


function getLocale(){
  const trans_url=`${process.env.REACT_APP_API_BASE_URL}/api/locales/${window.encodeURIComponent(appname)}/${window.encodeURIComponent(languageloc)}-${window.encodeURIComponent(countryloc)}`;
  // if(validateURL(trans_url,appname,languageloc,countryloc)){
    // if(appname != "" && typeof(appname) == 'string' && languageloc != "" && typeof(languageloc) == 'string'  &&countryloc != "" && typeof(countryloc) == 'string' ){
      axios
      .get(
        trans_url,
        {
          headers: { ...constant.headers,"csrf-token": csrf },
        }
      )
      .then((response) => {
        setGo(false);
        setValue(response.data);
        
      })
      .catch((err) => console.log(sanitizeUserInput(JSON.stringify(err))));
    // }
    
  // }
 
}

function openmessagepopup() {
  setMessageIsOpen(true);
}
function closemessagepopup() {
  setMessageIsOpen(false);
}
function openUploadFile() {
  setFileUpload(true);
}
function colseUploadFile() {
  setFileUpload(false);
}

function updateValue(name, val){

  setValue({
    ...value,
    [name]:val

  })
}

useEffect(() => {
  setValue("");
},[appname, languageloc , countryloc]);

function handleSubmit(){

  axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/create-request`,{
    application_name :appname,
    request_type : "updateTranslation",
    email : localStorage.getItem("username"),
    data: {
      language:languageloc,
      country:countryloc,
      data:value
    }
  },
  {
      headers: { ...constant.headers,"csrf-token": csrf },
  }
  )
  .then((response) => {

      setMessage(prevState => ({
                      ...prevState,
                      title: "Language Translation Updated Successfully",
                      body:"Language translation updated succesfully. Template Waiting for Approval",
                      success:true
                  }));

    openmessagepopup();
    setValue("");
    // setAppname("");
    // setLanguage("");
    // setCountry("");
    setSubmit(false);
  })
  .catch(error => {
      console.log(sanitizeUserInput(JSON.stringify(error)))
  }) 
  }

   
  const [show, setShow] = useState(false);
  const user = localStorage.getItem("username");
  const dispatch = useDispatch();


    return (

        <main className="mt-0 px-5">

          <div className="d-flex py-1 shadow-sm mb-4 justify-content-between">
          
            <div className="d-flex">
              <div className="me-3 d-flex align-items-center form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Application<sup className="text-danger">*</sup>
                    </label>
                    <select className="form-select mx-2" aria-label="Default select example"
                    value={appname}
                      onChange={(e) => {
                        setAppname(e.target.value)

                        if(e.target.value!==""){
                          dispatch(setCountry({"app":e.target.value,"value":appValue}));
                          setCountryloc("");
                          setLanguageloc("");
                          }else{
                            dispatch(resetCountry());
                            dispatch(resetLanguage());
                          }
                        }}>
                        <option value="">Select Application</option>
                          {appList?.map ((data,index) => {
                           return <option key={index} value={data?.appName}>{data?.aliasName}</option>
                          })}
                        {/* {success_application_list?.map ((option) => {
                          return <option>{option["application_name"]}</option>
                          })} */}
                          </select>
              </div>
              <div className="me-3 d-flex align-items-center form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Country<sup className="text-danger">*</sup>
                    </label>
                    <select className="form-select mx-2" aria-label="Default select example"
                    value={countryloc}
                    onChange={(e) => {
                      setCountryloc(e.target.value);
                      setLanguageloc("");
                      dispatch(setLanguage({"name":e.target.value,"country":country}));
                      }}>

                    <option value="">Select Country</option>
                    {country.map((data,index)=>  <option key={index} value={data.value}>{data.label}
                    </option>)}
                    {/* <option value={0}>Select Country</option>
                    <option value={"US"}>US</option>
                    <option value={"CA"}>Canada</option> */}
                    {/* <option value={2}>Failure mail</option>
                    <option value={3}>Inactive notification mail</option>
                    <option value={4}>Deactivated notification mail</option> */}
                    
                    </select>
              </div>
              <div className="me-3 d-flex align-items-center form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Language<sup className="text-danger">*</sup>
                    </label>
                    <select className="form-select mx-2" aria-label="Default select example" value={languageloc}
                    onChange={(event) => {
                      setLanguageloc(event.target.value)
                      }}>
                    <option value="">Select Language</option>
                    {laguage.map((data,index)=> <option key={index} value={data.Value}>{data.Label}
                     </option>)}
                        {/* {allowLan?.map ((option) => {
                          return <option value={option.value}>{option["label"]}</option>
                          })} */}
                    </select>
              </div>
              
            </div>
            <div className="d-flex me-2">
              {!go ? <button 
                className="btn btn-primary"
                style={{width:"5rem"}}
                disabled={!(appname && languageloc && countryloc)}
                onClick={()=>{
                  getLocale()
                  setGo(true)
                }}
              >Go</button>: <button className="btn btn-primary" style={{width:"5rem"}}>
              <div className="spinner-border spinner-border-sm text-light" role="status">
              <span className="visually-hidden">Loading...</span>
              </div>
              </button>}
            </div>

          </div>

          {(appname && languageloc && countryloc && Object.entries(value).length > 0) &&<div className="my-2"> 
            <div className="d-flex justify-content-end align-items-center p-2 user-select-none">
              <div role="button" onClick={openUploadFile}>upload file</div>
              <div className="material-symbols-outlined text-primary" role="button" onClick={openUploadFile}>
                upload_file
                </div>
            </div>

       
          </div>}
          <div>
           { Object.entries(value).map(([key,data],index) =>{
           return !(key.includes("_")) && <div className="row mb-2" key={index}>
                <div className="col-5">{key}</div>
                  <div className="col-7 d-flex align-items-center">
                    <input 
                    className="form-control" 
                    placeholder={data}
                    onChange={(e)=>{
                      updateValue(key, e.target.value)
                    }}></input>
                  </div>
            </div>
 })}
              
            
          </div>

                  {value && !submit && <button 
                  className="btn btn-primary mb-4 mt-5"
                  style={{width:"10rem"}}
                  onClick={()=>{
                    handleSubmit()
                    setSubmit(true)}}>Submit</button>}
                  {submit && <button className="btn btn-primary mb-4 mt-5" style={{width:"10rem"}}>
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                  </div>
                  </button>}

                  <MessagePopup 
                    modalIsOpen={messageIsOpen} 
                    closeModal={closemessagepopup}
                    title={message.title }
                    body={message.body}
                    success={message.success}
                    />
                    <FileUpload modalIsOpen={fileUpload} closeModal={colseUploadFile} metaData={
                      {
                        "appname":appname,
                        "languageloc":languageloc,
                        "countryloc":countryloc
                      }
                    } setMessage={setMessage} openmessagepopup={openmessagepopup}/>
                
        </main>
    )
}

export default UpdateTran;
