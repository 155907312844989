import React from "react";

import { changeRegisterFieldName, removeCustomField } from "../../store/slice";

import { useSelector, useDispatch } from "react-redux";

function Vijay(props) {
  const { customizeField } = useSelector((state) => state.onboard);

  const dispatch = useDispatch();

  const val = props.name;

  return (
    <>
      {val in customizeField ? (
        <div className="col-3 ps-0">
          <div className="d-flex">
            <div
              className="my-auto lh-1 fw-bold"
              onClick={() =>
                dispatch(
                  removeCustomField({
                    index: props.index,

                    name: props.name,
                  })
                )
              }
            >
              <span class="material-symbols-outlined text-danger" role="button">
                remove
              </span>
            </div>

            <input
              type="text"
              className="form-control ps-2 text-secondary w-100"
              style={{ height: "2rem" }}
              placeholder={props.name}
              name="name"
              disabled
              onChange={(e) =>
                dispatch(
                  changeRegisterFieldName({
                    index: props.index,

                    value: e.target.value,
                  })
                )
              }
              defaultValue={props.label}
            ></input>
          </div>
        </div>
      ) : (
        <div className="col-3 ps-4">
          <input
            type="text"
            className="form-control ps-2 text-secondary w-100"
            style={{ height: "2rem" }}
            placeholder={props.name}
            name="name"
            disabled
            onChange={(e) =>
              dispatch(
                changeRegisterFieldName({
                  index: props.index,

                  value: e.target.value,
                })
              )
            }
            defaultValue={props.label}
          ></input>
        </div>
      )}
    </>
  );
}

export default Vijay;
