import React from "react";
import { useState } from "react";
import AmgenLogo from "../Assets/Group1.svg";
import MyComponent from "../Table/Table";
import "./index.css";
import "../style.css";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import * as constant from "../Constants/constant";
import {
  updateLoader,
  completeButtonDisable,
  setOnboardData,
  setAdminApplication,
  updateOnboardKind,
  updateRequestId,
} from "../../store/slice";
import {
  setShow
} from "../../store/appslice"
import { useSelector, useDispatch } from "react-redux";
import "../style.css";
import Popover from '@mui/material/Popover';
import UserPopover from "../userpopover";
import MessagePopup from "../Popup/Messagepopup";
import { sanitizeUserInput } from "../../Helper/sanitize";

function Applicationowner() {
  const [loading,setLoading]=useState(false);
  const [data, setData] = useState("");
  const [applist, setApplist] = useState("");
  //const user = "tst_ss005@mailinator.com";
  const user = localStorage.getItem("username");
  const { Loader, adminApplications } = useSelector((state) => state.onboard);
  const { csrf, show } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adLoader, setADLoader] = useState({});
  const [applist1, setApplist1] = useState("");
  axios.defaults.withCredentials = true;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const [message,setMessage]=useState({
      title:"",
      body:"",
      success:false
  })

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }

  function handleOnboard(){
    dispatch(completeButtonDisable(false));
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:"globalUiDefault"},
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            
            response.data.data.name="";
            dispatch(setOnboardData(response.data.data));
            navigate(`/myrequest/onboard`);
            setLoading(false);
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  // React.useEffect(() =>{
  //   axios.get("http://country.io/names.json")
  //   .then((response) => {
  //   })
  // },[])

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`, {
        headers: { ...constant.headers,"csrf-token": csrf },
      })
      .then((response) => {
        setApplist(response.data.data);

        let app = [];
        const a = response.data.data;
        for (const i in a) {
          app.push(a[i]["application_name"]);
        }
        setApplist1(app);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });

      axios.get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
      {
      headers: { ...constant.headers },
      }            
      )
      
      .then((res) => {

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
        { email: user },
        {
          headers: { ...constant.headers,"csrf-token": res.data.data.csrfToken },
        }
      )
      .then((response) => {
        dispatch(updateLoader());
        setData(response.data.data.data);
        let ownerApp = (response.data.data.data.map((data) => data.application_name));
        dispatch(setAdminApplication(ownerApp));
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
    }).catch((error) => {
      console.log(sanitizeUserInput(JSON.stringify(error)));
    });
    return () => {
      dispatch(updateLoader());
    };
  }, []);

  function handleEdit(e, r) {
    dispatch(completeButtonDisable(true));
    dispatch(updateOnboardKind("ownerEdit"));

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-app`,
        { application_name: r.application_name },
        {
          headers: { ...constant.headers,"csrf-token": csrf },
        }
      )
      .then((response) => {
        dispatch(setOnboardData(response.data.data));
        navigate(`/myrequest/onboard`);
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }

  function handleStatus(status, r) {
    setADLoader({...adLoader,[r]:true});
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: r,
          request_type: status == "Active" ? "activation" : "deactivation",
          email: localStorage.getItem("username"),
        },
        {
          headers: { ...constant.headers,"csrf-token": csrf },
        }
      )
      .then((response) => {
       
            setMessage(prevState => ({
              ...prevState,
              title: "Request",
              body:"Request submitted successfully.",
              success:true
          }));
            setADLoader({...adLoader,[r]:false});
            openmessagepopup()
             
      })
      .catch((error) => {
        setADLoader({...adLoader,[r]:false});
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setADLoader({...adLoader,[r]:false});
      });
  }

  function handleView(e,r){
      dispatch(completeButtonDisable(true));

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:r.application_name},
          {
              headers: { ...constant.headers,"csrf-token": csrf },
          }
          )
          .then((response) => {
              dispatch(setOnboardData(response.data.data));
              navigate(`/myrequest/onboard`);
          })
          .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
  }

  // function handleStatus(status, r) {
  //   setADLoader({...adLoader,[r]:true});
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
  //       {
  //         application_name: r,
  //         request_type: status == "Active" ? "activation" : "deactivation",
  //         email: localStorage.getItem("username"),
  //       },
  //       {
  //         headers: { ...constant.headers,"csrf-token": csrf },
  //       }
  //     )
  //     .then((response) => {
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
  //           { email: user },
  //           {
  //             headers: { ...constant.headers,"csrf-token": csrf },
  //           }
  //         )
  //         .then((response) => {
  //           setData(response.data.data.data);
  //           setMessage(prevState => ({
  //             ...prevState,
  //             title: "Request",
  //             body:"Request submitted successfully.",
  //             success:true
  //         }));
  //           setADLoader({...adLoader,[r]:false});
  //           openmessagepopup()
            
  //         })
  //         .catch((error) => {
  //           setADLoader({...adLoader,[r]:false});
  //           console.log(sanitizeUserInput(JSON.stringify(error)));
  //           setMessage(prevState => ({
  //             ...prevState,
  //             title: "Request",
  //             body:"Request failed.",
  //             success:false
  //         }));
  //           setADLoader({...adLoader,[r]:false});
  //           openmessagepopup()
  //         });
  //     })
  //     .catch((error) => {
  //       setADLoader({...adLoader,[r]:false});
  //       console.log(sanitizeUserInput(JSON.stringify(error)));
  //       setADLoader({...adLoader,[r]:false});
  //     });
  // }

  const columns = [
    {
      name: 'Application Name',
      cell: (row) => row?.alias_name
    },
    {
      name: "Status",
      cell: (row) => row.status,
    },
    {
      name: "Actions",
      cell: (r) => {
        return (
          <div>
            &nbsp;&nbsp;
            {/* <span
              className="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Edit" type="button"
              onClick={(e) => {
                handleEdit(e, r);
              }}
            >
              edit
            </span> */}
            <span className="material-symbols-outlined iconfontsize" type="button" onClick={(e) => {handleView(e,r)}}>visibility</span>
          </div>
        );
      },
    },
    {
      name: "",
      cell: (r) => {
        return (
          <div>
             {(adLoader[r.application_name] || false) ? <div>
              <button  type="button"
                className="tablebtn" >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div> : <div>
            {applist1.includes(r.application_name) ? (
              applist[applist1.indexOf(r.application_name)]["status"] ==
              "Active" ? (
                <button
                  type="button"
                  className="tablebtn"
                  onClick={() => {
                    handleStatus("Deactive", r.application_name);
                  }}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="tablebtn"
                  onClick={() => {
                    handleStatus("Active", r.application_name);
                  }}
                >
                  Activate
                </button>
              )
            ) : (
              ""
            )}</div>}
          </div>
        );
      },
    },
  ];

  return (
    <main className={show ? "space-toggle" : null} style={{margin:0}}>
      <aside
        style={{ background: "#045EB7" }}
        className={`sidebar ${show ? "show" : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => dispatch(setShow())}>
              <span className="fas fa-bars sidebarfirst"></span>
            </div>
            <Sidebar show={show}></Sidebar>
          </div>
        </nav>
      </aside>
      <div className="maintop">
      <header style={{ background: "white",zIndex:"-1" ,height:"7vh",padding:0}} className={`header ${show ? 'space-toggle' : null}`}>
        <span><img style={{ paddingLeft: "1rem",height:"40px" }} src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg" alt=""></img></span>
        <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}
        
            <span 
                                aria-describedby={id} 
                                role="button" 
                                className="material-symbols-outlined headercolor fs-1 me-3" 
                                onClick={handleClick}
                                >account_circle
                            </span>
                            
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                            >
                                <UserPopover/>
                            </Popover>
        </div>
        </header>
        <div className="ms-4 mt-2 fw-bold" style={{color:"#0463c3"}}>Applications</div>
        <div className="bodyfirst">
          <div
            className="myreqwidth m-5"
          >
            <div className="d-flex justify-content-end">
                {loading ? <button 
                className="btn btn-primary mb-2" 
                style={{width:"11rem"}}
                >
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                :
                <button 
                className="btn btn-primary mb-2 d-flex justify-content-end"
                style={{width:"11rem"}}
                onClick={()=>{handleOnboard()}}
                >Onboard Application</button>
                }
            </div>
            <div className="tablebo">
            <MyComponent columns={columns} data={data} fileName={"Application"}/>
            </div>
            <MessagePopup 
             modalIsOpen={messageIsOpen} 
             closeModal={closemessagepopup}
             title={message.title }
             body={message.body}
             success={message.success}
            ></MessagePopup>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Applicationowner;
