import React, { useEffect,useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useLocation,Outlet,Navigate } from 'react-router-dom';
import axios from "axios";
import * as constant from '../components/Constants/constant';
import { useSelector, useDispatch } from "react-redux";
import {updateRole, setAdminApplication} from '../store/slice';
import {setAllowedApplication, setActive, newCountryList} from '../store/dashboardslice';
import { setAllowedApplicationWhitelist } from '../store/whitelistslice';
import { setToken } from '../store/appslice';
import { setAllowedMailApplicationlist } from '../store/frmails';
import { sanitizeUserInput } from '../Helper/sanitize';
import { adminRoutes, appOwnerRoutes, dashboardOnlyRoutes, superAdminRoutes } from '../Routes';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  return (<Outlet />);
}


export const UserInfo = () => {
  axios.defaults.withCredentials = true;
	const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const { csrf } = useSelector((state) => state.app);

//   const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
    const getUser = async () => {
      try {
        if (!localStorage.getItem("username")) {
          const res = await oktaAuth.getUser().then((data) => {
            dispatch(updateRole("loggedin"));
            localStorage.setItem("username", data.email);
            axios
              .get(
                `${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
                {
                  headers: { ...constant.headers },
                }
              )
              .then((resp) => {
                dispatch(setToken(resp.data.data.csrfToken));
                axios
                  .post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
                    { email: data.email },
                    {
                      headers: {
                        ...constant.headers,
                        "csrf-token": resp.data.data.csrfToken,
                      },
                    }
                  )
                  .then((response) => {
                    dispatch(setAllowedApplication(response?.data?.data));
                    dispatch(newCountryList(response?.data?.data));
                    if(response.data.data.role == "Super Admin"){
                      dispatch(setActive("HCP Pending"));
                  }
                    const temp=response.data.data.data.filter((data,index)=>data?.globalUI===true);
                    dispatch(setAllowedMailApplicationlist(temp));
                    
                    dispatch(
                      setAllowedApplicationWhitelist(response?.data?.data)
                    );
                  })
                  .catch((error) => {
                    console.log(sanitizeUserInput(JSON.stringify(error)));
                  });
              })
              .catch((error) => {
                console.log(sanitizeUserInput(JSON.stringify(error)));
              });
          });
        }

        // setUserInfo(res);
      } catch (error) {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      }
    };

    authState?.isAuthenticated && getUser();
  }, [authState, oktaAuth]);

    return (<Outlet />);
};



export const RoleVerify = () => {
  const{role} = useSelector((state) => state.dashboard);
  const location=useLocation();
  const route={
    superAdmin:["/dashboard","/applicationadmin","/myrequest/onboard","/request","/preloaduser","/mailtemplate","/updatetranslation","/clientcreation","/whitelist","/user"],
    admin:["/dashboard","/applicationadmin","/request","/myrequest/onboard","/preloaduser","/mailtemplate","/updatetranslation","/clientcreation","/whitelist","/user"],
    appOwner:["/dashboard","/applicationowner","/myrequest","/myrequest/onboard","/preloaduser","/mailtemplate","/updatetranslation","/clientcreation","/whitelist","/user"],
    dashboardUser:["/dashboard"]
  }
  const check=()=>{
    if(role.toLowerCase()==="super admin"){
      return route.superAdmin.includes(location.pathname)?true:false;
  }else if(role.toLowerCase()==="admin"){
      return route.admin.includes(location.pathname)?true:false;
  }else if(role.toLowerCase()==="app owner"){
      return route.appOwner.includes(location.pathname)?true:false;
  }else {
      return route.dashboardUser.includes(location.pathname)?true:false;
  }
  }


    return ( check()? <Outlet />: <Navigate to="/notavailable" replace />);
};
