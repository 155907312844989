import React from "react";
import * as constant from '../components/Constants/constant';
import axios from 'axios';
import { sanitizeUserInput } from "./sanitize";
import Countries from "../components/Data/Region";
import Languages from "../components/Data/Languages";

axios.defaults.withCredentials = true;

    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }


    const getCountry=(val)=>{
        if(val===""){
          return "";
        }else{
          const data=Countries.filter((d)=>d.code===val.toUpperCase());
          return data[0].label;
        }
        
      }

    const textFormatter=(val)=>{
        return val.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase().replaceAll("_"," ")

    }

    const getLanguage=(val)=>{
        if(val===""){
          return "";
        }else{
          const data=Languages.filter((d)=>d.code===val.toLowerCase());
          return data[0].name;
        }
        
      }

    const newRegistered = async(payload,setNewregisteredcount,setActiveNewregisteredData,csrf) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            ...payload,
            newRegistered_new: true
            },
            {
                headers: { ...constant.headers,"csrf-token": csrf },
            }
            )
            .then((response) => {
                // setNewregisteredcount(response.data.length)
                setActiveNewregisteredData(response.data)
            })
            .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }

    const countriesOnboarded = async(payload,setCountrycount,setActiveCountryData,csrf) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
        ...payload,
        countriesOnboarded_new:true
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            setCountrycount(response.data.length)
            setActiveCountryData(response.data);
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }

    const login = async(payload,setLoggedincount,setActiveLoggedInData,csrf,setAppValue,appValue) => {
        setActiveLoggedInData("")
        setLoggedincount(0)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            ...payload,
            login:true
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            let count = 0;
            response.data.forEach(val => {
                count = Number(count) + Number(val.Total)
            });
            setActiveLoggedInData(response.data)
            setLoggedincount(count)      
            setAppValue({
                ...appValue,
                logged: count
            })  
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }

    const applicationsOnboarded = async(payload,setApplicationcount,setActiveApplicationData,csrf) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            ...payload,
            applicationsOnboarded_new: true
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            let y = [];
            response.data.forEach((e)=> {
                const a = e.application_name.split(",")
                y = y.concat(a)
            })
            
            const App = removeDuplicates(y);
            setApplicationcount(App.length);
            setActiveApplicationData(response.data) 
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
    }

    const activeRegistered = async(payload,setActiveregisteredcount,setActiveRegisteredData,csrf) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            ...payload,
            activeRegistered_new: true
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            // let ar = 0;
            // response.data.forEach((e)=>{
            //     ar = ar + e.OccurenceValue

            // })
            // setActiveregisteredcount(ar)
            // setActiveregisteredcount(response.data.length)
            setActiveRegisteredData(response.data)
            //dispatch(updateLoader(true));
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }

    const hcpValidated = async(payload,setHcpvalidatedcount,setHcpvalidated,setLoading,csrf) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
        ...payload,
        hcpValidated: true,
        limit:10000
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
        // setHcpvalidatedcount(response.data.length  > 9999 ? response.data.length.toString().slice(0, -3)+"k":response.data.length.toString())
        //setAlldata(response.data)
        setHcpvalidated(response.data)
        setLoading(false)

        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }

    const hcpPending = async(payload,setHcppendingcount,setAlldata,setHcppending, setLoading,csrf)=> {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            ...payload,
            hcpPending: true,
            limit:10000
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            // setHcppendingcount(response.data.length  > 9999 ? response.data.length.toString().slice(0, -3)+"k":response.data.length.toString())
            setAlldata(response.data)
            setHcppending(response.data)
            setLoading(false)
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
    }
    const getCsrfToken = async()=> {
     const value=  await axios.get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
        {
            headers: { ...constant.headers },
        }
        )
        .then((response) => {
          return response.data.data.csrfToken;
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))})
        return value;
    }

    const makeDateFormat =(date)=>{
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d= new Date(date);
        return `${d.getDate()}-${months[d.getMonth()]}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
       }

    export  {
        hcpPending,
        hcpValidated,
        activeRegistered,
        applicationsOnboarded,
        textFormatter,
        login,
        countriesOnboarded,
        newRegistered,getCsrfToken,makeDateFormat,getCountry,getLanguage
    };


