import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import * as constant from '../Constants/constant';
import MessagePopup from './Messagepopup';

import { useSelector, useDispatch } from "react-redux";
import { sanitizeUserInput } from '../../Helper/sanitize';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: 'white',
        color: 'white'
    },
};

function Rejectpopup({ opencommentmodal, closecommentmodal, requestid, appname,afterClose}) {
    const[loading,setLoading]=useState(false);
    axios.defaults.withCredentials = true;
    const { csrf } = useSelector((state) => state.app);
    const [comment, setComment] = useState("");
    const user = localStorage.getItem("username");
    const [messageIsOpen, setMessageIsOpen] = React.useState(false);

    function openmessagepopup() {
        setMessageIsOpen(true);
      }
      function closemessagepopup() {
        setMessageIsOpen(false);
      }

const [message,setMessage]=useState({
        title:"",
        body:"",
        success:false
    })


    function handleChange(event){
        let value = event.target.value;
        setComment(value);

    }

    function handleClose() {
        setLoading(true);

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/reject-user-request`,{
            request_id:requestid,
            processed_by: user,
            comments:comment
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => { 
            closecommentmodal()
            setMessage(prevState => ({
                ...prevState,
                title: "Request Rejected",
                body:"Your request is rejected.",
                success:false
            }));
            setLoading(false);
         openmessagepopup();
         afterClose();
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))
            setLoading(false);
            closecommentmodal()
        });
        
    }


    let subtitle;


    


    function afterOpenModal() {

        subtitle.style.color = 'white';
    }



    return (
        <div className='position-relative'>
            <Modal
                isOpen={opencommentmodal}
                //onAfterOpen={afterOpenModal}
                onRequestClose={closecommentmodal}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <div>
                <button onClick={() => {
                        closecommentmodal()
                        }} style={{ 
                            textDecoration: "none", 
                            border: "none", 
                            borderRadius:"16px", 
                            marginRight:"1rem", 
                            marginTop:"1rem" 
                            }} 
                            className="material-symbols-outlined position-absolute top-0 end-0">
                        close
                </button>

                <div style={{color:"#0063c3"}}><b>Reject User</b></div><br/>
                <div style={{color:"#6c757d"}}>Add comment for {appname}, </div><br/>
                <textarea className="form-control" rows="4" cols="70" onChange={(event) => {handleChange(event)}}></textarea> <br/><br/>
               <div>
                {loading ?  <button className="btn btn-primary"  style={{width:"6rem"}}
             type="button">

               <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
             </button> :
                <button className="onboard-button btn btn-primary ms-2" style={{width:"6rem"}} onClick={handleClose}>Submit</button>
            }
            </div> </div>
            </Modal>

                <MessagePopup 
                    modalIsOpen={messageIsOpen} 
                    closeModal={closemessagepopup}
                    title={message.title }
                    body={message.body}
                    success={message.success}
                />
        </div>
    );
}

export default Rejectpopup;