import React, { useState } from "react";
import * as constant from "../Constants/constant";
import axios from "axios";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import MessagePopup from "./Messagepopup";
import { sanitizeUserInput } from "../../Helper/sanitize";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "5px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "white",
    color: "#6c757d",
  },
};
export default function Frtestpopup({ modalIsOpen, closeModal, payload, subject }) {
    const [tempMail, setTempMail] = useState("");
    const [flag, setFlag] = useState(false);
    const [btndisable, setBtndisable] = useState(false);
    const [mailList, setMailList] = useState([]);
    const {successMailContent} = useSelector((state) => state.mailtemplate);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { csrf } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
    const [message,setMessage]=useState({
      title:"",
      body:"",
      success:false
  })
    let subtitle;
    const [messageIsOpen, setMessageIsOpen] = React.useState(false);
    function openmessagepopup() {
      setMessageIsOpen(true);
    }
    function closemessagepopup() {
      setMessageIsOpen(false);
    }
  
    function afterOpenModal() {
      subtitle.style.color = "white";
    }

    function Mailcheck(e){
        if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e.target.value)) {
        setBtndisable(true);
    } else {
        setBtndisable(false);
    }
    }

    function handleTest(){
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fr/test-email-template`,
            {
                "app_name":successMailContent.application_name,
                "locale":successMailContent.language,
                "activity":payload.activity,
                "email_ids":tempMail
            },{
              headers: { ...constant.headers,"csrf-token": csrf },
          }
        )
        .then((response) => {
            setMessage(prevState => ({
              ...prevState,
              title: "Template send Successfully",
              body:"Template send your mail id you entered above, kindly check your mail",
              success:true
          }));
          openmessagepopup();
          closeModal();
          setLoading(false)
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)));    
        }); 
    }

   

    return (
      <div>
        <Modal
          isOpen={modalIsOpen}
          //onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={false}
          data-keyboard="false"
        >
          
          <div className="position-relative" style={{ maxHeight: "40vw" }}>
            <span
              role="button"
              className="position-absolute top-0 end-0 material-symbols-outlined text-white"
              style={{
                background: "#AAAAAA",
                borderRadius: "15px",
              }}
              onClick={closeModal}
            >
              close
            </span>
            <div className="px-5 pt-3 pb-5">
              <p className="fs-4 text-primary">Test Mail</p>
              <div className="input-group mb-4">
                <input
                  type="text"
                  className="form-control"
                  
                  placeholder="Add Email id here"
                //   value={tempMail}
                  onChange={(e) => {
                    setTempMail(e.target.value)
                    Mailcheck(e);
                }}
                />
                {/* <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="button-addon2"
                  onClick={
                    ()=>{
                        setMailList(tempMail)
                    }
                }
                >
                  Add Email
                </button> */}
              </div>
              <div className="d-flex">
                <button
                  className="btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>

                <button 
                  className="btn btn-primary ms-2" 
                  style={{width:"15%"}}
                  disabled={!btndisable}
                  onClick={(event) => {
                  setLoading(true)
                  handleTest();
                  }}
                  >
                  {loading && <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                  </div>}
                  {!loading && <div>Test</div>}
                  </button>

              </div>
            </div>
            
          </div>
        </Modal>
        <MessagePopup 
                    modalIsOpen={messageIsOpen} 
                    closeModal={closemessagepopup}
                    title={message.title }
                    body={message.body}
                    success={message.success}
                    />
      </div>
    );
  }
  