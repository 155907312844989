import React from "react";

export default function Loader() {
  return (
    <div
      className="position-absolute top-0 left-0 d-flex w-100 h-100 justify-content-center align-items-center bg-dark bg-opacity-50"
      style={{ zIndex: "1000" }}
    >
      <div class="spinner-grow text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
