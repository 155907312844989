import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from "react-redux";
import { updateValidationvalue } from '../../store/slice';
import { trimData } from '../../Helper/onboard';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: 'white',
        color: 'white',
    },
};

export default function Validationpopup({modalIsOpen, closeModal,data,setValData}) {
    const [value,setValue]=useState(0);
    const [error,setError]=useState("");
    const dispatch = useDispatch();
    const closePopup=()=>{
        setValue(0);
        setError("");
        setValData({ title:"",
        index: 0,
        key: "",
        check:""});
        closeModal();
    }
    function handleSubmit(e){
        switch (data.key) {
            case "min":
                if(data.check){
                    if(value <= Number(data.check)){
                        dispatch(updateValidationvalue({  index: data.index,
                            key: data.key,
                            value: String(value)}));
                            closePopup();
                   }else{
                    setError("Value greater than Maximum of value");
                   }
                }else{
                    dispatch(updateValidationvalue({  index: data.index,
                        key: data.key,
                        value: String(value)}));
                        closePopup();
                }
                  
                break;
            case "max":
                if(data.check){
                    if( value >= Number(data.check)){
                        dispatch(updateValidationvalue({  index: data.index,
                            key: data.key,
                            value: String(value)}));
                            closePopup();
                    }else{
                     setError("Value less than Minimum  of value");
                    }
                }else{
                    dispatch(updateValidationvalue({  index: data.index,
                        key: data.key,
                        value: String(value)}));
                        closePopup();
                }
               
                break;
            default:
                setError("Something wrong, try again!");
        
        }
    }
  return (
    <div>  <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    shouldCloseOnOverlayClick={false}
    style={customStyles}
    ariaHideApp={false}
    contentLabel="Example Modal"
>
   <div className='text-dark'>
    <div className='text-center'><b>{`Change ${data.title} value`}</b></div>
            <div class="form-group">
                <div className="d-flex align-items-center mb-2">
                    <label htmlFor="usr" className="me-1 m-0">{`Enter your ${data.title} value`}</label>
                </div>
                
                <input type="text" class="form-control" onChange={(e) => {
                    if(/^[0-9]*$/i.test(trimData(e.target.value))){
                        setValue(Number(trimData(e.target.value)));
                        setError("");
                    }else{
                        setError("Numbers only allowed");
                    }}}/>
                {error !== "" && (<div className="text-danger mx-2">{error}</div>)}
            </div>
            <div className='d-flex justify-content-center'>
                <button 
                type="button" 
                class="btn btn-secondary btn-sm me-4" 
                onClick={() => {closePopup()}}>
                    Cancel
                </button>
                <button class="btn btn-primary btn-sm" 
                disabled={value === 0 || error} onClick ={(e) => {handleSubmit(e);}} >Submit</button>
            </div>
   </div>
    
</Modal></div>
  )
}
