import React, { useState } from "react";
import * as constant from "../Constants/constant";
import axios from "axios";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setSuccessMailSender,
  setFailureMailSender,
  removeSuccessMailSender,
  removeFailureMailSender,
  setDeactivatedMailSender,
  setInactiveMailSender,
  setMailSender,
  setRemoveMailSender
} from "../../store/mailslice";
import "./style.css";
import { sanitizeUserInput } from "../../Helper/sanitize";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "5px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "white",
    color: "#6c757d",
  },
};
export default function Testpopup({ modalIsOpen, closeModal, preval }) {
    const [tempMail, setTempMail] = useState("");
    const [sendsucmail, setSendsucmail] = useState(false);
    const [sendfailmail, setSendfailmail] = useState(false);
    const[wrongmail, setWrongmail] = useState(false);
    const[loading, setLoading] = useState(false);
    const[sucmaildone, setSucMailDone] = useState(false);
    const[failmaildone, setFailMailDone] = useState(false);
    const[inactivemaildone, setInactivemaildone] = useState(false);
    const[deactivatedmaildone, setDeactivatedmaildone] = useState(false);
    const { 
        successMailContent,
        failureMailContent, 
        inactiveMailContent,
        deactivatedMailContent,
        email_blast
    } = useSelector((state) => state.mailtemplate);
    const { csrf } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
    const dispatch = useDispatch();
    let subtitle;
  

    function setSucValue() {
      // const body = successMailContent.hcp_validation_success_email_body.replace(
      //   /&nbsp;/i,
      //   " "
      // );
      const data = {
        email: successMailContent.email,
        language: successMailContent.language,
        data: successMailContent.content,
        country:successMailContent.country
      };
      return data;
    }

    function setFailValue() {
      // const body = failureMailContent.hcp_validation_failure_email_body.replace(
      //   /&nbsp;/i,
      //   " "
      // );
      const data = {
        email: failureMailContent.email,
        data: failureMailContent.content,
        language: successMailContent.language,
        country:successMailContent.country
      };
      return data;
    }

    function setInactiveValue() {
      // const body = inactiveMailContent.hcp_validation_failure_email_body.replace(
      //   /&nbsp;/i,
      //   " "
      // );
      const data = {
        email: inactiveMailContent.email,
        data: inactiveMailContent.content,
        language: successMailContent.language,
        country:successMailContent.country
      };
      return data;
    }

    function setDeactivatedVal() {
      // const body = deactivatedMailContent.hcp_validation_failure_email_body.replace(
      //   /&nbsp;/i,
      //   " "
      // );
      const data = {
        email: deactivatedMailContent.email,
        data: deactivatedMailContent.content,
        language: successMailContent.language,
        country:successMailContent.country
      };
      return data;
    }

    React.useEffect(() =>{
      if(preval){
        if(preval == 1) { 
          setSendsucmail(true)
          setSendfailmail(false)
          setInactivemaildone(false)
          setDeactivatedmaildone(false)
        }
        if(preval == 2) { 
          setSendsucmail(false)
          setSendfailmail(true)
          setInactivemaildone(false)
          setDeactivatedmaildone(false)
        }
        if(preval == 3) { 
          setSendsucmail(false)
          setSendfailmail(false)
          setInactivemaildone(true)
          setDeactivatedmaildone(false)
        }
        if(preval == 4) { 
          setSendsucmail(false)
          setSendfailmail(false)
          setInactivemaildone(false)
          setDeactivatedmaildone(true)
        } 
      }
    },[preval])

    function afterOpenModal() {
      subtitle.style.color = "white";
    }
    return (
      <div>
        <Modal
          isOpen={modalIsOpen}
           //onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          {(!sucmaildone && !failmaildone ) ?
          <div className="position-relative" style={{ maxHeight: "40vw" }}>
            <span
              role="button"
              className="position-absolute top-0 end-0 material-symbols-outlined text-white"
              style={{
                background: "#AAAAAA",
                borderRadius: "15px",
              }}
              onClick={closeModal}
            >
              close
            </span>
            <div className="px-5 pt-3 pb-5">
              <p className="fs-4 text-primary">Test Mail</p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Email id(s) here"
                  value={tempMail}
                  onChange={(e) => setTempMail(e.target.value)}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  id="button-addon2"
                  onClick={(e) => {
                    const a = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(tempMail);
                    if(a=== true){
                      dispatch(setMailSender(tempMail));
                      // dispatch(setFailureMailSender(tempMail));
                      // dispatch(setInactiveMailSender(tempMail));
                      // dispatch(setDeactivatedMailSender(tempMail));
                      setTempMail("");
                    }else{
                      setWrongmail(true);
                    }
                    
                  }}
                >
                  Add Email
                </button>
              </div>
              {(wrongmail && tempMail !="") &&
                <div className="text-danger mb-1">
                  *Enter valid mail
                </div>}
              <div className="d-flex mb-2 mt-3 flex-wrap">
                { preval == 1 && <div class="form-check form-switch me-4">
                  <input 
                    class="form-check-input"
                    type="checkbox" 
                    id="flexSwitchCheckDefault"
                    defaultChecked={true}
                    disabled
                    // onClick={(e)=>{
                    //   if(e.target.checked === true){
                    //     setSendsucmail(true)
                    //   }else{
                    //     setSendsucmail(false)
                    //   }
                    // }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Validation success notification</label>
                </div>}
                { preval == 2 && <div class="form-check form-switch">
                  <input 
                  class="form-check-input" 
                  type="checkbox" 
                  defaultChecked={true}
                  id="flexSwitchCheckChecked"
                  disabled
                  // onClick={(e)=>{
                  //   if(e.target.checked === true){
                  //     setSendfailmail(true)
                  //   }else{
                  //     setSendfailmail(false)
                  //   }
                  // }}
                  />
                  <label class="form-check-label me-4" for="flexSwitchCheckChecked">Validation failure notification</label>
                </div>}
                { preval == 3 && <div class="form-check form-switch me-4">
                  <input 
                  class="form-check-input" 
                  type="checkbox" 
                  defaultChecked={true}
                  id="flexSwitchCheckChecked"
                  disabled
                  // onClick={(e)=>{
                  //   if(e.target.checked === true){
                  //     setInactivemaildone(true)
                  //   }else{
                  //     setInactivemaildone(false)
                  //   }
                  // }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">User inactivity notification</label>
                </div>}
                { preval == 4 && <div class="form-check form-switch">
                  <input 
                  class="form-check-input" 
                  type="checkbox" 
                  defaultChecked={deactivatedmaildone}
                  id="flexSwitchCheckChecked"
                  disabled
                  // onClick={(e)=>{
                  //   if(e.target.checked === true){
                  //     setDeactivatedmaildone(true)
                  //   }else{
                  //     setDeactivatedmaildone(false)
                  //   }
                  // }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">User deactivated notification</label>
                </div>}
              </div>
              <div
                className="border border-primary rounded mb-3 overflow-auto"
                style={{
                  height: "10vw",
                }}
              >
                <div className="d-flex p-2 flex-wrap">
                  {email_blast.map((data, index) => (
                    <div
                      key={index}
                      className="px-2 border rounded-pill bg-light text-primary me-1 my-1"
                    >
                      {data}
  
                      <span
                        role="button"
                        className="ms-1 material-symbols-outlined align-middle fs-6 text-primary"
                        onClick={() => dispatch(removeSuccessMailSender(index))}
                      >
                        cancel
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex w-100">
                <button
                  className="btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                {loading ?<button  type="button" style={{width:"15%"}}
                className="btn btn-primary px-3 rounded-pill" >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button> :  <button
                  className="btn btn-primary"
                  disabled={email_blast?.length===0 && true}
                  style={{width:"15%"}}
                  onClick={() => {
                    setLoading(true);
                    if(sendsucmail){
                    axios
                      .post(
                        `${process.env.REACT_APP_API_BASE_URL}/api/success-mail-blast`,
                        {
                          email:email_blast,
                          data:successMailContent.content,
                          language: successMailContent.language,
                          country:successMailContent.country
                        },
                        {
                          headers: { ...constant.headers,"csrf-token": csrf },
                        }
                      )
                      .then((response) => {
                        setLoading(false);
                        setSucMailDone(true);
                      })
                      .catch((error) => {
                        setLoading(false);
                        console.log(sanitizeUserInput(JSON.stringify(error)));
                      });
                    }
                    if(sendfailmail){
                      axios
                        .post(
                          `${process.env.REACT_APP_API_BASE_URL}/api/rejection-mail-blast`,
                          {
                            email:email_blast,
                            data:failureMailContent.content,
                            language: successMailContent.language,
                            country:successMailContent.country
                          },
                          {
                            headers: { ...constant.headers,"csrf-token": csrf },
                          }
                        )
                        .then((response) => {
                          setLoading(false);
                          setFailMailDone(true);
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(sanitizeUserInput(JSON.stringify(error)));
                        });
                    }
                     if(inactivemaildone){
                      axios
                        .post(
                          `${process.env.REACT_APP_API_BASE_URL}/api/inactive-mail-blast`,
                          {
                            email:email_blast,
                            data:inactiveMailContent.content,
                            language: successMailContent.language,
                            country:successMailContent.country
                          },
                          {
                            headers: { ...constant.headers,"csrf-token": csrf },
                          }
                        )
                        .then((response) => {
                          setLoading(false);
                          setSucMailDone(true);
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(sanitizeUserInput(JSON.stringify(error)));
                        });
                      }
                     if(deactivatedmaildone){
                        axios
                          .post(
                            `${process.env.REACT_APP_API_BASE_URL}/api/deactivated-mail-blast`,
                            {
                              email:email_blast,
                              data:deactivatedMailContent.content,
                              language: successMailContent.language,
                              country:successMailContent.country
                            },
                            {
                              headers: { ...constant.headers,"csrf-token": csrf },
                            }
                          )
                          .then((response) => {
                            setLoading(false);
                            setSucMailDone(true);
                          })
                          .catch((error) => {
                            setLoading(false);
                            console.log(sanitizeUserInput(JSON.stringify(error)));
                          });
                        }
                  }}
                >
                  Send
                </button>} 
               
              </div>
            </div>
          </div>
          :
          <div className="position-relative" style={{ maxHeight: "40vw" }}>
            <span
              role="button"
              className="position-absolute top-0 end-0 material-symbols-outlined text-white"
              style={{
                background: "#AAAAAA",
                borderRadius: "15px",
              }}
              onClick={()=>{
                closeModal();
                setSendsucmail(false);
                setSendfailmail(false);
                setSucMailDone(false);
                setFailMailDone(false);
                setInactivemaildone(false);
                setDeactivatedmaildone(false);
                dispatch(setRemoveMailSender());
              }
              }
            >
              close
            </span>
            <div className="px-5 pt-3 pb-2">
              <p className="fs-4 text-primary">Test Mail</p>
            </div>
            <div className="px-5 pt-3 pb-5">
              Check your email <br/>
              If you cannot find mail in inbox. check spam folder
            </div>
          </div>
          }
        </Modal>
      </div>
    );
  }
  