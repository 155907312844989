const sanitizeUserInput = (data) => {
    
    const value = `${data}`;
    if (!value) {return "";}
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#x27;",
      "/": "&#x2F;",
    };
  
    const reg = /[&<>"'/]/ig;
  
    return value.replace(reg, (match) => map[match]).replace(/(\r\n|\n|\r)/gm, "");
  };

export {
    sanitizeUserInput
};