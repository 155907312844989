import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as constant from "../Constants/constant";
import Dropzone from "react-dropzone";
import MessagePopup from "../Popup/Messagepopup";
import { sanitizeUserInput } from "../../Helper/sanitize";
import { changeClient, changeFlag, resetClient, setOnChange } from "../../store/tempslice";

export default function ClientCreation() {
  const user = localStorage.getItem("username");
  const {adminApplications} = useSelector((state) => state.onboard);
  const { csrf } = useSelector((state) => state.app);
  // const role = localStorage.getItem("role");
  const{ role,appList} = useSelector((state) => state.dashboard);
  axios.defaults.withCredentials = true;
  const [redirecturl, setRedirecturl] = useState([]);
  const [isAppSelected, setAppSelect] = React.useState(false);
  const [getdetail, setGetDetail] = React.useState(false);
  const [btn, setBtn] = React.useState(false);
  const [subbtn, setSubbtn] = React.useState(false);
  const dispatch = useDispatch();
  const { clientDetails } = useSelector((state) => state.temp);
  const initialValue = {
    application:"",
    client_name:"",
    client_uri:"",
    redirect_uris:[]
}
  const [value,setValue]=useState({...initialValue})
  const [error,setError]=useState({sign:"",redirect:""})

useEffect(()=>{
  setValue({...clientDetails});
},[clientDetails]);
  useEffect(()=>{
    return ()=>{
        dispatch(changeFlag("clientCreation"));
     }
  },[])
function handleApplicationChange(e){
    e.preventDefault();
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-client`,
    {
    headers: { 
      ...constant.headers ,
      app_name:value.application
    },
    }            
    )
    .then((res) => {
      // setValue({
      //   ...value,
      //   ...res.data.data
      //   });
        dispatch(changeClient({...res.data.data}));
        setGetDetail(true);
        setBtn(false);
        if(res.data.data.client_uri || !res.data.data.update){
          dispatch(setOnChange({key:"clientCreation",value:true}));
        }
    })
    .catch(error => {
      console.log(sanitizeUserInput(JSON.stringify(error)))
  })
}

  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  
  const [message,setMessage]=useState({
    title:"",
    body:"",
    success:false
})

function openmessagepopup() {
  setMessageIsOpen(true);
}
function closemessagepopup() {
  setMessageIsOpen(false);
}

function addClick(){
  const r = [...clientDetails.redirect_uris,redirecturl]
  // console.log("redirecturl",r);
  // r.push(redirecturl)
  // setValue({
  //   ...value,
  //   redirect_uris:(r)
  // }
  // )
  dispatch(changeClient({redirect_uris:r}));
  setRedirecturl("")
}

function closeClick(index){
  const a = clientDetails.redirect_uris.filter((data,i)=> i!==index)

  // setValue({
  //   ...value,
  //   redirect_uris:(a)
  // })
  dispatch(changeClient({redirect_uris:a}));
}


  function handleSubmit(e) {
    e.preventDefault();

                  axios
                  .put(
                    `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
                    {
                      application_name:value.application,
                      data: value,
                      request_type:"ClientCreation",
                      email: localStorage.getItem("username"),
                    },
                    {
                      headers: { ...constant.headers,"csrf-token": csrf },
                    }
                  )
                  .then((response) => {
                    // setValue(initialValue)
                    dispatch(resetClient());
                    dispatch(setOnChange({key:"clientCreation",value:true}));
                    setMessage(prevState => ({
                      ...prevState,
                      title: "Client Creation",
                      body:"Client Created succesfully. Waiting for Approval",
                      success:true
                  }));
                  openmessagepopup();
                  setSubbtn(false)
                  })
                  .catch((error) => {
                    console.log(sanitizeUserInput(JSON.stringify(error)));
                  });
  }



  return (
    <div >
      <form >
        <div className="d-flex justify-content-between mb-4 align-items-center">
          <div className="d-flex align-items-center">
          <div className="me-2">
          <label>Select application</label><br/>
          </div>
          <div className="me-4">

          <select 
          value={value.application || ""}
          onChange={(e)=> {
            setAppSelect(true);
            setGetDetail(false)
            // setValue({
            // ...initialValue,
            // application:e.target.value
            // });
            dispatch(changeClient({ application:e.target.value,client_name: "",client_uri: "",redirect_uris: []}));
          }}
            className="form-select mb-2 mt-1">
            <option value="">Select application</option>
            {appList.map((data,index)=>{
              return <option key={index} value={data?.appName}>{data?.aliasName}</option>
            })}
            
          </select>
          </div>
          <div className="me-4">
            Or
          </div>
          <div className="me-2">
            <input 
              className="form-control" 
              placeholder="Enter app name" 
              value={value.application}
              onChange={(e)=> {
                setAppSelect(true);
                setGetDetail(false);
                setValue({
                ...initialValue,
                application:e.target.value
              })
              dispatch(changeClient({ application:e.target.value,client_name: "",client_uri: "",redirect_uris: []}));
            }}
              ></input>
          </div>
          </div>
          <div>
        {(btn) ? <div className="w-100">
              <button  
                type="button" 
                style={{width:"7rem"}}
                className="btn btn-primary me-4" >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div> : <div className="w-100">
                <button 
                disabled={value.application === ""}
                className="btn btn-primary me-4" 
                style={{width:"7rem"}}
                onClick={(e)=>{
                  
                  setBtn(true);
                  handleApplicationChange(e)
                }}
                >Get Details</button>
                </div>}
          </div>
        </div>
        <div className="w-50">
          <div>
            <label>Name of the client<span className="text-danger">*</span></label><br/>
            <input 
              className="form-control mb-2" 
              placeholder="Client Name"
              value={value.client_name}
              type="text"
              disabled = {isAppSelected && getdetail ? false : true}
              onChange={(e)=> {
                // setValue({
                // ...value,
                // client_name:e.target.value})
              dispatch(changeClient({ client_name:e.target.value.trim()}));
            }}
              ></input>
          </div>
          <div>
            <label>Sign in URL<span className="text-danger">*</span></label><br/>
            <input 
              className="form-control mb-2" 
              value={value.client_uri}
              disabled = {isAppSelected && getdetail ? false : true}
              placeholder="sign in url (Ex . https://clienturi.org/)"
              type="text"
              onChange={(e)=> {
              //   setValue({
              //   ...value,
              //   client_uri:e.target.value
              // }
              // )
                dispatch(changeClient({ client_uri:e.target.value.trim()}));
                if(e.target.value.trim().length !==0){
                  if(constant.urlFormat.test(e.target.value.trim())){
                    setError({...error,sign:""});
                  }else{
                    setError({...error,sign:"Enter the valid url"});
                  }
                }else{
                  setError({...error,sign:""});
                }
              
            }}
              ></input>
                {error.sign !== "" && (
            <div className="text-danger">
                {error.sign}
            </div>
            )}
          </div>
        
          {/* <div>
            <label>Redirect URLs</label><br/>
            <div className="d-flex">
              <input 
                className="form-control pe-4" 
                disabled = {isAppSelected && getdetail ? false : true}
                type="text"
                placeholder="redirect url"
                value={redirecturl}
                onChange={(e)=>
                  setRedirecturl(e.target.value)
                }
                ></input>
                <span 
                  className="material-symbols-outlined d-flex align-items-center ms-2" 
                  disabled = {isAppSelected ? false : true}
                  role="button"
                  onClick={() => {
                    if(redirecturl){
                      addClick()
                    }
                  }}
                  >
                    add_circle
                </span>
            </div>
          </div> */}
          <label>Redirect URLs<span className="text-danger">*</span></label><br/>
          <div class="input-group mb-3">
            <input 
            className="form-control pe-4" 
            disabled = {isAppSelected && getdetail ? false : true}
            type="text"
            placeholder="redirect url (Ex . https://clienturi.org/)"
            value={redirecturl}
            onChange={(e)=>{ 
              setRedirecturl(e.target.value.trim());
              if(e.target.value.trim().length !==0){
                if(constant.urlFormat.test(e.target.value.trim())){
                  setError({...error,redirect:""});
                }else{
                  setError({...error,redirect:"Enter the valid url"});
                }
              }else{
                setError({...error,redirect:""});
              }
           }
            }
            />
            <div class="input-group-append">
              <span 
              className="material-symbols-outlined d-flex align-items-center ms-2" 
              disabled = {isAppSelected ? false : true}
              role="button"
              onClick={() => {
                if(redirecturl){
                  addClick()
                }
              }}
              class="input-group-text">+</span>
            </div>
          </div>
          {error.redirect !== "" && (
            <div className="text-danger">
                {error.redirect}
            </div>
            )}
        </div>

            {value.redirect_uris.map((data,index)=>{
              return <div className="d-flex">
                        <div className="ms-3 mt-1 me-1 fs-5">{data}</div>
                        <span 
                          class="material-symbols-outlined d-flex align-items-center mt-2"
                          disabled = {isAppSelected ? false : true}
                          role="button"
                          style={{"fontSize":"15px", "color":"red"}}
                          onClick={()=>{closeClick(index)}}
                          >
                        cancel
                        </span>
                      </div>
            })}

{(subbtn) ? <div className="w-100">
              <button  
                type="button" 
                style={{width:"7rem"}}
                className="btn btn-primary mt-3" >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div> : <div className="w-100">
                <button 
                className="btn btn-primary mt-3" 
                style={{width:"7rem"}}
                onClick={(e)=>{
                  setSubbtn(true);
                  handleSubmit(e)
                }}
                disabled = {isAppSelected && getdetail && (value.client_name && value.client_uri && value.redirect_uris.length!==0) && (!error.sign && !error.redirect) ? false : true}
                >Submit</button>
                </div>}
      </form>

      <MessagePopup 
             modalIsOpen={messageIsOpen} 
             closeModal={closemessagepopup}
             title={message.title }
             body={message.body}
             success={message.success}
            ></MessagePopup>

     </div>
  );
}
