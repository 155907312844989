import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import * as constant from '../Constants/constant';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: 'white',
        color: 'black'
    },
};

function UrlModel({ modalIsOpen, closeModal, data}) {

    function handleClose() {
        closeModal()
      }

 
    return (
        <div className='position-relative'>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <div style={{maxHeight:"40vh"}}>
                <button onClick={() => {
                        handleClose()
                        }} style={{ textDecoration: "none", border: "none", borderRadius:"16px", marginRight:"1rem", marginTop:"1rem" }} className="material-symbols-outlined position-absolute top-0 end-0">close</button>
                {/* <div style={{color:"black"}}>URLs</div> */}
                <div style={{color:"#0463c3"}} className="font-weight-bold h3">Whitelist URL Preview</div>
                <div className="h-100 overflow-auto mt-2">
<div className="d-flex">
    <div className="w-50 pe-2">
        <div className="h4 text-dark">URL</div>
        <div>
        {data?.url.map((data,index)=>
        <p key={index}>{data}
      </p>
        )}
        {data?.url === undefined && 
            <div className="py-3">urls not available</div>}
        </div>
       
    </div>
    <div className="w-50 ps-2">
    <div className="h4 text-dark">RedirectURI</div>
    <div>
        {data?.redirectURI.map((data,index)=>
        <p className="text-break" key={index}>{data}</p>
    
        )}
          {data?.redirectURI === undefined && <div className="py-3">urls not available</div>
           }
        </div>
    </div>
</div>
                </div>

                </div>
                
            </Modal>
        </div>
    );
}

export default UrlModel;