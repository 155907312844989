import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewOptions,
  removeOption,
  changeRegisterValidation,
  updateRegexpatternvalue,
  updateRegexmessagevalue
} from "../../store/slice";
import Vijay from "./vijay";
import "./index.css";
import ValidationComponent from "./validationcomponent";
import { requireFields } from "./validations_list";
import AddFieldPopup from "./addfieldpopup";
import Regexpopup from "../Popup/Regexpopup";
import Validationpopup from "../Popup/validationpopup";
import ValidationComponentView from "./validationcomponentview";

export default function ApiComponentView({data}) {
  const [openregex, setOpenregex] = React.useState(false);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const[index,setIndex]=useState("");
  const[first_name,setName]=useState("");
  const [valPopup, setValPopup] = useState(false);
  const [valData, setValData] = useState({ title:"",index: 0,key: "",check:""});

  function openModal2() {
    setIsOpen2(true);
  }


  function closeModal2() {
    setIsOpen2(false);
  }
  const handleValPopup = () => {
    setValPopup((pre)=>!pre);
   };
  function openRegex(e,index,name) {
    setIndex(index);
    setName(name);
    if(e.target.checked === true){
    setOpenregex(true);
    }else if(e.target.checked === false){
      dispatch(updateRegexpatternvalue({index: index,value: "",name:name}));
      dispatch(updateRegexmessagevalue({index: index,value: "",name:name}));
    }
  }

  function viewRegex(e,index,name){
    setIndex(index);
    setName(name);
    setOpenregex(true);
  }

  function closeRegex() {
    setOpenregex(false);
  }

  function cancelCourse(index){ 
  document.getElementById(`inputField1${index}`).value="";
  options[document.getElementById(`inputField1${index}`).name]="";
}
  return (
    <div>
      <div className="container">
        <div className="row " style={{ marginBottom: "1rem" }}>
          <div className="col-3 ps-4">Field Name</div>
          <div className="col-1">Type</div>
          <div className="col-1">Mandatory</div>
          <div className="col-2">Options</div>
          <div className="col-1">Pattern Validation (Regex)</div>
          <div className="col-4">Validations</div>
        </div>
      </div>
      {data?.fields?.register?.steps[0]?.fields?.map((data, index) =>{
     return <div key={index} className="container">
          <div className="row py-2">
                 <Vijay
                name={data.name}
                index={index}
                value={data.name}
                label={data.label}
              />
            <div className="col-1 h-100">
              <div className="my-auto" style={{ width: "95%" }}>
                {data.type}
              </div>
            </div>
            <div className="col-1">
              <div>
                {requireFields.includes(data.name) ? (
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={data.validations.required}
                    id="flexCheckDefault"
                    style={{ marginLeft: "10%" }}
                    disabled
                  />
                ) : (
                  <input
                    className="form-check-input"
                  role="button"
                    type="checkbox"
                    disabled
                    checked={data.validations.required}
                    onChange={() => dispatch(changeRegisterValidation(index))}
                    id="flexCheckDefault"
                    style={{ marginLeft: "10%" }}
                  />
                )}
              </div>
            </div>
            <div className="col-2">
              {data.type.toLowerCase() === "dropdown" && (
                  

                  <select className="form-select" value="">
                        <option disabled value="">Click to view values</option>
                        {data.values.map((option, index) => {
                            return (
                                <option disabled key={index}>
                                    {option.value}
                                </option>
                            );
                        })}
                    </select>
              )}
            </div>
            <div className="col-1" style={{ marginLeft: "2%",width:"6%"}}>
            {/* {(data.type === "text")  && 
            <input
                    className="form-check-input"
                    type="checkbox"
                    role="button"
                     checked={"regex" in data.validations && "checked"}
                    onChange={(e) => {
                      openRegex(e,index,data.name)}}
                    id="flexCheckDefault"
                    
                  />} */}
                { 
                 ((data.type === "text") && (data.validations.regex) &&
                 (data.validations.regex?.pattern != "" || data.validations.regex?.msg != "" )) &&
                  <span 
                  style={{ marginLeft: "10%", fontSize:"19px" }}
                  role="button"
                  onClick={(e) => {viewRegex(e,index,data.name)}}
                  className="material-symbols-outlined">
                    visibility
                  </span>
                }
            </div>
            <div className="col-4 overflow-auto">
              <ValidationComponentView
                value={data}
                index={index}
                openVal={handleValPopup}
                setValData={setValData}
              ></ValidationComponentView>
            </div>
          </div>
        </div>
      })}
      {/* <Regexpopup
      modalIsOpen={openregex}
      closeModal={closeRegex}
      index={index}
      name={first_name}
      >
      </Regexpopup> */}
      <Validationpopup  modalIsOpen={valPopup} data={valData}  setValData={setValData}
          closeModal={handleValPopup}></Validationpopup>
    </div>
  );
}
