import React, { useState, useEffect } from "react";
import axios from "axios";
import * as constant from "../Constants/constant";
import { useSelector, useDispatch } from "react-redux";
import MessagePopup from "../Popup/Messagepopup";
import { sanitizeUserInput } from "../../Helper/sanitize";
import { changeFlag, setWhitelist,setOnChange } from "../../store/tempslice";
import { resetApplicationDetails } from "../../store/whitelistslice";

export default function WhitelistComponent({ list }) {
  const { orginalUrl, redirectUrl, application } = useSelector(
    (state) => state.whitelist
  );
  const [loading,setLoading]=useState(false);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const [country, setCountry] = useState("");
  const [isAppLoader,setAppLoader]=useState(false);
  const{ countryListNew,appList} = useSelector((state) => state.dashboard);
  const [application_state,setApplicationState]=useState("");
  const [newAppList,setAppList]=useState([]);
  const [orgin_state,setOrginState]=useState([]);
  const [redirect_state,setRedirectState]=useState([...redirectUrl]);
  const [message,setMessage]=useState({
    title:"",
    body:"",
    success:false
})
  axios.defaults.withCredentials = true;
  const contenHeight=`calc(100% - 60px)`;
  
  const { onChange} = useSelector((state) => state.temp);
  const { csrf, show } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [input, setInput] = useState({ originurl: "", redirecturl: "" });
  const [error,setError]=useState({originurl:"",redirecturl:""});

  function openmessagepopup() {
    setMessageIsOpen(true);
  }
  function closemessagepopup() {
    setMessageIsOpen(false);
  }
 
  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if(countryListNew.length>0){
      setCountry(countryListNew[0].value);
    }
  }, []);

  useEffect(() => {
    setAppLoader((pre)=>!pre);
      if(country){
       axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
        headers: { ...constant.headers },
      }).then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/country-app-list`,
            { country: country },
            {
              headers: {
                ...constant.headers,
                "csrf-token": res.data.data.csrfToken,
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            if(typeof response.data.data === "object" &&  Object.keys(response.data.data).length>0 ){
              setAppList(response.data.data.apps);
          }else{
            setAppList([]);
          }
          setAppLoader((pre)=>!pre);
          })
          .catch((er) => {
            console.log("errorr ",er);
            setAppList([]);
            setAppLoader((pre)=>!pre);

          });
      }).catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)), "errorItem");
        setAppList([]);
        setAppLoader((pre)=>!pre);
      })
    }else{
      setAppList([]);
      setAppLoader((pre)=>!pre);
    }
  },[country]);


useEffect(()=>{
  return ()=>{
      dispatch(changeFlag("whitelist"));
   }
},[])
  useEffect(() => {
    if(application !==""){
      setApplicationState(application);
      setOrginState([...orginalUrl]);
      setRedirectState([...redirectUrl]);
      dispatch(setOnChange({key:"whitelist",value:false}));
      dispatch(resetApplicationDetails());
    }
  }, []);

const changeApplications=async(application_state)=>{
  if (application_state === "") {
    setOrginState([]);
    setRedirectState([]);
    dispatch(setWhitelist({appName:"",orginUrl:[],redirectUrl:[]}));
    dispatch(setOnChange({key:"whitelist",value:true}));
  } else {
    setLoading(true);
    dispatch(setOnChange({key:"whitelist",value:true}));
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-whitelist-urls`,
        { application_name: application_state },
        {
          headers: { ...constant.headers,"csrf-token": csrf },
        }
      )
      .then((response) => {
        if(typeof(response.data.data) == "string"){
          setOrginState([]);
          setRedirectState([]);
          setLoading(false);
        }else{
          setOrginState((response.data.data.url));
          setRedirectState((response.data.data.redirectURI));
          dispatch(setWhitelist({appName:application_state,orginUrl:[...response.data.data.url],redirectUrl:[...response.data.data.redirectURI]}));
          setLoading(false);
        }
       
      })
      .catch((error) => {
        setLoading(false);
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }
}

  const handleSubmit = async () => {
    if(application_state !==""){
      dispatch(setOnChange({key:"whitelist",value:true}));
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        data: {
          application_name: application_state,
          data: {
            url: orgin_state,
            redirectURI: redirect_state,
          },
          request_type: "whitelist",
          email: localStorage.getItem("username"),
        },
        headers: {
          ...constant.headers,"csrf-token": csrf ,
        },
      }).then((res)=>{
        setMessage(prevState => ({
          ...prevState,
          title: "URL Whitelist Successfully",
          body:"Your URL whitelist request was successfully submitted and is waiting for approval.",
          success:true
      }));
      setLoading(false);
      setApplicationState("");
      setOrginState([]);
      setRedirectState([]);
      dispatch(setWhitelist({appName:"",orginUrl:[],redirectUrl:[]}));
        openmessagepopup();
       
        return res;
      });
    } catch (error) {
      setMessage(prevState => ({
        ...prevState,
        title: "URL Whitelist",
        body:"We are facing some technical, please try again later",
        success:false
    }));
      setLoading(false);
      openmessagepopup();
      console.log(sanitizeUserInput(JSON.stringify(error)));
    }
  }
  };

  return (
    <div className="h-100 pb-2">
      <div className="d-flex justify-content-between align-items-center p-1 mb-2" style={{height :"54px"}}>
        <div className="d-flex">
        <div className="d-md-flex align-items-center me-2">
        <b className="me-1">Select Country</b>
          <select
            name="language"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            style={{
              border: "1px solid #ced4da",
              width: "150px",
              borderRadius: "5px",
              height: "2rem",
            }}
            aria-label="Default select example"
          >
            <option value="">Select Country</option>
                {countryListNew.map((x, index) => (
                 <option key={index} value={x.value}>
                 {x.label}
               </option>
                   ))}
          </select>
         
        </div>
        <div className="d-md-flex align-items-center">
        <b className="me-1">Select Application</b>
          <select
            name="language"
            value={application_state}
            onChange={(e) => {
              setApplicationState(e.target.value);
              changeApplications(e.target.value);
            }}
            style={{
              border: "1px solid #ced4da",
              width: "150px",
              borderRadius: "5px",
              height: "2rem",
            }}
            aria-label="Default select example"
          >
            { !isAppLoader &&  <option value="">Select Application</option>}
                { isAppLoader && <option value="">Loading...</option>}
                {newAppList.map((data, index) => (
                  <option key={index} value={data.application_name}>
                    { data?.aliasName}
                  </option>
                   ))}
          </select>
         
        </div>
        </div>
        <div>
          {loading ? <button className="btn btn-primary" style={{width:"75px"}}>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button> :
         
          <button className="btn btn-primary" style={{width:"75px"}} disabled={!application_state || onChange.whitelist}  onClick={(()=>{
            setLoading(true);
            handleSubmit();
          })}>Submit</button>
        }
         
          
        </div>
      </div>
      <div className="row p-0 overflow-auto common-scroll" style={{height:contenHeight}}>
        <div className="col-12 col-lg-6 h-100">
          <div className="p-3 shadow rounded">
            <div className="h5">Origin URL</div>

            <div className="d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="eg: https://sample.com/"
                name="originurl"
                value={input.originurl}
                onChange={handleChange}
              ></input>
              
              <div
                className="ms-2 my-auto lh-1 fw-bold"
                onClick={(e) => {
                    try {
                      const url = new URL(input.originurl);
                      if (url.protocol === "https:") {
                        setOrginState((pre)=>{
                          dispatch(setWhitelist({orginUrl:[...pre,input.originurl]}));
                          return [...pre,input.originurl]});
                        setInput({ ...input, originurl: "" });
                        setError({ ...error, originurl: "" });
                        dispatch(setOnChange({key:"whitelist",value:false}));
                      } else {
                        setError({
                          ...error,
                          originurl:
                            "enter the secure protocol(https)",
                        });
                      }
                    } catch (e) {
                      setError({
                        ...error,
                        originurl:
                          "invalid URL (eg: https://sample.com/)",
                      });
                    }
                }}
              >
                <span className="material-symbols-outlined" role="button">
                  add_circle
                </span>
              </div>
            </div>
            {error.originurl !== "" && (
                            <div className="text-danger mx-2">
                              {error.originurl}
                            </div>
                          )}
          </div>
          <div className="mt-3 overflow-auto">
            {orgin_state?.map((data, index) => ( !constant.blockedDomain.some((val)=>data.includes(val)) &&
              <div key={index} className="d-flex w-100 my-1">
                <div className="d-flex w-100 px-2 py-1 border rounded-pill">
                  <div className="ps-1 text-nowrap txt-overflow">{data}</div>

                  <div
                    className="ms-auto my-auto lh-1 fw-bold"
                    onClick={() =>{
                      const temp=orgin_state.filter((d,ind)=>ind!==index);
                      setOrginState([...temp]);
                      dispatch(setWhitelist({orginUrl:[...temp]}));
                      dispatch(setOnChange({key:"whitelist",value:false}));
                    }}
                  >
                    <span className="material-symbols-outlined" role="button">
                      remove
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="p-3 shadow rounded">
            <div className="h5">Redirect URL</div>

            <div className="d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="eg: https://sample.com/"
                name="redirecturl"
                value={input.redirecturl}
                onChange={handleChange}
              ></input>
              
             <div
              className="ms-2 my-auto lh-1 fw-bold"
                onClick={(e) => {
                    try {
                      const url = new URL(input.redirecturl);
                      if (url.protocol === "https:") {
                        setRedirectState((pre)=>{
                          dispatch(setWhitelist({redirectUrl:[...pre,input.redirecturl]}));
                          return [...pre,input.redirecturl]});
                        setInput({ ...input, redirecturl: "" });
                        setError({ ...error, redirecturl: "" });
                        dispatch(setOnChange({key:"whitelist",value:false}));
                      } else {
                        setError({
                          ...error,
                          redirecturl:
                            "enter the secure protocol(https)",
                        });
                      }
                    } catch (e) {
                      setError({
                        ...error,
                        redirecturl:
                          "invalid URL (eg: https://sample.com/)",
                      });
                }}}
              >
                <span className="material-symbols-outlined" role="button">
                  add_circle
                </span>
              </div>

            </div>
            {error.redirecturl !== "" && (
                            <div className="text-danger mx-2">
                              {error.redirecturl}
                            </div>
                          )}
          </div>
          <div className="mt-3 overflow-auto">
            {redirect_state?.map((data, index) => (!constant.blockedDomain.some((val)=>data.includes(val)) &&
              <div key={index} className="d-flex w-100 my-1">
                <div className="d-flex w-100 px-2 py-1 border rounded-pill">
                  <div className="ps-1 text-nowrap txt-overflow">{data}</div>

                  <div
                    className="ms-auto my-auto lh-1 fw-bold"
                    onClick={() => {
                      const temp=redirect_state.filter((d,ind)=>ind!==index);
                      setRedirectState([...temp]);
                      dispatch(setWhitelist({redirectUrl:[...temp]}));
                      dispatch(setOnChange({key:"whitelist",value:false}));
                    }}
                  >
                    <span className="material-symbols-outlined" role="button">
                      remove
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MessagePopup 
             modalIsOpen={messageIsOpen} 
             closeModal={closemessagepopup}
             title={message.title }
             body={message.body}
             success={message.success}
            ></MessagePopup>
            
    
    </div>
  );
}
