import { createSlice } from "@reduxjs/toolkit";
import OnboardData from "../components/Data/Onboarddata";
import { sanitizeUserInput } from "../Helper/sanitize";
import {
  consentUpdate,
  updateMigrate,
  updateMigrateMapping,
  updateProfile,
  updateProfileMapping,
} from "../Helper/onboard";

const initialState = { ...OnboardData };

export const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    setOnboardData: (state, actions) => {
      if (Object.keys(state.data).length === 0) {
        state.data = actions.payload;
      } else {
        console.log("data already exist...");
      }
    },
    setSocialLogin: (state, actions) => {
      if (actions.payload.flag) {
        state.data.options.social = [
          ...state.data.options.social,
          actions.payload.value.toUpperCase(),
        ];
      } else {
        state.data.options.social = state.data.options.social.filter(
          (d) => d !== actions.payload.value
        );
      }
    },
    setLoginUrl: (state, actions) => {
      for (const x in state.data.fields) {
        state.data.fields[x]["loginURL"] = actions.payload;
      }
    },
    setRedirectUrl: (state, actions) => {
      for (const x in state.data.fields) {
        state.data.fields[x]["redirectURL"] = actions.payload;
      }
    },
    changeNewField: (state, actions) => {
      switch (actions.payload.option) {
        case 0:
          state.newField[actions.payload.name] = actions.payload.value;
          break;
        case 1:
          if (actions.payload.type === "dropdown") {
            state.newField.type = actions.payload.type;
          } else {
            state.newField.type = actions.payload.type;
            state.newField.values = null;
          }
          break;
        case 2:
          if (state.newField.values === null) {
            state.newField.values = [
              {
                key: "0",
                value: actions.payload.value,
              },
            ];
          } else {
            const lastelement =
              state.newField.values[state.newField.values.length - 1];

            state.newField.values.push({
              key: `${Number(lastelement.key) + 1}`,
              value: actions.payload.value,
            });
          }

          break;
        case 3:
          state.newField.values = state.newField.values.filter(
            (d, i) => i !== actions.payload.index
          );
          if (state.newField.values.length === 0) {
            state.newField.values = null;
          }
          break;
        case 4:
          state.newField.validations.required =
            !state.newField.validations.required;
          break;
        default:
          return state;
      }
    },
    addNewRegisterField: (state) => {
      state.customizeField[state.newField.name] = state.newField.name;
      if (state.newField.type === "dropdown") {
        state.newField.values = [
          { value: `Select a ${state.newField.label.toLowerCase()}`, key: "" },
          ...state.newField.values,
        ];
        state.data.options.fields.push(state.newField);
        state.data.options.field_mapping.additional = [
          ...state.data.options.field_mapping.additional,
          { content: state.newField.label, id: state.newField.name },
        ];
      } else {
        state.data.options.fields.push(state.newField);
        state.data.options.field_mapping.additional = [
          ...state.data.options.field_mapping.additional,
          { content: state.newField.label, id: state.newField.name },
        ];
      }
      state.newField = {
        name: "",
        label: "",
        placeholder: "",
        type: "text",
        validations: {
          required: false,
        },
        values: null,
      };
    },
    removeCustomField: (state, actions) => {
      delete state.customizeField[actions.payload.name];
      state.data.options.fields = state.data.options.fields.filter(
        (d, i) => i !== actions.payload.index
      );
      state.data.options.field_mapping.additional =
        state.data.options.field_mapping.additional.filter(
          (d) => d.id !== actions.payload.name
        );
    },
    removeConsent: (state, actions) => {
      state.consentRemove = !state.consentRemove;
    },
    deleteConsent: (state, actions) => {
      delete state.data.register.steps[1];
    },
    setAdminApplication: (state, actions) => {
      state.adminApplications = actions.payload;
    },
    changeHcpValidation: (state) => {
      if (state.data.options) {
        state.data.options.hcpvalidation = !state.data.options.hcpvalidation;
      } else {
        state.data["options"] = {
          hcpvalidation: true,
        };
      }
    },
    changeLanguage: (state, actions) => {
      state.data.options.language = actions.payload;
    },
    updateRole: (state, actions) => {
      state.role = actions.payload;
    },
    updateInitialLoader: (state, actions) => {
      state.initialLoader = actions.payload;
    },
    updateLanguage: (state, actions) => {
      const d = state.data?.options?.allowedLanguages.findIndex(
        (val) => val.value === actions.payload.value
      );
      if (d === -1) {
        state.data.options.allowedLanguages.push(actions.payload);
        const x = state.data.options?.mailBlastCountries[0];
        if (x) {
          const lang = {
            Value: actions.payload.value,
            Label: actions.payload.label,
          };
          state.data.options.mailBlastCountries[0]["supportedlang"].push(lang);
        } else {
          const lang = {
            Value: actions.payload.value,
            Label: actions.payload.label,
          };
          const mailBlastCountries = [
            {
              supportedlang: [],
            },
          ];
          mailBlastCountries[0]["supportedlang"].push(lang);
          state.data.options["mailBlastCountries"] = mailBlastCountries;
        }
      } else {
        console.log(
          "values",
          sanitizeUserInput(JSON.stringify(actions.payload))
        );
      }
    },
    updateTranslation: (state, actions) => {
      if (actions.payload.length !== 0) {
        state.translations = [...state.translations, ...actions.payload];
      } else {
        state.translations = [];
      }
    },
    changeRegion: (state, actions) => {
      state.data.options.social = state.data.options.social.filter(
        (d) => d !== state.data.options.country
      );
      state.data.options.country = actions.payload.value;
      const updateList = state.data.options["mailBlastCountries"];
      if (updateList.length > 0) {
        state.data.options["mailBlastCountries"][0]["label"] =
          actions.payload.label;
        state.data.options["mailBlastCountries"][0]["value"] =
          actions.payload.value;
      } else {
        state.data.options["mailBlastCountries"].push({
          label: actions.payload.label,
          supportedlang: [],
          value: actions.payload.value,
        });
      }
      if (state.data.mapping_json) {
        state.data.mapping_json.country = actions.payload.value;
      } else {
        state.data["mapping_json"] = {
          country: actions.payload.value,
        };
      }
    },
    updateLoader: (state, actions) => {
      state.Loader = actions.payload;
    },
    changeAppname: (state, actions) => {
      state.data.name = actions.payload;
    },
    changeClientName: (state, actions) => {
      state.clientName = actions.payload;
    },
    updateAppData: (state, actions) => {
      state.data.options[actions.payload.key] = actions.payload.value;
    },

    completeButtonDisable: (state, actions) => {
      state.completeButtonDisable = actions.payload;
    },

    updateOnboardKind: (state, actions) => {
      state.kind = actions.payload;
    },

    updateRequestId: (state, actions) => {
      state.request_id = actions.payload;
    },

    changeAllowOtherAppsToReg: (state) => {
      if (state.data.options) {
        state.data.options.allowOtherAppsToReg =
          !state.data.options.allowOtherAppsToReg;
      } else {
        state.data["options"] = {
          allowOtherAppsToReg: true,
        };
      }
    },

    changeTriggerHcpEmail: (state, actions) => {
      state.data.options.triggerHcpSuccessEmail = actions.payload;
      state.data.options.triggerHcpFailureEmail = actions.payload;
    },
    changeSyncCPC: (state) => {
      if (state.data.options) {
        state.data.options.syncCPC = !state.data.options.syncCPC;
      } else {
        state.data["options"] = {
          syncCPC: true,
        };
      }
    },
    changeSyncDPN: (state) => {
      if (state.data.options) {
        state.data.options.syncDPN = !state.data.options.syncDPN;
      } else {
        state.data["options"] = {
          syncDPN: true,
        };
      }
    },
    changeSyncEmailConsent: (state) => {
      if (state.data.options) {
        state.data.options.syncEmailConsent =
          !state.data.options.syncEmailConsent;
      } else {
        state.data["options"] = {
          syncEmailConsent: true,
        };
      }
    },
    addUrl: (state, actions) => {
      if (actions.payload !== "") {
        state.data.url.push(actions.payload);
      }
    },
    removeUrl: (state, actions) => {
      state.data.url = state.data.url.filter((d, i) => i !== actions.payload);
    },
    removeLanguage: (state, actions) => {
      state.data.options.allowedLanguages =
        state.data.options.allowedLanguages.filter(
          (d, i) => i !== actions.payload.index
        );
      if (state.data.options.allowedLanguages.length === 0) {
        state.data.options.language = "";
      }
      state.data.options.mailBlastCountries[0]["supportedlang"] =
        state.data.options.mailBlastCountries[0]["supportedlang"].filter(
          (d, i) => d.Value !== actions.payload.value
        );
    },
    addRedirectURI: (state, actions) => {
      if (actions.payload !== "") {
        state.data.redirectURI.push(actions.payload);
      }
    },
    removeRedirectURI: (state, actions) => {
      state.data.redirectURI = state.data.redirectURI.filter(
        (d, i) => i !== actions.payload
      );
    },
    changeRegisterFieldName: (state, actions) => {
      state.data.fields.register.steps[0].fields[actions.payload.index].label =
        actions.payload.value;
    },
    addNewOptions: (state, actions) => {
      if (Object.keys(actions.payload.value).length !== 0) {
        if (actions.payload.value[actions.payload.name] !== "") {
          if (state.data.options.fields[actions.payload.index].key === "") {
            state.data.options.fields[actions.payload.index].values = [
              {
                value: actions.payload.value[actions.payload.name],
                key: "0",
              },
            ];
          } else {
            const lastelement =
              state.data.options.fields[actions.payload.index].values[
                state.data.options.fields[actions.payload.index].values.length -
                  1
              ];
            state.data.options.fields[actions.payload.index].values.push({
              value: actions.payload.value[actions.payload.name],
              key: `${Number(lastelement.key) + 1}`,
            });
          }
        }
      }
    },
    removeOption: (state, actions) => {
      state.data.options.fields[actions.payload.index].values =
        state.data.options.fields[actions.payload.index].values.filter(
          (d, i) => i !== actions.payload.i
        );
      if (
        state.data.options.fields[actions.payload.index].values.length === 0
      ) {
        state.data.options.fields[actions.payload.index].values = null;
      }
    },
    changeRegisterValidation: (state, actions) => {
      state.data.options.fields[actions.payload].validations.required =
        !state.data.options.fields[actions.payload].validations.required;
    },
    modifyRegisterValidation: (state, actions) => {
      const key = actions.payload.key;
      const index = actions.payload.index;
      const value = actions.payload.value;
      if (key in state.data.options.fields[index].validations) {
        delete state.data.options.fields[index].validations[key];
      } else {
        state.data.options.fields[index].validations[key] = value;
      }
    },
    updateValidationvalue: (state, actions) => {
      const key = actions.payload.key;
      const index = actions.payload.index;
      const value = actions.payload.value;
      state.data.options.fields[index].validations[key] = value;
    },
    updateRegexpatternvalue: (state, actions) => {
      const index = actions.payload.index;
      const value = actions.payload.value;

      if (state.data.options.fields[index].validations && value !== "") {
        state.data.options.fields[index].validations.regex = {
          ...state.data.options.fields[index].validations.regex,
          pattern: value,
        };
      } else {
        delete state.data.options.fields[index].validations.regex;
      }
    },
    updateRegexmessagevalue: (state, actions) => {
      const index = actions.payload.index;
      const value = actions.payload.value;
      if (state.data.options.fields[index].validations && value !== "") {
        state.data.options.fields[index].validations.regex = {
          ...state.data.options.fields[index].validations.regex,
          msg: value,
        };
      } else {
        delete state.data.options.fields[index].validations.regex;
      }
    },

    changeRegisterConsentValidation: (state, actions) => {
      state.data.options.consent.fields[actions.payload].validations.required =
        !state.data.options.consent.fields[actions.payload].validations
          .required;
    },
    changeRegisterConsentLabel: (state, actions) => {
      state.data.options.consent.fields[actions.payload.index].label =
        actions.payload.value;
    },
    onSubmitProfile: (state, actions) => {
      state.data.fields.changeprofile.steps[0]={...state.data.fields.changeprofile.steps[0],disabled:!actions.payload.flag.profile || false}
      state.data.fields.changeprofile.steps[0].fields = updateProfile(
        actions.payload.fields,
        actions.payload.flag
      );
      state.data.fields.changeprofile.steps[1].fields = [
        ...consentUpdate(actions.payload.consent),
      ];
    },
    onSubmitMigrate: (state, actions) => {
      state.data.fields.migrateduserdata.steps[0].fields = updateMigrate(
        actions.payload.fields
      );
      state.data.fields.migrateduserdata.steps[1].fields = [
        ...consentUpdate(actions.payload.consent),
      ];
    },
    onSubmitRegister: (state, actions) => {
      state.data.fields.register.steps[0].fields = [...actions.payload.fields];
      state.data.fields.register.steps[1].fields = [...actions.payload.consent];
    },
    onSubmit: (state, actions) => {
      const login = state.data.options.allowTokenized;
      const allowOtp = state.data.options.allowOtp;
      const reg = state.data.options.allowPrepopulate;
        state.data.fields.login.steps[1].social.owa=[...actions.payload.social];
        state.data.fields.login["allowTokenized"]= login ? login : false;
        state.data.fields.login["allowOtp"]= allowOtp ? allowOtp : false;
        state.data.fields.register["allowPrepopulate"]= reg ? reg : false;
    },
    updateFieldMapping: (state, actions) => {
      state.data.fields.register.field_mapping = actions.payload;
      state.data.fields.changeprofile.field_mapping = updateProfileMapping(
        actions.payload
      );
      state.data.fields.migrateduserdata.field_mapping = updateMigrateMapping(
        actions.payload
      );
    },
    setAllowUpdates: (state, actions) => {
      state.data.options.updateFlag[actions.payload.name] =
        actions.payload.flag;
    },
    updateOption: (state, actions) => {
      state.data.options[actions.payload.name] = actions.payload.flag;
    },
    resetOnboard: (state, actions) => {
      state = { ...OnboardData };
    },
  },
});

export const {
  setOnboardData,
  setAdminApplication,
  updateRole,
  changeNewField,
  addNewRegisterField,
  changeLanguage,
  updateLanguage,
  changeRegion,
  updateLoader,
  removeCustomField,
  changeHcpValidation,
  changeAllowOtherAppsToReg,
  changeTriggerHcpEmail,
  changeSyncEmailConsent,
  changeSyncCPC,
  changeSyncDPN,
  addUrl,
  removeUrl,
  updateTranslation,
  addRedirectURI,
  removeRedirectURI,
  changeRegisterFieldName,
  addNewOptions,
  removeOption,
  changeRegisterValidation,
  updateInitialLoader,
  modifyRegisterValidation,
  updateValidationvalue,
  updateRegexmessagevalue,
  updateRegexpatternvalue,
  changeRegisterConsentValidation,
  changeRegisterConsentLabel,
  completeButtonDisable,
  updateOnboardKind,
  updateAppData,
  changeAppname,
  kind,
  setLoginUrl,
  setRedirectUrl,
  request_id,
  updateRequestId,
  removeConsent,
  deleteConsent,
  setSocialLogin,
  updateFieldMapping,
  removeLanguage,
  resetOnboard,
  updateOption,
  onSubmit,
  onSubmitProfile,
  onSubmitMigrate,
  onSubmitRegister,
  setAllowUpdates,
  changeClientName,
} = onboardSlice.actions;

export default onboardSlice.reducer;
