import React, { useEffect } from "react";
import { useState } from "react";
import AmgenLogo from "../Assets/Group1.svg";
import Popup from "./Popup";
import Sidebar from "../Sidebar/Sidebar";
import MyComponent from "../Table/Table";
import axios from "axios";
import Revokepopup from "./Revokepopup";
import * as constant from "../Constants/constant";
import { updateLoader } from "../../store/slice";
import { useSelector, useDispatch } from "react-redux";
import "../style.css";
import Popover from "@mui/material/Popover";
import UserPopover from "../userpopover";
import { setShow } from "../../store/appslice";
import { sanitizeUserInput } from "../../Helper/sanitize";
import NewApp from "./newApp";
import MessagePopup from "../Popup/Messagepopup";
import { trimData } from "../../Helper/onboard";
import ListUser from "./listUser";

function UserAdd() {
  const dispatch = useDispatch();
  const{role,appList} = useSelector((state) => state.dashboard);
  const { show } = useSelector((state) => state.app);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allApp, setAllApp] = useState([]);
  const [step, setStep] = useState("");
  const [currole, setCurrole] = useState("");
  const [userApp, setUserApp] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if(role === "App Owner"){
      setStep("addUser");
    }else{
    setStep("listUser");
    }
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`, {
        headers: { ...constant.headers },
      })
      .then((response) => {
        if(role === "Super Admin"){
          setAllApp(response.data.data);
        }else{
        setAllApp(appList);
        }
      })
      .catch((error) => {
        console.log(sanitizeUserInput(JSON.stringify(error)));
      });
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [email, setEmail] = useState("");
  const [userDet, setUserDet] = useState({
    role: "",
    aliasName: "",
    application_name: "",
    appOwner: "",
    country: "",
    language: "en",
    mailBlastCountries: [],
    allowedLanguages: [],
  });
  const [roleList, setRoleList] = useState([]);
  const [userrole, setUserrole] = useState({});

  const [error, setError] = useState({ app: "", email: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState({
    search: false,
    addUser: false,
    newApp: true,
    updateRole:false
  });
  const [msgData, setMsgData] = useState({
    title: "",
    body: "",
    success: false,
  });
  const [msg, setMsg] = useState(false);
  const handleModel = () => {
    setIsOpen((pre) => !pre);
  };
  const handleMsg = () => {
    setMsg((pre) => !pre);
  };

  const reset = () => {
    setUserrole({
      role: "",
      email:""
    });

    setUserDet({
      role: "",
      aliasName: "",
      application_name: "",
      appOwner: "",
      country: "",
      language: "en",
      mailBlastCountries: [],
      allowedLanguages: [],
    });
    setRoleList([]);
    setEmail("");
    setLoading({ search: false, addUser: false, newApp: true });
  };

  const SearchUser = () => {
    setLoading((pre) => ({ ...pre, search: true }));
    setUserDet({
      role: "",
      aliasName: "",
      application_name: "",
      appOwner: "",
      country: "",
      language: "en",
      mailBlastCountries: [],
      allowedLanguages: [],
    });
    setRoleList([]);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/fetch-user-apps`,
        { email: email.toLocaleLowerCase() },
        { headers: constant.headers }
      )
      .then((response) => {
        if (response.data.data.message) {
          if(role === "Super Admin"){
            setRoleList(["Super Admin", "Admin", "App Owner", "Dashboard Only"]);
          }else if(role === "Admin"){
            setRoleList(["Admin", "App Owner", "Dashboard Only"]);
          }else if(role === "App Owner"){
            setRoleList(["App Owner", "Dashboard Only"]);
          }
          // setRoleList(["Super Admin", "Admin", "App Owner", "Dashboard Only"]);
          setLoading((pre) => ({ ...pre, newApp: false }));
        } else {
          setUserApp(response.data.data.applications);
          setUserDet((pre) => ({ ...pre, role: response.data.data.role }));
          setRoleList([]);
          setLoading((pre) => ({ ...pre, newApp: false }));
        }
        setLoading((pre) => ({ ...pre, search: false }));
      })
      .catch((error) => {
        setMsgData({
          title: "Add user",
          body: "Something went wrong, try again...",
          success: false,
        });
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setLoading((pre) => ({ ...pre, search: false }));
        handleMsg();
      });
  };

  const RoleSearch = () => {
    setLoading((pre) => ({ search: true }));
    
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/fetch-user-apps`,
        { email: email.toLocaleLowerCase() },
        { headers: constant.headers }
      )
      .then((response) => {
        
        if (response.data.data.message) {
          

          setMsgData({
            title: "User not found",
            body: "User not found in the system",
            success: false,
          });

          handleMsg();
        } else {
          setCurrole(response.data.data.role);
          setUserrole((pre) => ({ ...pre, role: response.data.data.role }));
          if(role === "Super Admin"){
            setRoleList(["Super Admin", "Admin", "App Owner", "Dashboard Only"].filter((role)=>role !== response.data.data.role));
          }else if(role === "Admin"){
            setRoleList(["Admin", "App Owner", "Dashboard Only"].filter((role)=>role !== response.data.data.role));
          }else if(role === "App Owner"){
            setRoleList([ "App Owner", "Dashboard Only"].filter((role)=>role !== response.data.data.role));
          }
          //setRoleList(["Super Admin", "Admin", "App Owner", "Dashboard Only"].filter((role)=>role !== response.data.data.role));
          setLoading((pre) => ({ search: false }));
        }
        setLoading((pre) => ({ search: false }));
      })
      .catch((error) => {
        setMsgData({
          title: "Add user",
          body: "Something went wrong, try again...",
          success: false,
        });
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setLoading((pre) => ({search: false }));
        handleMsg();
      });
  };

  const submitData = () => {
    setLoading((pre) => ({ ...pre, addUser: true }));
    const temp = userApp.filter(
      (app) => app.application_name === userDet.application_name
    );
    if (temp.length > 0) {
      setLoading((pre) => ({ ...pre, addUser: false }));
      setError((pre) => ({
        ...pre,
        app: "User already have access to this application",
      }));
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
          {
            application_name: userDet.application_name,
            data: {
              role: userDet.role,
              application_name: userDet.application_name,
              email: email.toLocaleLowerCase(),
            },
            request_type: "AddUser",
            email: localStorage.getItem("username"),
          },
          {
            headers: { ...constant.headers },
          }
        )
        .then((response) => {
          setMsgData({
            title: "Add user",
            body: "User access request created successfully. Waiting for approval",
            success: true,
          });
          setLoading((pre) => ({ ...pre, addUser: false }));
          reset();
          handleMsg();
        })
        .catch((error) => {
          setMsgData({
            title: "Add user",
            body: "Something went wrong, try again...",
            success: false,
          });
          console.log(sanitizeUserInput(JSON.stringify(error)));
          setLoading((pre) => ({ ...pre, addUser: false }));
          reset();
          handleMsg();
        });
    }
  };

  const submitRole = () => {
    setLoading((pre) => ({updateRole: true }));
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
          {
            application_name: "N/A",
            data: {
              role: userrole.role,
              email: email.toLocaleLowerCase(),
            },
            request_type: "UpdateRole",
            email: localStorage.getItem("username"),
          },
          {
            headers: { ...constant.headers },
          }
        )
        .then((response) => {
          setMsgData({
            title: "Role Update",
            body: "User role update request created successfully. Waiting for approval",
            success: true,
          });
          reset();
          setCurrole("")
          handleMsg();
          setLoading((pre) => ({updateRole: false }));
        })
        .catch((error) => {
          setMsgData({
            title: "Role update",
            body: "Something went wrong, try again...",
            success: false,
          });
          console.log(sanitizeUserInput(JSON.stringify(error)));
          reset();
          setCurrole("")
          handleMsg();
          setLoading((pre) => ({updateRole: false }));
        });
      }

  return (
    <main style={{ margin: 0 }} className={show ? "space-toggle m-0" : null}>
      <aside
        style={{ background: "#045EB7" }}
        className={`sidebar ${show ? "show" : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => dispatch(setShow())}>
              <span className="fas fa-bars sidebarfirst"></span>
            </div>

            <Sidebar show={show}></Sidebar>
          </div>
        </nav>
      </aside>

      <div className="maintop">
        <header
          style={{
            background: "white",
            zIndex: "-1",
            height: "7vh",
            padding: 0,
          }}
          className={`header ${show ? "space-toggle" : null}`}
        >
          <span>
            <img
              style={{ paddingLeft: "1rem", height: "40px" }}
              src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
              alt=""
            ></img>
          </span>
          <div>
            <span
              aria-describedby={id}
              role="button"
              className="material-symbols-outlined headercolor fs-1 me-3"
              onClick={handleClick}
            >
              account_circle
            </span>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <UserPopover />
            </Popover>
          </div>
        </header>
        <button className="mainheading">
          <span>
            <b>Users</b>
          </span>
        </button>

        <div className="bodyfirst">
       
          <div className="d-flex h-100 py-4">
            <div className="d-block w-25 ps-5" style={{marginTop:"8%"}}>
            {role !== "App Owner" && <div className="d-block mb-4">
                  <div className={step == "listUser" ? "btn text-white tranbtn_add d-flex": "btn text-white tranbtn d-flex"} onClick={()=>{setStep("listUser")}}>
                    Users  
                  </div>
              </div>}
            <div className="d-block mb-4">
                <div className={step == "addUser" ? "btn text-white tranbtn_add d-flex": "btn text-white tranbtn d-flex"} onClick={()=>{setStep("addUser")}}>
                  Add User 
                </div>
              </div>
              <div className="d-block mt-4">
                  <div className={step == "roleUpdate" ? "btn text-white tranbtn_add d-flex": "btn text-white tranbtn d-flex"} onClick={()=>{setStep("roleUpdate")}}>
                    Role update  
                  </div>
              </div>
              
              </div>
           
          
            <div className="w-75 common-scroll overflow-auto">
              
                {step == "addUser" && <div className="w-100 overflow-none">
                
            <div className="mx-4">
            <h3 className="text-primary fz-bold">Add user</h3>
            <label className="">
                  Search user<span className="text-danger">*</span>
                </label>
                <br />
            <div className="d-flex w-100">
            
              <div className="w-100">
                <input
                  className="form-control mb-2"
                  placeholder="Enter email"
                  value={email}
                  type="text"
                  onChange={(e) => {
                    setLoading((pre) => ({ ...pre, newApp: true }));
                    setEmail(trimData(e.target.value));
                    if (constant.emailRegex.test(e.target.value.trim())) {
                      setError((pre) => ({ ...pre, email: "" }));
                    } else {
                      setError((pre) => ({
                        ...pre,
                        email: "Enter the valid email",
                      }));
                    }
                  }}
                ></input>
                {error.email !== "" && (
                  <div className="text-danger ms-1">{error.email}</div>
                )}
              </div>
              <div className="ms-2">
                {loading.search ? (
                  <button
                    className="btn"
                    style={{
                      background: "#045EB7",
                      fontSize: "18px",
                      zindex: "0",
                      color: "white",
                      float: "right",
                      width: "82px",
                      height: "38px",
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    className="btn"
                    style={{
                      background: "#045EB7",
                      fontSize: "18px",
                      zindex: "0",
                      color: "white",
                      float: "right",
                      width: "82px",
                      height: "38px",
                    }}
                    disabled={email === "" || error.email !== ""}
                    onClick={() => {
                      SearchUser();
                    }}
                  >
                    Search
                  </button>
                )}
              </div>
            </div>
              <div>
                <label>
                  Applications<span className="text-danger">*</span>
                </label>
                <br />
                <div className="d-flex align-items-center">
                {role === "Super Admin" &&  <select
                    value={userDet.application_name}
                    disabled={allApp.length === 0}
                    onChange={(e) => {
                      setError((pre) => ({ ...pre, app: "" }));
                      setUserDet((pre) => ({
                        ...pre,
                        application_name: e.target.value,
                      }));
                    }}
                    className="form-select mb-2 mt-1"
                  >
                    <option value="">Select an application</option>
                    {allApp.map((data, index) => {
                      return (
                        <option key={index} value={data.application_name}>
                          {data?.options?.aliasName ? data?.options?.aliasName : data?.application_name}
                        </option>

                      );
                    })}
                  </select>}

                  {role !== "Super Admin" &&  <select
                    value={userDet.application_name}
                    disabled={appList.length === 0}
                    onChange={(e) => {
                      setError((pre) => ({ ...pre, app: "" }));
                      setUserDet((pre) => ({
                        ...pre,
                        application_name: e.target.value,
                      }));
                    }}
                    className="form-select mb-2 mt-1"
                  >
                    <option value="">Select an application</option>
                    {appList.map((data, index) => {
                      return (
                        <option key={index} value={data.appName}>
                          {data?.aliasName ? data?.aliasName : data?.appName}
                        </option>

                      );
                    })}
                  </select>}
                  <div className="mx-2 fw-bold test-dark">or</div>
                  <button
                    className="btn text-white"
                    style={{ background: "#045EB7" }}
                    disabled={loading.newApp}
                    onClick={() => {
                      setUserDet((pre) => ({
                        ...pre,
                        application_name: "",
                      }));
                      handleModel();
                    }}
                  >
                    New app
                  </button>
                </div>
              </div>
              <div>
                <label>
                  Role<span className="text-danger">*</span>
                </label>
                <br />
                {roleList.length === 0 ? (
                  <input
                    type="text"
                    className="form-control"
                    value={userDet.role}
                    disabled
                  />
                ) : (
                  <select
                    value={userDet.role}
                    onChange={(e) => {
                      setUserDet((pre) => ({ ...pre, role: e.target.value }));
                    }}
                    className="form-select mb-2 mt-1"
                  >
                    <option value="">Select a role</option>
                    {roleList.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div className="mt-3">
                {loading.addUser ? (
                  <button
                    className="btn"
                    style={{
                      background: "#045EB7",
                      fontSize: "18px",
                      zindex: "0",
                      color: "white",
                      width: "118px",
                      height: "38px",
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    className="btn"
                    style={{
                      background: "#045EB7",
                      fontSize: "18px",
                      zindex: "0",
                      color: "white",
                      width: "118px",
                      height: "38px",
                    }}
                    disabled={
                      userDet.application_name === "" || userDet.role === ""
                    }
                    onClick={() => {
                      submitData();
                    }}
                  >
                    + Add User
                  </button>
                )}
              </div>
              {error.app !== "" && (
                <div className="text-danger">{error.app}</div>
              )}
            </div>
                </div>}
                {step == "roleUpdate" && <div className="w-100 overflow-none">
                
                <div className="mx-4">
                <h3 className="text-primary fz-bold">Role Update</h3>
                <label className="">
                      Search User<span className="text-danger">*</span>
                    </label>
                    <br />
                <div className="d-flex w-100">
                
                  <div className="w-100">
                    <input
                      className="form-control mb-2"
                      placeholder="Enter email"
                      value={email}
                      type="text"
                      onChange={(e) => {
                        reset();
                        setCurrole("");
                        // setLoading((pre) => ({ ...pre, search: true }));
                        setEmail(trimData(e.target.value));
                        if (constant?.emailRegex?.test(e.target.value.trim())) {
                          setError((pre) => ({ email: "" }));
                        } else {
                          setError((pre) => ({
                            email: "Enter the valid email",
                          }));
                        }
                      }}
                    ></input>
                    {error.email !== "" && (
                      <div className="text-danger ms-1">{error.email}</div>
                    )}
                  </div>
                  <div className="ms-2">
                    {loading.search ? (
                      <button
                        className="btn"
                        style={{
                          background: "#045EB7",
                          fontSize: "18px",
                          zindex: "0",
                          color: "white",
                          float: "right",
                          width: "82px",
                          height: "38px",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn"
                        style={{
                          background: "#045EB7",
                          fontSize: "18px",
                          zindex: "0",
                          color: "white",
                          float: "right",
                          width: "82px",
                          height: "38px",
                        }}
                        disabled={email === "" || error.email !== ""}
                        onClick={() => {
                          RoleSearch();
                        }}
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>
                  <div>
                    <label>
                      Role<span className="text-danger">*</span>
                    </label>
                    <br />
                      <select
                      value={userrole.role}
                      disabled={email === "" || currole === "" || loading.search}
                      onChange={(e) => {
                        setUserrole((pre) => ({ ...pre, role: e.target.value }));
                      }}
                      className="form-select mb-2 mt-1"
                    >
                      <option value="">{currole}</option>
                      {roleList.map((data, index) => {
                        return (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                    </div>
                  <div className="mt-3">
                    {loading.updateRole ? (
                      <button
                        className="btn"
                        style={{
                          background: "#045EB7",
                          fontSize: "18px",
                          zindex: "0",
                          color: "white",
                          width: "128px",
                          height: "38px",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn"
                        style={{
                          background: "#045EB7",
                          fontSize: "18px",
                          zindex: "0",
                          color: "white",
                          width: "128px",
                          height: "38px",
                        }}
                        disabled={userrole.role === currole || userrole.role === "" || currole === ""}
                        onClick={() => {
                          submitRole();
                        }}
                      >
                        Update Role
                      </button>
                    )}
                  </div>
                  {error.app !== "" && (
                    <div className="text-danger">{error.app}</div>
                  )}
                </div>
                    </div>}
                {step == "listUser" && <ListUser/>}
                
            </div>
          </div>
          
        </div>
      </div>
      <NewApp
        modalIsOpen={isOpen}
        closeModal={handleModel}
        data={userDet}
        setData={setUserDet}
        roleList={roleList}
        setMsgData={setMsgData}
        handleMsg={handleMsg}
        reset={reset}
        email={email}
      ></NewApp>
      <MessagePopup
        modalIsOpen={msg}
        closeModal={handleMsg}
        title={msgData.title}
        body={msgData.body}
        success={msgData.success}
      />
    </main>
  );
}

export default UserAdd;
