import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeRegisterConsentValidation,
  changeRegisterConsentLabel,
} from "../../store/slice";

export default function Consent({country,}) {
  const { data } = useSelector((state) => state.onboard);
  const {info} = useSelector((state) => state.info);
  const dispatch = useDispatch();
  const[prohandle,setProhandle]=useState(true);
  const[conhandle,setConhandle]=useState(true);
  return (
    <div className="">
      {/* <br/> */}
      {/* <div><b style={{
        color:"#045EB7",
        marginLeft: "1rem",
        borderBottom: "1px solid #045EB7",
      }}>Consent</b> &nbsp;&nbsp;(* If you checked below boxes, terms and conditions and consent will be made required in your application)</div> */}
        {data.options?.consent?.fields?.map((data, index) => (
          <div key={index} className="mb-3">
              <div className="d-flex align-items-center mb-2">
                            <b
                              className="me-1 user-select-none"
                              style={{
                                color: "#045EB7",
                                // borderBottom: "1px solid #045EB7",
                              }}
                            >
                              {data.title}
                            </b>
                            <span
                              class="material-symbols-outlined fs-6 text-info"
                              role="button"
                              data-toggle="tooltip"
                              title={info[data.name]}
                            >
                              info
                            </span>
                          </div>
            <form onSubmit={(e)=>{
              e.preventDefault();
              e.target[0].disabled=!e.target[0].disabled;
              setProhandle(!prohandle);
              e.target[1].disabled=!e.target[1].disabled;

              }}>
            <div className="d-flex">
              <div className="form-check my-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data.validations.required === true && "checked"}
                  value=""
                  disabled={data.name==="termsandcondition"}
                  id="flexCheckDefault"
                  onChange={() =>
                    dispatch(changeRegisterConsentValidation(index))
                  }
                />
              </div>
              <div className="col-10 px-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={data.placeholder}
                    defaultValue={data.label}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={((e)=>dispatch(changeRegisterConsentLabel({index:index,value:e.target.value})))}
                  />
                </div>
              </div>
              {/* {data.name.toLowerCase() === "promotions" &&
              <div>
                  {prohandle ? <button 
                  className="btn btn-primary" 
                  type="submit"
                  onChange={()=>dispatch(removeConsent())}
                  >Remove</button> 
                  :
                  <button 
                  className="btn btn-primary"
                  onChange={()=>dispatch(removeConsent())}
                  >Add</button>}
              </div>
              } */}
            </div>
            </form>
          </div>
        ))}
    </div>
  );
}
