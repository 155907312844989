import React from 'react';
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { resetWhitlist } from '../../store/tempslice';
import { setApplicationDetails } from '../../store/whitelistslice';

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      background: "white"
    },
  };

export default function Draftpopup({ modalIsOpen, closeModal }) {
  const { whitelist } = useSelector((state) => state.temp);
  const dispatch = useDispatch();
  const ClosePopup=()=>{
    dispatch(resetWhitlist());
    closeModal();
  }
  return (
    <div>
         <Modal
        isOpen={modalIsOpen}
        onRequestClose={ClosePopup}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
        {/* <span
            type="button"
            class="material-symbols-outlined text-white"
            style={{
              float: "right",
              background: "#AAAAAA",
              borderRadius: "15px",
            }}
            onClick={ClosePopup}
          >
            close
          </span> */}
          <div style={{color:"#0463c3"}} className="font-weight-bold h3">Draft</div>
          <div className='mt-3'>Do you want save your changes?</div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-primary' onClick={(()=>{
              dispatch(setApplicationDetails({app:whitelist.appName,orgin:[...whitelist.orginUrl],redirect:[...whitelist.redirectUrl]}));
              ClosePopup();
              })}>Yes</button>
            <button className='btn btn-danger ms-2' onClick={ClosePopup}>No</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
