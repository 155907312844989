import React from "react";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import MyComponent from "../Table/Table";
import axios from 'axios';
import * as constant from '../Constants/constant';
import { useState } from "react";
import { Applicationdata } from "./Application_data";
import { 
    updateLoader
} from "../../store/slice";
import { useSelector, useDispatch } from "react-redux";
import {applicationsOnboarded,getCsrfToken, makeDateFormat} from '../../Helper/helper';
import { sanitizeUserInput } from "../../Helper/sanitize";

function UserTable (props) {
    const dispatch = useDispatch();
    // dispatch(updateLoader(false));
    const [data, setData] = useState(props.activeApplicationData || "");
    const [appname, setAppname] = useState([]);
    const [value, setValue] = useState("");
    const { csrf } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
    const{role} = useSelector((state) => state.dashboard);
    let user = localStorage.getItem("username");

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
        {
        headers: { ...constant.headers },
        }            
        )
        .then((res)=>{

            axios
            .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
            { email: user },
            {
                headers: { ...constant.headers,"csrf-token": res.data.data.csrfToken },
            }
            )
            .then((response) => {
                if(props.country){
                    const filteredData = response.data.data.data.filter((data) => data.country === props.country);
                    if(props.dashapp){
                        const filteredApp = filteredData.filter((data) => data.application_name === props.dashapp);
                        props.setUserTableCount(filteredApp.length);
                        setData(filteredApp);
                    }else{
                        props.setUserTableCount(filteredData.length);
                        setData(filteredData);
                    }
                }else{
                    props.setUserTableCount(response.data.data.data.length);
                    setData(response.data.data.data);
                }
            })
            .catch((error) => {
                console.log(sanitizeUserInput(JSON.stringify(error)),"errorItem")
        });

        })
    },[props, props.goflag, user])

    
    


    // React.useEffect(() => {
    //     if(props.goflag){
    //     axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
    //     {
    //         "applicationsOnboarded_new":true,
    //         "country":props.country,
    //         "startDate": props.startdate.toISOString(),
    //         "endDate": props.enddate.toISOString(),
    //         "appName":props.dashapp
    //     },
    //     {
    //         headers: { ...constant.headers,"csrf-token": csrf },
    //     }
    //     )
    //     .then((response) => {
    //         setData(response.data)
    //         setAppname(response.data.map((data)=>data.application_name))
    //         setValue(response.data.map((data)=>data.OccurenceValue))
    //         // dispatch(updateLoader(true));
    //     })
    //     .catch(error => {console.log(error)}) 
    // }
    // }, [props.goflag]);

    // React.useEffect(() => {
    //     setData(props.activeApplicationData)
    //     setAppname(props.activeApplicationData.map((data)=>data.application_name))
    //     setValue(props.activeApplicationData.map((data)=>data.OccurenceValue))
    // }, [props.activeApplicationData]);
        

    const column = [
        {
            name: "Application Name",
            cell: (row) => row.alias_name,
            sortable:true,
            selector: (row) => row.alias_name,
            
        },
        // {
        //     name: "",
        //     cell: (row) => row.application_name,
        //     sortable:true,
        //     selector:"application_name"
            
        // },
        {
            name: "Owner Name",
            cell: (row) => row.owner_name,
            selector: (row) => row.owner_name,
            
        },
        {
            name: "Country",
            cell: (row) => row.country,
            sortable:true,
            selector: (row) => row.country,
        },
        {
            name: "Default Language",
            cell: (row) => row.language,
        },
        {
            name: "Requested Date",
            cell: (row) => row?.requested_date ? makeDateFormat(row?.requested_date):row?.requested_date,
        },
        {
            name: "Approval date",
            cell: (row) => row?.processed_date ? makeDateFormat(row?.processed_date):row?.processed_date,
        },
        {
            name: "Approved by",
            cell: (row) => row.processed_by,
        }
    ]
    return <div className="mx-4">
                <div className="row">
                        <div className="col">
                            <div style={{}}><MyComponent header="true" fileName="Application details" data={data} tableHeight="47vh" columns={column} paginationPerPage={10}/></div>
                        </div>
                </div>
</div>
    
    
}

export default UserTable;