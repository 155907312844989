import React,{useState} from "react";
import Modal from "react-modal";
import axios from "axios";
import * as constant from "../Constants/constant";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { sanitizeUserInput } from "../../Helper/sanitize";
import { resetOnboard } from "../../store/slice";
import { resetFinal } from "../../store/onboardfinal";
import { persistor } from "../../store";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "white",
    color: "#6c757d",
  },
};

function Popup({ modalIsOpen, closeModal,setModalBody,setShowModal }) {
  const { data} = useSelector((state) => state.onboard);
  const{role} = useSelector((state) => state.dashboard);
  const {mailTemplate,successUrl,finalTranslations} = useSelector((state) => state.onboardFinal);
    const { csrf } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();
const [loading,setLoading]=useState(false);
function handleSubmit(){
  setLoading(true);
  const final={...data,mailTemplate:{...mailTemplate,"redirectURL":successUrl}};
  if (final.options) {
    final.options = Object.keys(final.options)
      .filter(key => !['consent','social','updateFlag','fields','field_mapping'].includes(key))
      .reduce((obj, key) => {
        obj[key] = final.options[key];
        return obj;
      }, {});
  }
   axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
          {
            application_name: data.name,
            data: final,
            translations: finalTranslations,
            request_type: "onboard",
            email: localStorage.getItem("username"),
          },
          {
            headers: { ...constant.headers,"csrf-token": csrf },
          }
        )
        .then((response) => { 
          setModalBody("Would you like to track your request?");
          setLoading(false);
          persistor.purge();
          setShowModal(true);
          closeModal();
        })
        .catch((error) => {
          setLoading(false);
        console.log(sanitizeUserInput(JSON.stringify(error)));
        });
}

const handleCancel=()=>{
  persistor.purge();
 closeModal();
 role.toLowerCase() == "app owner"
 ? navigate(`/applicationowner`,{replace:true})
 : navigate(`/applicationadmin`,{replace:true});
}

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <div className='text-dark'>
               <div className='text-start'><b>Onboarding</b></div>
            <div className="">Are you sure want to onboard?</div>
            <div className='d-flex justify-content-end'>
                <button 
                type="button" 
                className="btn onboardbtn2 btn-sm me-4" 
                style={{ width: "123px", height: "41px" }}
                onClick={() => {
                  handleCancel();
                  }}>
                    Cancel
                </button>
                {loading ? (
                      <button
                      className="onboardbtn2"
                      style={{ width: "123px", height: "41px" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="onboardbtn2"
                        style={{ width: "123px", height: "41px" }}
                        onClick={() => {handleSubmit()
                        }}
                      >
                        Submit
                      </button>
                    )}
            </div>
   </div>
      </Modal>
    </div>
  );
}

export default Popup;
