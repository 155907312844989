import React from "react";
import './style.css';
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { useState, useEffect } from "react";
import { useOktaAuth,withOktaAuth } from '@okta/okta-react';
import jwt_decode from "jwt-decode";
import {setAllowedApplication,updateInitialLoader,setActive} from '../store/dashboardslice';
import {updateTranslation,resetOnboard} from "../store/slice";
import { resetMailSlice } from "../store/mailslice";
import { persistor } from "../store";

function UserPopover () {

    const{allowedApp, role,initialLoader} = useSelector((state) => state.dashboard);
    let user = localStorage.getItem("username");
    var res = user==null?"": user.split("@");
    let firstletter = user?.slice(0,1).toUpperCase();
    const { authState, oktaAuth } = useOktaAuth();
    const [name,setName] = useState("");
     const dispatch = useDispatch();

    const token = localStorage.getItem("okta-token-storage")
    useEffect(() => {
        if(token){
            const parsedToken = JSON.parse(token)
            const decoded = jwt_decode(parsedToken["idToken"]["idToken"]);
            setName(decoded.name)
        }
    }, []);
     

    return <div className="container">
        <div>
            <div className="d-flex mb-3">
                {/* <div className="circle"><div className="txt">TEXT</div></div> */}
                <div className="rounded-circle circle d-flex align-items-center pe-none mt-2">{firstletter}</div>
                <div className="ms-3">
                    <div className="txt-dark pe-none" style={{fontSize:"x-large"}}>{name !== "" ? name : res[0]}</div>
                    <div className="pe-none">{role}</div>
                </div>
            </div>
            <hr/>
            <div>
                {/* <div type="button">My profile</div>
                <div type="button">Help</div> */}
                <div className="mb-2" type="button" onClick={(async ()=>{ 
                        // dispatch(updateTranslation([]));
                        persistor.purge();
                        // dispatch(resetOnboard());
                        // dispatch(resetMailSlice());
                        // dispatch(setAllowedApplication(""))
                        // localStorage.removeItem("username");
                        // localStorage.removeItem("role");
                        // dispatch(setActive("HCP Pending"));

                        await oktaAuth.signOut({postLogoutRedirectUri: `${window.location.origin}/logout`});
                        })}>Logout</div>
            </div>
        </div>
    </div>
}

export default UserPopover;