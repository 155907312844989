import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: "",
  application: "",
  allowedApp: [],
  orginalUrl: [],
  redirectUrl: [],
};

export const whitelistSlice = createSlice({
  name: "whitelist",
  initialState,
  reducers: {
    setApplicationDetails: (state, actions) => {
      state.application = actions.payload.app;
      state.orginalUrl=actions.payload.orgin;
      state.redirectUrl=actions.payload.redirect;
    },
    setAllowedApplicationWhitelist: (state, actions) => {
      let adminApp = actions.payload?.data?.map(
        (data) => data.application_name
      );
      state.allowedApp = adminApp;
      state.role = actions.payload?.role;
    },
    resetApplicationDetails:(state)=>{
      state.application = "";
      state.orginalUrl= [];
      state.redirectUrl=[];
    }
  },
});

export const {
  setApplicationDetails,
  setAllowedApplicationWhitelist,
  resetApplicationDetails
} = whitelistSlice.actions;
export default whitelistSlice.reducer;
