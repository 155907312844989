import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import * as constant from '../Constants/constant';
import Preview from '../Dynamic_mail/preview';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        background: 'white',
        color: 'white'
    },
};

function Previewpopup({ modalIsOpen, closeModal, title,body,login,tnc,needhelp,address1,address2,salutation,need_help_link,amgen_uri,footer_logo,privacy,privacy_link,copy_right_year,banner,tnc_link}) {

    let subtitle;


    function afterOpenModal() {
        // subtitle.style.color = 'white';
    }

    return (
        <div className='position-relative'>
            <Modal
                isOpen={modalIsOpen}
                // //onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
               <div style={{height:"80vh"}}>
                <Preview  
                title={title}
                body={body}
                login={login}
                tnc={tnc}
                banner={banner}
                tnc_link={tnc_link}
                privacy={privacy}
                privacy_link={privacy_link}
                need_help_link={need_help_link}
                footer_logo={footer_logo}
                copy_right_year={copy_right_year}
                amgen_uri={amgen_uri}          
                needhelp={needhelp}
                address1={address1}
                address2={address2}
                salutation={salutation}
                />
               </div>
                
            </Modal>
        </div>
    );
}

export default Previewpopup;