const devAppOwner = [
    {
        to:'/dashboard',
        title:'Dashboard',
        symbol:'material-symbols-outlined',
        symbolName:'dashboard',
        item:'Dashboard'
    },
    // {
    //     to:'/applicationowner',
    //     title:'Applications',
    //     symbol:'material-symbols-outlined',
    //     symbolName:'apps',
    //     item:'Applications'
    // }, 
    // {
    //     to:'/access',
    //     title:'Access',
    //     symbol:'material-symbols-outlined',
    //     symbolName:'settings_applications',
    //     item:'Application Access'
    // },
    {
        to:'/user',
        title:'User',
        symbol:'material-symbols-outlined',
        symbolName:'person',
        item:'Users'
    },
    {
        to:'/myrequest',
        title:'My Requests',
        symbol:'material-symbols-outlined',
        symbolName:'request_quote',
        item:'My Requests'
    },
    {
        to:'/preloaduser',
        title:'Preload',
        symbol:'material-symbols-outlined',
        symbolName:'group_add',
        item:'Preload User'
    },
    // {
    //     to:'/mailtemplate',
    //     title:'Mail Template',
    //     symbol:'material-symbols-outlined',
    //     symbolName:'mail',
    //     item:'Mail Template'
    // },
    // {
    //     to:'/updatetranslation',
    //     title:'Update Translation',
    //     symbol:'material-symbols-outlined',
    //     symbolName:'translate',
    //     item:'Update Translation'
    // },
    // {
    //     to:'/clientcreation',
    //     title:'Client Creation',
    //     symbol:'material-symbols-outlined',
    //     symbolName:'api',
    //     item:'Client Creation'
    // },
    {
        to:'/whitelist',
        title:'Whitelisting URLs',
        symbol:'material-symbols-outlined',
        symbolName:'add_link',
        item:'Whitelist'
    },
    {
        to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
        title:'Validation Form',
        symbol:'material-symbols-outlined',
        symbolName:'dynamic_form',
        item:'Validationform'
    }
    ]
const appOwner = [
        {
            to:'/dashboard',
            title:'Dashboard',
            symbol:'material-symbols-outlined',
            symbolName:'dashboard',
            item:'Dashboard'
        },
        // {
        //     to:'/applicationowner',
        //     title:'Applications',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'apps',
        //     item:'Applications'
        // }, 
        // {
        //     to:'/access',
        //     title:'Access',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'settings_applications',
        //     item:'Application Access'
        // },
        {
            to:'/user',
            title:'User',
            symbol:'material-symbols-outlined',
            symbolName:'person',
            item:'Users'
        },
        {
            to:'/myrequest',
            title:'My Requests',
            symbol:'material-symbols-outlined',
            symbolName:'request_quote',
            item:'My Requests'
        },
        {
            to:'/preloaduser',
            title:'Preload',
            symbol:'material-symbols-outlined',
            symbolName:'group_add',
            item:'Preload User'
        },
        // {
        //     to:'/mailtemplate',
        //     title:'Mail Template',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'mail',
        //     item:'Mail Template'
        // },
        // {
        //     to:'/updatetranslation',
        //     title:'Update Translation',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'translate',
        //     item:'Update Translation'
        // },
        // {
        //     to:'/clientcreation',
        //     title:'Client Creation',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'api',
        //     item:'Client Creation'
        // },
        {
            to:'/whitelist',
            title:'Whitelisting URLs',
            symbol:'material-symbols-outlined',
            symbolName:'add_link',
            item:'Whitelist'
        },
        {
            to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
            title:'Validation Form',
            symbol:'material-symbols-outlined',
            symbolName:'dynamic_form',
            item:'Validationform'
        }
    ]
const devAdmin = [
    {
    to:'/dashboard',
    title:'Dashboard',
    symbol:'material-symbols-outlined',
    symbolName:'dashboard',
    item:'Dashboard'
    },
    {
        to:'/applicationadmin',
        title:'Applications',
        symbol:'material-symbols-outlined',
        symbolName:'apps',
        item:'Applications'
    }, 
    {
        to:'/user',
        title:'User',
        symbol:'material-symbols-outlined',
        symbolName:'person',
        item:'Users'
    },
    {
        to:'/request',
        title:'Requests',
        symbol:'material-symbols-outlined',
        symbolName:'request_quote',
        item:'Requests'
    },
    {
        to:'/preloaduser',
        title:'Preload',
        symbol:'material-symbols-outlined',
        symbolName:'group_add',
        item:'Preload User'
    },
    {
        to:'/mailtemplate',
        title:'Mail Template',
        symbol:'material-symbols-outlined',
        symbolName:'mail',
        item:'Mail Template'
    },
    {
        to:'/updatetranslation',
        title:'Update Translation',
        symbol:'material-symbols-outlined',
        symbolName:'translate',
        item:'Update Translation'
    },
    {
        to:'/clientcreation',
        title:'Client Creation',
        symbol:'material-symbols-outlined',
        symbolName:'api',
        item:'Client Creation'
    },
    {
        to:'/whitelist',
        title:'Whitelisting URLs',
        symbol:'material-symbols-outlined',
        symbolName:'add_link',
        item:'Whitelist'
    },
    {
        to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
        title:'Validation Form',
        symbol:'material-symbols-outlined',
        symbolName:'dynamic_form',
        item:'Validationform'
    }
    ]
const admin = [
        {
        to:'/dashboard',
        title:'Dashboard',
        symbol:'material-symbols-outlined',
        symbolName:'dashboard',
        item:'Dashboard'
        },
        {
            to:'/applicationadmin',
            title:'Applications',
            symbol:'material-symbols-outlined',
            symbolName:'apps',
            item:'Applications'
        }, 
        {
            to:'/user',
            title:'User',
            symbol:'material-symbols-outlined',
            symbolName:'person',
            item:'Users'
        },
        {
            to:'/request',
            title:'Requests',
            symbol:'material-symbols-outlined',
            symbolName:'request_quote',
            item:'Requests'
        },
        {
            to:'/preloaduser',
            title:'Preload',
            symbol:'material-symbols-outlined',
            symbolName:'group_add',
            item:'Preload User'
        },
        // {
        //     to:'/mailtemplate',
        //     title:'Mail Template',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'mail',
        //     item:'Mail Template'
        // },
        // {
        //     to:'/updatetranslation',
        //     title:'Update Translation',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'translate',
        //     item:'Update Translation'
        // },
        {
            to:'/clientcreation',
            title:'Client Creation',
            symbol:'material-symbols-outlined',
            symbolName:'api',
            item:'Client Creation'
        },
        {
            to:'/whitelist',
            title:'Whitelisting URLs',
            symbol:'material-symbols-outlined',
            symbolName:'add_link',
            item:'Whitelist'
        },
        {
            to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
            title:'Validation Form',
            symbol:'material-symbols-outlined',
            symbolName:'dynamic_form',
            item:'Validationform'
        }
    ]
const devSuperAdmin = [
    {
    to:'/dashboard',
    title:'Dashboard',
    symbol:'material-symbols-outlined',
    symbolName:'dashboard',
    item:'Dashboard'
    },
    {
        to:'/applicationadmin',
        title:'Applications',
        symbol:'material-symbols-outlined',
        symbolName:'apps',
        item:'Applications'
    }, 
    {
        to:'/user',
        title:'User',
        symbol:'material-symbols-outlined',
        symbolName:'person',
        item:'Users'
    },
    {
        to:'/request',
        title:'Requests',
        symbol:'material-symbols-outlined',
        symbolName:'request_quote',
        item:'Requests'
    },
    {
        to:'/preloaduser',
        title:'Preload',
        symbol:'material-symbols-outlined',
        symbolName:'group_add',
        item:'Preload User'
    },
    {
        to:'/mailtemplate',
        title:'Mail Template',
        symbol:'material-symbols-outlined',
        symbolName:'mail',
        item:'Mail Template'
    },
    {
        to:'/updatetranslation',
        title:'Update Translation',
        symbol:'material-symbols-outlined',
        symbolName:'translate',
        item:'Update Translation'
    },
    {
        to:'/clientcreation',
        title:'Client Creation',
        symbol:'material-symbols-outlined',
        symbolName:'api',
        item:'Client Creation'
    },
    {
        to:'/whitelist',
        title:'Whitelisting URLs',
        symbol:'material-symbols-outlined',
        symbolName:'add_link',
        item:'Whitelist'
    },
    {
        to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
        title:'Validation Form',
        symbol:'material-symbols-outlined',
        symbolName:'dynamic_form',
        item:'Validationform'
    }
    ]
const superAdmin = [
        {
        to:'/dashboard',
        title:'Dashboard',
        symbol:'material-symbols-outlined',
        symbolName:'dashboard',
        item:'Dashboard'
        },
        {
            to:'/applicationadmin',
            title:'Applications',
            symbol:'material-symbols-outlined',
            symbolName:'apps',
            item:'Applications'
        }, 
        {
            to:'/user',
            title:'User',
            symbol:'material-symbols-outlined',
            symbolName:'person',
            item:'Users'
        },
        {
            to:'/request',
            title:'Requests',
            symbol:'material-symbols-outlined',
            symbolName:'request_quote',
            item:'Requests'
        },
        {
            to:'/preloaduser',
            title:'Preload',
            symbol:'material-symbols-outlined',
            symbolName:'group_add',
            item:'Preload User'
        },
        // {
        //     to:'/mailtemplate',
        //     title:'Mail Template',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'mail',
        //     item:'Mail Template'
        // },
        // {
        //     to:'/updatetranslation',
        //     title:'Update Translation',
        //     symbol:'material-symbols-outlined',
        //     symbolName:'translate',
        //     item:'Update Translation'
        // },
        {
            to:'/clientcreation',
            title:'Client Creation',
            symbol:'material-symbols-outlined',
            symbolName:'api',
            item:'Client Creation'
        },
        {
            to:'/whitelist',
            title:'Whitelisting URLs',
            symbol:'material-symbols-outlined',
            symbolName:'add_link',
            item:'Whitelist'
        },
        {
            to:'https://forms.office.com/Pages/ResponsePage.aspx?id=pmZCS2gTr0GtWlnrY0962CcoSrV3QEtHhF-3_BGaXKhUQTFPQ1FRWDc3OUw5TVZZOVZVQzU0TlczSS4u',
            title:'Validation Form',
            symbol:'material-symbols-outlined',
            symbolName:'dynamic_form',
            item:'Validationform'
        }
    ]
const dashboardOnly = [
    {
    to:'/dashboard',
    title:'Dashboard',
    symbol:'material-symbols-outlined',
    symbolName:'dashboard',
    item:'Dashboard'
    }
]

export  {
    dashboardOnly,
    superAdmin,
    admin,
    appOwner,
    devSuperAdmin,
    devAdmin,
    devAppOwner
}