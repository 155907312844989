import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import axios from "axios";
import * as constant from "../Constants/constant";
import { useSelector } from "react-redux";
import { sanitizeUserInput } from "../../Helper/sanitize";
import { getCountry, getLanguage } from "../../Helper/helper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Countries from "../Data/Region";
import Languages from "../Data/Languages";
import { trimData } from "../../Helper/onboard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "50vh",
    background: "white",
    color: "black",
  },
};

export default function NewApp({
  modalIsOpen,
  closeModal,
  data,
  setData,
  roleList,
  setMsgData,
  handleMsg,
  reset,email
}) {
  const [loading, setLoading] = useState(false);
  const [selectedLang, setLanguage] = useState([
    { code: "en", label: "English" },
  ]);

  const onSubmit = () => {
    setLoading(true);
    const allowed = selectedLang.map((data) => {
      return { label: data.label, value: data.code };
    });
    const mailblast = [
      {
        label: getCountry(data.country),
        supportedlang: selectedLang.map((data) => {
          return { Label: data.label, Value: data.code };
        }),
        value: data.country,
      },
    ];
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-request`,
        {
          application_name: data.application_name,
          data: {
            ...data,
            mailBlastCountries: mailblast,
            allowedLanguages: allowed,
            email: email,
          },
          request_type: "AddUser",
          email: localStorage.getItem("username"),
        },
        {
          headers: { ...constant.headers },
        }
      )
      .then((response) => {
        setMsgData({
          title: "Add user",
          body: "User access request created successfully. Waiting for approval",
          success: true,
        });
        setLoading(false);
        closeModal();
        reset();
        handleMsg();
      })
      .catch((error) => {
        setMsgData({
          title: "Add user",
          body: "Something went wrong, try again...",
          success: false,
        });
        console.log(sanitizeUserInput(JSON.stringify(error)));
        setLoading(false);
        closeModal();
        reset();
        handleMsg();
      });
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
      >
        <div>
          <span
            type="button"
            className="material-symbols-outlined text-white"
            style={{
              float: "right",
              background: "#AAAAAA",
              borderRadius: "15px",
            }}
            onClick={() => {
              setData({
                aliasName: "",
                application_name: "",
                appOwner: "",
                country: "",
                language: "en",
                mailBlastCountries: [],
                allowedLanguages: [],
              });
              setLanguage([{ code: "en", label: "English" }]);
              closeModal();
            }}
          >
            close
          </span>
          <div style={{ color: "#0463c3" }} className="font-weight-bold h3">
            Add user{" "}
          </div>
          <div>
            <div>
              <label>
                New application<span className="text-danger">*</span>
              </label>
              <br />
              <input
                className="form-control mb-2"
                placeholder="Application name"
                value={data.application_name}
                type="text"
                onChange={(e) => {
                  setData((pre) => ({
                    ...pre,
                    application_name: trimData(e.target.value),
                  }));
                }}
              ></input>
            </div>
            <div>
              <label>
                Role<span className="text-danger">*</span>
              </label>
              <br />
              {roleList.length === 0 ? (
                <input
                  type="text"
                  className="form-control"
                  value={data.role}
                  disabled
                />
              ) : (
                <select
                  value={data.role}
                  onChange={(e) => {
                    setData((pre) => ({ ...pre, role: e.target.value }));
                  }}
                  className="form-select mb-2 mt-1"
                >
                  <option selected>Select a role</option>
                  {roleList.map((data, index) => {
                    return (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div>
              <label>
                Alias Name<span className="text-danger">*</span>
              </label>
              <br />
              <input
                className="form-control mb-2"
                placeholder="Alias name"
                value={data.aliasName}
                type="text"
                onChange={(e) => {
                  setData((pre) => ({ ...pre, aliasName: e.target.value }));
                }}
              ></input>
            </div>
            <div>
              <label>
                Owner name<span className="text-danger">*</span>
              </label>
              <br />
              <input
                className="form-control mb-2"
                placeholder="Owner name"
                value={data.appOwner}
                type="text"
                onChange={(e) => {
                  setData((pre) => ({ ...pre, appOwner: e.target.value }));
                }}
              ></input>
            </div>
            <div className="mb-2">
              <label>
                Country<span className="text-danger">*</span>
              </label>
              <br />
              <Autocomplete
                id="country-select-demo"
                sx={{ width: "100%" }}
                options={Countries}
                value={{
                  value: data.country,
                  label: getCountry(data.country),
                }}
                onChange={(e, v) => {
                    try{
                        if(v){
                            setData((pre) => ({ ...pre, country: v.code }));
                        }
                     
                    }catch(e){
                        console.log(sanitizeUserInput(JSON.stringify(e)));
                    }
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select a country"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div>
              <label>
                Language<span className="text-danger">*</span>
              </label>
              <br />
              <select
                className="form-select"
                id="chooseLanguages"
                value={data.language}
                onChange={(e) => {
                  setLanguage((pre) => [
                    ...pre,
                    {
                      code: e.target.value,
                      label: getLanguage(e.target.value),
                    },
                  ]);
                  setData((pre) => ({ ...pre, language: e.target.value }));
                }}
              >
                {Languages.map((option, index) => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="ms-1 mt-2">
                {selectedLang.map((d, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      className="me-2"
                      type="radio"
                      role="button"
                      id={d.label}
                      name={d.label}
                      value={d.code}
                      data-toggle="tooltip"
                      checked={data.language === d.code}
                      onChange={(e) => {
                        setData((pre) => ({
                          ...pre,
                          language: e.target.value,
                        }));
                      }}
                    />
                    <div>{d.label}</div>
                    {data.language !== d.code && (
                      <span
                        type="button"
                        role="button"
                        style={{ fontSize: "20px", color: "red" }}
                        onClick={() => {
                          setLanguage((pre) =>
                            pre.filter((data, i) => data.code !== d.code)
                          );
                        }}
                        className="material-symbols-outlined"
                      >
                        remove
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-3">
              {loading ? (
                <button
                  className="btn"
                  style={{
                    background: "#045EB7",
                    fontSize: "18px",
                    zindex: "0",
                    color: "white",
                    width: "118px",
                    height: "38px",
                  }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  className="btn"
                  style={{
                    background: "#045EB7",
                    fontSize: "18px",
                    zindex: "0",
                    color: "white",
                    width: "118px",
                    height: "38px",
                  }}
                  disabled={
                    data.application_name === "" ||
                    data.role === "" ||
                    data.aliasName === "" ||
                    data.appOwner === "" ||
                    data.country === "" ||
                    data.language === ""
                  }
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  + Add User
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
