import React,{useEffect,useState} from "react";
import { useOktaAuth } from "@okta/okta-react";
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetOnboard } from "../../store/slice";
import { resetMailSlice } from "../../store/mailslice";
import { setActive, setAllowedApplication } from "../../store/dashboardslice";

function Logout() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
 const { oktaAuth, authState } = useOktaAuth();
	const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/dashboard" });
  useEffect(() => {
    dispatch(resetOnboard())
    dispatch(resetMailSlice())
    dispatch(setAllowedApplication(""))
    dispatch(setActive("HCP Pending"))
    // if(localStorage.getItem("okta-token-storage")){
    //   navigate("/dashboard");
    // }
    if(authState?.isAuthenticated ){
      navigate("/dashboard");
    }
  
  }, [authState?.isAuthenticated])


  return (
    <div 
        className="w-100 vh-100" 
        style={{
            paddingTop:"3%",
            paddingBottom:"3%",
            paddingLeft:"6%",
            paddingRight:"6%",
            background:"rgb(4,94,183)"
        }}
        >
        <div className="p-2 w-100 h-100 bg-white rounded overflow-auto">
            <div><img style={{height:"40px" }} src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg" alt="logo"></img></div>
            <div className="row ps-4 d-flex align-items-center justify-content-center" style={{height:"90%"}}>
                <div className="col-8">

                    <h3 
                        className="mb-4" 
                        style={{
                                    color:"rgb(4,94,183)",
                                    fontWeight:"600"
                                }}>
                            Welcome to Passport Self-Service
                    </h3>

                    <div style={{fontSize:"x-large"}}>The Passport Self-Service Console empowers end-app owners with an automated tool, enabling seamless self-onboarding and effortless administration of integrations with the IDM solution.</div>
                    <button style={{
                                    background:"rgb(4,94,183)",
                                    color:"white"
                                }}
                                className="btn mt-3 px-5 fs-bolder" onClick={
                                    loggingIn
                                    } >Sign in </button>
                    
                </div>
                <div className="col-4">

                <div className="d-flex justify-content-center align-items-center h-100">
                        <img src="https://amgen-passport-cdn-public-resources-dev.s3.eu-central-1.amazonaws.com/passport/logo/dashboard_sign_in_icon.png" alt="Okta" width="75%" />
                </div>

                </div>
            </div>
            
        </div>

    </div>
  );
}

export default Logout;
