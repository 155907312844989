import React, { useEffect } from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import tick from '../Assets/check-mark.png';
import error from '../Assets/cancel.png'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height:'80%',
        background: 'white',
        color: 'white'
    },
};

function UpdateTranslationPopup({ modalIsOpen, closeModal,data }) {

    function handleClose() {
        closeModal()
    }

    let subtitle;

    


    function afterOpenModal() {

        subtitle.style.color = 'white';
    }



    return (
        <div className='position-relative w-50'>
            <Modal
                isOpen={modalIsOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="h-100">
                    <button onClick={() => {
                            handleClose()
                            }} style={{ textDecoration: "none", border: "none", borderRadius:"16px", marginRight:"1rem", marginTop:"1rem" }} className="material-symbols-outlined position-absolute top-0 end-0">close
                    </button>
                    <div className="font-weight-bold text-primary">Update Translation Request</div><br/>
                    <div className="text-dark h-100">
                        <div className="row h-100 overflow-auto">
                                {Object.keys(data).map((key,index) => 
                                <div key={index} className="d-flex shadow-sm mb-2">
                                    <div className="w-50 me-1"> {key} </div> :
                                    <div className="w-50 ms-1"> {data[key]} </div>
                                </div> 
                                )}
                            
                            
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UpdateTranslationPopup;