import React,{useState,useEffect} from 'react';
import axios from "axios";
import * as constant from '../Constants/constant';
import "./style.css";
import Sidebar from '../Sidebar/Sidebar';
import AmgenLogo from '../Assets/Group1.svg';
import WhitelistComponent from './whitelistcomponent';
import Request from './request';
import History from './history';
import Popover from '@mui/material/Popover';
import UserPopover from '../userpopover';
import Loader from '../Loader/loader';
import { useSelector, useDispatch } from "react-redux";
import {
  setShow
} from "../../store/appslice"
import { sanitizeUserInput } from '../../Helper/sanitize';

export default function WhiteList() {
    const [applicationList, setApplicationList] = useState([]);
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();
    // const role = localStorage.getItem("role");
    const{role} = useSelector((state) => state.dashboard);
    const user = localStorage.getItem("username");
    const [approveLoading, setApprove] = useState(false);
    const { csrf, show } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
  const contenHeight=`calc(100vh - 64px)`;
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
    useEffect(() => {
      if(role == "Super Admin"){
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`,
  {
    headers: { ...constant.headers,"csrf-token": csrf },
  }
  )
  .then((response) => {
    setApplicationList(response.data.data);
  })
  .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))});
  }else{
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
    {
    headers: { ...constant.headers },
    }            
    )
    
    .then((res) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
            { email: user },
            {
              headers: { ...constant.headers,"csrf-token": res.data.data.csrfToken },
            }
          )
          .then((response) => {
            setApplicationList(response.data.data.data);
            
          })
          .catch((error) => {console.log(sanitizeUserInput(JSON.stringify(error)))});
        }).catch((error) => {console.log(sanitizeUserInput(JSON.stringify(error)))});
  }
  }, []);
  
    return (
      <div>
      <main className={show ? 'space-toggle' : null} style={{margin:0}}>
       
      <aside style={{ background: "#045EB7" }} className={`sidebar ${show ? 'show' : null}`}>
          <nav className='nav'>
              <div>
                  <div className='header-toggle' onClick={() => dispatch(setShow())}>
                      <span className='fas fa-bars sidebarfirst'></span>
                  </div>
                  <Sidebar show={show}></Sidebar>
              </div>
          </nav>
      </aside>
          <div className="bodyfirstpreload">
          <header style={{ background: "white",zIndex:"-1" ,height:"7vh",padding:0}} className={`header ${show ? 'space-toggle' : null}`}>
        <span><img style={{ paddingLeft: "1rem",height:"40px" }} src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg" alt=""></img></span>
        <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}
        
            <span 
                                aria-describedby={id} 
                                role="button" 
                                class="material-symbols-outlined headercolor fs-1 me-3" 
                                onClick={handleClick}
                                >account_circle
                            </span>
                            
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                            >
                                <UserPopover/>
                            </Popover>
        </div>
    </header>
    
          <div className="px-4 py-3" style={{ backgroundColor: "#edf2f6", height: contenHeight}}>
          <div className="ms-4 fw-bold pb-2" style={{color:"#0463c3"}}>Whitelist</div>
        {/* <div className="ms-5 d-flex">
          <div
            role="button"
            className={
              tab === 0
                ? "active-tab bg-white rounded-top fw-bold px-4 py-2 "
                : "tab rounded-1 fw-bold px-4 py-2"
            }
            onClick={() => setTab(0)}
          >
            White List
          </div>
          <div
            role="button"
            className={
              tab === 1
                ? "active-tab ms-2 bg-white rounded-top fw-bold px-4 py-2 "
                : "tab ms-2 rounded-1 fw-bold px-4 py-2"
            }
            onClick={() => setTab(1)}
          >
            Requests
          </div>
          <div
            role="button"
            className={
              tab === 2
                ? "active-tab ms-2 bg-white rounded-top fw-bold px-4 py-2 "
                : "tab ms-2 rounded-1 fw-bold px-4 py-2"
            }
            onClick={() => setTab(2)}
          >
            History
          </div>
        </div> */}
        <div className="bodyfirstpreload"  style={{padding:"30px 0px 0px 40px " }}>
          <div className="myreqwidth mr-5 h-100">
          {tab === 0 && <WhitelistComponent list={applicationList}/>}
          {/* {tab === 1 && <Request setApprove={setApprove} />}
          {tab === 2 && <History />} */}
          </div>
        </div>
      </div>
          </div>
  </main >
     
  {approveLoading && <Loader />}
      </div>
    );
  }
