import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as constant from '../Constants/constant';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { makeDateFormat } from '../../Helper/helper';
import { customStyles } from './customStyletable';

axios.defaults.withCredentials = true;

//   {
//       name: 'id',
//       selector: row => row.id,
//       width: '100px'
//   },
//   {
//       name: 'coverimage',
//       cell: row => <img src={row.coverimage} width={50} alt={row.name}></img>,
//       selector: row => row.coverimage,
//       width: '100px'
//   },
//   {
//       name: 'name',
//       selector: row => row.name,
//       width: '200px'
//   },
//   {
//       name: 'detail',
//       selector: row => row.detail,
//       width: '500px'
//   },
//   {
//       name: 'latitude',
//       selector: row => row.latitude,
//       width: '100px'
//   },
//   {
//       name: 'longitude',
//       selector: row => row.longitude,
//       width: '100px'
//   },
// ];

const columns = [
  
  {
    name: "Email",
    cell: (row) => row.email,
    grow: 2,
    reorder:true,
},
{
  name: "Application Name",
  cell: (row) => row.aliasName,
  compact:true,
  reorder:true,
},
{
  name: "Other Apps",
  cell: (row) => row.secondary_application,
  compact:true,
  reorder:true,
},
  {
    name: "Prospect id",
    cell: (row) => row.prospect_id,
    compact:true,
    reorder:true,
  },
  {
    name: "Party id",
    cell: (row) => row.MDM_ID,
    compact:true,
    reorder:true,
  },
  {
    name: "Status",
    cell: (row) => row.MDM_Status,
    compact:true,
    reorder:true,
  },
  {
    name: "Profession",
    cell: (row) => row.Title,
    compact:true,
    reorder:true,
  },
  {
      name: "First name",
      selector: (row) => row.first_name,
      cell: (row) => row.first_name,
      compact:true,
      reorder:true,
  },
  {
      name: "Last name",
      cell: (row) => row.last_name,
      compact:true,
      reorder:true,
  },
  
  {
    name: "Phone",
    cell: (row) => row.mobile_number,
    compact:true,
    reorder:true,
  },
  {
    name: "Postal code",
    cell: (row) => row.postal_code,
    compact:true,
    reorder:true,
  },
  {
    name: "City",
    cell: (row) => row.city,
    compact:true,
    reorder:true,
  },
  {
    name: "Institution",
    cell: (row) => row.institution,
    compact:true,
    reorder:true,
  },
  {
      name: "Specialty",
      cell: (row) => row.Specialty,
      compact:true,
      reorder:true,
  },
  {
      name: "HCP Validation Submitted",
      cell: (row) =>row?.hcp_validation_submitted_date,
      selector: (row) =>row?.hcp_validation_submitted_date,
      sortable: true,
      compact:true,
      reorder:true,
  },
  {
    name: "HCP Validation Completion",
    cell: (row) => row?.hcp_validation_verified_date,
    selector: (row) => row?.hcp_validation_verified_date,
    sortable: true,
    compact:true,
    reorder:true,
},
  {
    name: "Last login",
    cell: (row) => row.last_login_timestamp,
    sortable: true,
    compact:true,
    reorder:true,
},
{
  name: "Onekey id",
  cell: (row) => row.one_key_id,
  compact:true
},
{
  name: "Multipass Result",
  cell: (row) => row.multipassParams,
  compact:true,
  reorder:true,
},
{
  name: "License Number",
  cell: (row) => row.validationId ? row.validationId : "",
  compact:true
},
{
  name: "Quick Register",
  cell: (row) => row.quickregister ? row.quickregister : "",
  compact:true
}
  
];

function DynamicDataTable(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(500);
  const paginationComponentOptions = {
    noRowsPerPage: true
  };

  // const ExpandedComponent = ({ data }) => <Expandable data={data} />;

  const [pending, setPending] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if(props.goflag){
    fetchData(1, perPage);
    }
  }, [props.goValidated, perPage, props.goflag])

  const fetchData = async (page, per_page) => {
    let payload = {};

    if(props.country){
        payload["country"] = props.country
    }
    if(props.startdate){
        payload["startDate"] = props.startdate.toISOString()
    }
    if(props.enddate){
        payload["endDate"] = props.enddate.toISOString()
    }
    if(props.dashapp){
        payload["appName"] = props.dashapp
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
          "hcpValidated_new": true,
          ...payload,
          "limit": per_page,
          "offset": (page-1)*per_page
        },
        {
          headers: { ...constant.headers,"csrf-token": props.csrf },
        }
        )
      // .then(res => res.json())
      .then(
        (result) => {
          props.setNextload(false);
          // console.log(result,"result")
          setIsLoaded(true);
          // setItems(result.data);
          // setTotalRows(100);
          if (result.data["result"].length > 0) {
            result.data["result"].map((item) => {
              if (item.secondary_application) {
                const al = item.secondary_application.split(",").filter(Boolean);
                if (al.length > 0) {
                  const updatedApp = al.map(app => constant.appList[app] || app);
                  item.secondary_application = updatedApp.join(", ");
                }
              }
              return item;
            });
          }
          setItems(result.data["result"]);
          setTotalRows(result.data["totalRows"][0]["totalRows"]);
          props.setGoValidated(false)

        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          props.setGoValidated(false)
        }
      )
  }
  
  const handlePageChange = page => {
    props.setNextload(true);
    fetchData(page, perPage);
  }


  const tableData = {
    columns : columns,
    data : items
 };
 
    return (
      <div className="App">
        {/* {
          props.goValidated ? 
            <Stack spacing={1.5}>
                <Skeleton variant="rounded"  animation="wave" height={40} />
                <Stack spacing={0}>
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                </Stack>
            </Stack> : */}
            <div className="table-container">
            <DataTableExtensions
            {...tableData}
            exportHeaders = {true}
            fileName = "Hcp validated data"
            filterDigit = {0}
            filterPlaceholder = "Search Table"
            print={false}
            >
            <DataTable
              columns={columns}
              data={items}
              // expandableRows={true}
              // expandableRowsComponent={ExpandedComponent}
              pagination
              paginationServer
              customStyles={customStyles}
              responsive ={true}
              paginationTotalRows={totalRows}
              progressPending={pending}
              fixedHeaderScrollHeight ="47vh"
              fixedHeader = {true}
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
              paginationPerPage={perPage}
            />
            </DataTableExtensions>
            </div>
        {/* } */}
      </div>
    );
  
}

export default DynamicDataTable;