import React from "react";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import MyComponent from "../Table/Table";
import axios from 'axios';
import * as constant from '../Constants/constant';
import { useState } from "react";
import { Applicationdata } from "./Application_data";
import { 
    updateLoader
} from "../../store/slice";
import { useSelector, useDispatch } from "react-redux";
import {applicationsOnboarded,getCsrfToken} from '../../Helper/helper';
import { sanitizeUserInput } from "../../Helper/sanitize";

function Application (props) {
    const dispatch = useDispatch();
    // dispatch(updateLoader(false));
    const [data, setData] = useState(props.activeApplicationData || "");
    const [appname, setAppname] = useState([]);
    const [value, setValue] = useState("");
    const { csrf } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
    


    React.useEffect(() => {
        if(props.goflag){
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard-data`,
        {
            "applicationsOnboarded_new":true,
            "country":props.country,
            "startDate": props.startdate.toISOString(),
            "endDate": props.enddate.toISOString(),
            "appName":props.dashapp
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            setData(response.data)
            setAppname(response.data.map((data)=>data.aliasName ? data.aliasName: data.application_name))
            setValue(response.data.map((data)=>data.OccurenceValue))
            // dispatch(updateLoader(true));
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)),"errorItem")}) 
    }
        // applicationsOnboarded(
        //     {
        //         "country":props.countryval,
        //         "startDate": props.startdate.toISOString(),
        //         "endDate": props.enddate.toISOString(),
        //         "appName":props.dashapp
        //     },
        //     props.setApplicationcount,
        //     props.setActiveApplicationData
        //     )

        // setData(props.activeApplicationData)
        // setAppname(props.activeApplicationData.map((data)=>data.aliasName ? data.aliasName: data.application_name))
        // setValue(props.activeApplicationData.map((data)=>data.OccurenceValue))
    }, [props.goflag]);

    React.useEffect(() => {
        setData(props.activeApplicationData)
        setAppname(props.activeApplicationData.map((data)=>data.aliasName ? data.aliasName: data.application_name))
        setValue(props.activeApplicationData.map((data)=>data.OccurenceValue))
      }, [props.activeApplicationData]);
        

    const column = [
        {
            name: "Application Name",
            cell: (row) =>row.aliasName ?row.aliasName: row.application_name,
            sortable:true,
            selector: (row) =>row.aliasName ?row.aliasName: row.application_name,
            
        },
        {
            name: "Country",
            cell: (row) => row.country,
            selector: (row) => row.country,
            sortable:true,
        },
        {
            name: "Total Users",
            cell: (row) => row.OccurenceValue,
        }
    ]
    return <div className="container">
                <div className="row">
                        <div className="col" style={{margin:"5% 0 0 3%"}}>
                        <Bar style={{width:"90%",height:"45vh"}}
                            data = {{
                                //labels: ['Amgen Brand', 'Amgen Compass', 'Enbrel Resource', 'Amgen ISS', 'HUb360'],
                                labels:appname,
                                datasets: [{
                                    label: 'Number of users',
                                    //data: [10,40,25,15,35],
                                    data:value,
                                    borderWidth: 1,
                                    borderRadius:200,
                                    backgroundColor:'#0adbc2',
                                    borderSkipped: false,
                                    
                                }]
                                }}
                                height={400}
                                width={800}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: true,
                                            onClick:null,
                                        }
                                    },
                                    maintainAspectratio:false,
                                    responsive: true,
                                    maxBarThickness: 20,
                                    scales: {
                                        x: {
                                            grid: {
                                            display: false
                                            },
                                            reverse:true
                                        },
                                        y: {
                                            grid: {
                                            display: false
                                            }
                                        }
                                    }
                            }}
            />
                        </div>
                        <div className="col">
                            <div style={{}}><MyComponent header="true" fileName="Application onboarded" data={data} tableHeight="47vh" columns={column} paginationPerPage={10}/></div>
                        </div>
                </div>
</div>
    
    
}

export default Application;