import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success_application_list: [],
  failure_application_list: [],
  email_blast:[],
  successMailContent: {
    application_name: "",
    language:"",
    country:"",
    content:{}
  },
  failureMailContent: {
    application_name: "",
    language:"",
    country:"",
    content:{}
  },
  inactiveMailContent: {
    application_name: "",
    language:"",
    content:{},
    email:[]
  },
  deactivatedMailContent: {
    application_name: "",
    language:"",
    content:{}
  },
};

export const emailTemplateSlice = createSlice({
  name: "emailtemplate",
  initialState,
  reducers: {
    setSuccessApplicationList: (state, actions) => {
      state.success_application_list = actions.payload.filter(
        (d, i) => d.options.triggerHcpSuccessEmail === true && d.options.globalUI === true
      );
    },
    
    setSuccessAppnameAndLag: (state, actions) => {
      if(actions.payload.name!==undefined){
        state.successMailContent.application_name=actions.payload.name;
      }
      if(actions.payload.language!==undefined){
        state.successMailContent.language=actions.payload.language;
        }
      if(actions.payload.country!==undefined){
        state.successMailContent.country=actions.payload.country;
        }
    },
    setMailSender: (state, actions) => {
      state.email_blast.push(actions.payload);
    },
    setRemoveMailSender: (state, actions) => {
      state.email_blast = [];
    },
    setInactiveMailSender: (state, actions) => {
      state.inactiveMailContent.email.push(actions.payload);
    },
    setDeactivatedMailSender: (state, actions) => {
      state.deactivatedMailContent.email.push(actions.payload);
    },
    removeSuccessMailSender: (state, actions) => {
      if(actions.payload=="all"){
        state.successMailContent.email = []
      }else{
      state.successMailContent.email = state.successMailContent.email.filter(
        (d, i) => i !== actions.payload
      );
    }
    },
    changeContentSuccessMail: (state, actions) => {
      state.successMailContent[actions.payload.key] = actions.payload.value;
      if (state.successMailContent.buttonText === "") {
        state.successMailContent.buttonLink = "";
      }
    },
    setSuccessMailBody: (state, actions) => {
      state.successMailContent.hcp_validation_success_email_body =
        actions.payload;
    },
    // setTeamNameSlice: (state, actions) => {
    //   state.successMailContent.content.hcp_validation_success_email_team_name =
    //     actions.payload;
    //     state.failureMailContent.content.hcp_validation_email_team_name =
    //     actions.payload;
    //     state.inactiveMailContent.content.Inactive_users_email_team_name = actions.payload;
    //     state.deactivatedMailContent.content.deactivated_users_email_team_name = actions.payload;
    // },
    setUserData: (state, actions) => {
      state.successMailContent.content.hcp_validation_success_email_privacy_link =
      actions.payload.privacy;
      state.failureMailContent.content.hcp_validation_email_privacy_link =
      actions.payload.privacy;
      state.inactiveMailContent.content.Inactive_users_email_privacy_link = actions.payload.privacy;
      state.deactivatedMailContent.content.deactivated_users_email_privacy_link = actions.payload.privacy;

      state.successMailContent.content.hcp_validation_success_email_tnc_link =
      actions.payload.termsofuse;
      state.failureMailContent.content.hcp_validation_email_tnc_link =
      actions.payload.termsofuse;
      state.inactiveMailContent.content.Inactive_users_email_tnc_link = actions.payload.termsofuse;
      state.deactivatedMailContent.content.deactivated_users_email_tnc_link = actions.payload.termsofuse;

      state.successMailContent.content.hcp_validation_success_email_amgen_uri =
      actions.payload.amgenuri;
      state.failureMailContent.content.hcp_validation_email_amgen_uri =
      actions.payload.amgenuri;
      state.inactiveMailContent.content.Inactive_users_email_amgen_uri = actions.payload.amgenuri;
      state.deactivatedMailContent.content.deactivated_users_email_amgen_uri = actions.payload.amgenuri;

      state.successMailContent.content.hcp_validation_success_email_team_dl =
      actions.payload.teamdl;
      state.failureMailContent.content.hcp_validation_email_team_dl =
      actions.payload.teamdl;
      state.inactiveMailContent.content.Inactive_users_email_team_dl = actions.payload.teamdl;
      state.deactivatedMailContent.content.deactivated_users_email_team_dl = actions.payload.teamdl;
      state.successMailContent.content.hcp_validation_success_email_need_help_link =
      actions.payload.teamdl;
      state.failureMailContent.content.hcp_validation_email_need_help_link =
      actions.payload.teamdl;
      state.inactiveMailContent.content.Inactive_users_email_need_help_link = actions.payload.teamdl;
      state.deactivatedMailContent.content.deactivated_users_email_need_help_link = actions.payload.teamdl;
      state.successMailContent.content.hcp_validation_success_email_body =
      actions.payload.success;
      state.failureMailContent.content.hcp_validation_email_body =
      actions.payload.failure;
      state.inactiveMailContent.content.Inactive_users_email_body = actions.payload.inactive;
      state.deactivatedMailContent.content.deactivated_users_email_body = actions.payload.deactive;
    },
    setTeamDlSlice: (state, actions) => {
        state.successMailContent.content.hcp_validation_success_email_team_dl =
        actions.payload;
        state.failureMailContent.content.hcp_validation_email_team_dl =
        actions.payload;
        state.inactiveMailContent.content.Inactive_users_email_team_dl = actions.payload
        state.deactivatedMailContent.content.deactivated_users_email_team_dl = actions.payload
    },
    setFooterLeft: (state, actions) => {
        state.successMailContent.content.hcp_validation_success_email_footer_left =
        actions.payload;
        state.failureMailContent.content.hcp_validation_email_footer_left =
        actions.payload;
        state.inactiveMailContent.content.Inactive_users_email_footer_left = actions.payload;
        state.deactivatedMailContent.content.deactivated_users_email_footer_left = actions.payload;
    },
    setFooterRight: (state, actions) => {
        state.successMailContent.content.hcp_validation_success_email_footer_right =
        actions.payload;
        state.failureMailContent.content.hcp_validation_email_footer_right =
        actions.payload;
        state.inactiveMailContent.content.Inactive_users_email_footer_right = actions.payload
        state.deactivatedMailContent.content.deactivated_users_email_footer_right = actions.payload
    },
    setLink: (state, actions) => {
        state.successMailContent.content.hcp_validation_success_email_privacy_link =
        actions.payload.privacy;
        state.successMailContent.content.hcp_validation_success_email_tnc_link =
        actions.payload.tnc;
        state.successMailContent.content.hcp_validation_success_email_amgen_link = actions.payload.amgen
    },
    setNeedHelpSlice: (state, actions) => {
      state.successMailContent.content.hcp_validation_success_email_need_help_link =
      actions.payload;
      state.failureMailContent.content.hcp_validation_email_need_help_link =
      actions.payload;
      state.inactiveMailContent.content.Inactive_users_email_need_help_link = actions.payload;
      state.deactivatedMailContent.content.deactivated_users_email_need_help_link = actions.payload
  },
  setBodySlice: (state, actions) => {
    state.successMailContent.content.hcp_validation_success_email_body =
    actions.payload.success;
    state.failureMailContent.content.hcp_validation_email_body =
    actions.payload.failure;
    state.inactiveMailContent.content.Inactive_users_email_body = actions.payload.inactive;
    state.deactivatedMailContent.content.deactivated_users_email_body = actions.payload.deactive;
},
    setTeamNameSlice: (state, actions) => {
      state.successMailContent.content.hcp_validation_success_email_team_name =
      actions.payload;
      state.failureMailContent.content.hcp_validation_email_team_name =
      actions.payload;
      state.inactiveMailContent.content.Inactive_users_email_team_name = actions.payload;
      state.deactivatedMailContent.content.deactivated_users_email_team_name = actions.payload;
    },
    setSuccessMailFooter: (state, actions) => {
      state.successMailContent.hcp_validation_success_email_footer_left =
        actions.payload;
    },
    setSuccessMailcontent: (state, actions) => {
      state.successMailContent.content =
        actions.payload;
    },
    setSuccessMailContentOverride: (state, actions) => {
      if(actions.payload.buttonLabel!==""){
        const text=actions.payload.buttonLabel.split(`>`);
        const link=actions.payload.buttonLabel.split(`'`);
              state.successMailContent.buttonText = text[1];
              state.successMailContent.buttonLink = link[1];}
      state.successMailContent.hcp_validation_success_email_sender =
        actions.payload.sender;
      state.successMailContent.hcp_validation_success_email_subject =
        actions.payload.subject;
      state.successMailContent.hcp_validation_success_email_head =
        actions.payload.head;
      state.successMailContent.hcp_validation_success_email_logo =
        actions.payload.logo;
      state.successMailContent.hcp_validation_success_email_title =
        actions.payload.title;
      state.successMailContent.hcp_validation_success_email_salutation =
        actions.payload.salutations;
      state.successMailContent.hcp_validation_success_email_body =
        actions.payload.body;
      state.successMailContent.hcp_validation_success_email_footer_left =
        actions.payload.footer;
    },
    resetSuccessData:(state)=>{
    state.successMailContent.email= [];
    state.successMailContent.buttonText= "";
    state.successMailContent.buttonLink= "";
    state.successMailContent.hcp_validation_success_email_sender= "";
    state.successMailContent.hcp_validation_success_email_subject= "";
    state.successMailContent.hcp_validation_success_email_head= "";
    state.successMailContent.hcp_validation_success_email_logo= "";
    state.successMailContent.hcp_validation_success_email_title= "";
    state.successMailContent.hcp_validation_success_email_salutation= "";
    state.successMailContent.hcp_validation_success_email_body= ``;
    state.successMailContent.hcp_validation_success_email_footer_left= ``;
    },



    setFailureApplicationList: (state, actions) => {
      state.failure_application_list = actions.payload.filter(
        (d, i) => d.options.triggerHcpFailureEmail === true
      );
    }, 
    setFailureAppnameAndLag: (state, actions) => {
      if(actions.payload.name!==undefined || ""){
        state.failureMailContent.application_name=actions.payload.name;
      }
      if(actions.payload.country!==undefined || ""){
        state.failureMailContent.country=actions.payload.country;
      }
      if(actions.payload.language!==undefined || ""){
      state.failureMailContent.language=actions.payload.lag;
      }
    },
    setFailureMailcontent: (state, actions) => {
      state.failureMailContent.content =
        actions.payload;
    },
    setInactiveMailContent: (state, actions) => {
      state.inactiveMailContent.content =
        actions.payload;
    },
    setDeactivatedMailContent: (state, actions) => {
      state.deactivatedMailContent.content =
        actions.payload;
    },
    setFailureMailSender: (state, actions) => {
      state.failureMailContent.email.push(actions.payload);
    },
    removeFailureMailSender: (state, actions) => {
      if(actions.payload == "all"){
        state.failureMailContent.email = []
      }else{
      state.failureMailContent.email = state.failureMailContent.email.filter(
        (d, i) => i !== actions.payload
      );
    }
    },
    changeContentFailureMail: (state, actions) => {
      state.failureMailContent[actions.payload.key] = actions.payload.value;
    },
    setFailureMailBody: (state, actions) => {
      state.failureMailContent.hcp_validation_failure_email_body =
        actions.payload;
    },
    setFailureMailFooter: (state, actions) => {
      state.failureMailContent.hcp_validation_failure_email_footer_left =
        actions.payload;
    },
    setFailureMailContentOverride:(state,actions)=>{
      state.failureMailContent.hcp_validation_failure_email_sender =
      actions.payload.sender;
    state.failureMailContent.hcp_validation_failure_email_subject =
      actions.payload.subject;
    state.failureMailContent.hcp_validation_failure_email_head =
      actions.payload.head;
    state.failureMailContent.hcp_validation_failure_email_logo =
      actions.payload.logo;
    state.failureMailContent.hcp_validation_failure_email_title =
      actions.payload.title;
    state.failureMailContent.hcp_validation_failure_email_salutation =
      actions.payload.salutations;
    state.failureMailContent.hcp_validation_failure_email_body =
      actions.payload.body;
    state.failureMailContent.hcp_validation_failure_email_footer_left =
      actions.payload.footer;
    },
    resetFailureData:(state)=>{
      state.failureMailContent.email= [];
      state.failureMailContent.hcp_validation_failure_email_sender= "";
      state.failureMailContent.hcp_validation_failure_email_subject= "";
      state.failureMailContent.hcp_validation_failure_email_head= "";
      state.failureMailContent.hcp_validation_failure_email_logo= "";
      state.failureMailContent.hcp_validation_failure_email_title= "";
      state.failureMailContent.hcp_validation_failure_email_salutation= "";
      state.failureMailContent.hcp_validation_failure_email_body= ``;
      state.failureMailContent.hcp_validation_failure_email_footer_left= ``;
      },
      resetMailSlice:(state,actions)=>{
        state={
          success_application_list: [],
          failure_application_list: [],
          email_blast:[],
          successMailContent: {
            application_name: "",
            language:"",
            country:"",
            content:{}
          },
          failureMailContent: {
            application_name: "",
            language:"",
            country:"",
            content:{}
          },
          inactiveMailContent: {
            application_name: "",
            language:"",
            content:{},
            email:[]
          },
          deactivatedMailContent: {
            application_name: "",
            language:"",
            content:{}
          },
        }
      }
  },
});

export const {
  setSuccessApplicationList,
  setSuccessAppnameAndLag,
  setSuccessMailSender,
  setDeactivatedMailSender,
  setRemoveMailSender,
  setMailSender,
  setInactiveMailSender,
  removeSuccessMailSender,
  changeContentSuccessMail,
  setSuccessMailBody,
  setSuccessMailFooter,
  setSuccessMailContentOverride,
  setDeactivatedMailContent,
  setInactiveMailContent,
  resetSuccessData,
  setFailureApplicationList,
  setFailureAppnameAndLag,
  setFailureMailSender,
  removeFailureMailSender,
  changeContentFailureMail,
  setSuccessMailcontent,
  setFailureMailcontent,
  setFailureMailBody,setFailureMailFooter,
  setFailureMailContentOverride,
  resetFailureData,
  setTeamNameSlice,
  setBodySlice,
  setTeamDlSlice,
  setNeedHelpSlice,setLink,setFooterLeft,setFooterRight,
  setUserData,resetMailSlice
} = emailTemplateSlice.actions;
export default emailTemplateSlice.reducer;
