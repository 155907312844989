import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translations: {},
  finalTranslations: {},
  mandatoryTrans: {},
  mailTemplate: {},
  successUrl:""
};

export const onboardFinal = createSlice({
  name: "onboardFinal",
  initialState,
  reducers: {
    setTranslation: (state, actions) => {
      state.translations = {
        ...state.translations,
        [actions.payload.key]: actions.payload.value,
      };
    },
    setMailTemplateValues: (state, actions) => {
      state.mailTemplate[actions.payload.obj] = {
        ...state.mailTemplate[actions.payload.obj],
        [actions.payload.key]: actions.payload.value,
      };
    },
    setMailTemplateDefault: (state, actions) => {
      if(actions.payload.obj){
        state.mailTemplate[actions.payload.obj] = {
          ...state.mailTemplate[actions.payload.obj],amgen_url:"", privacy_url:"", team_dl:"", team_name:"",terms_of_use_url:""
        };
      }else{
        state.mailTemplate={};
      }
    },
    updateTranslation: (state, actions) => {
      state.finalTranslations[actions.payload.obj] = {
        ...state.finalTranslations[actions.payload.obj],
        [actions.payload.key]: actions.payload.value,
      };
    },
    updateMandatoryTrans: (state, actions) => {
      state.mandatoryTrans[actions.payload.obj] = {
        ...state.mandatoryTrans[actions.payload.obj],
        [actions.payload.key]: actions.payload.value,
      };
    },
    removeTrans: (state, actions) => {
      Object.keys(state.mandatoryTrans).map((key, index) => {
        if (key.includes(actions.payload)) {
          delete state.mandatoryTrans[key];
        }
      });
    },
    removeMail: (state, actions) => {
      Object.keys(state.mailTemplate).map((key, index) => {
        if (key.includes(actions.payload)) {
          delete state.mailTemplate[key];
        }
      });
    },
    appendTransvalue: (state, actions) => {
      if( Object.keys(state.finalTranslations).length>0){
        Object.keys(state.finalTranslations).map((key, index) => {
          state.finalTranslations[key] = {
            ...state.finalTranslations[key],
            ...actions.payload[key],
          };
        });
      }else{
        Object.keys(actions.payload).map((key, index) => {
          state.finalTranslations[key] = {
            ...state.finalTranslations[key],
            ...actions.payload[key],
          };
        });
      }
     
    },
    setSuccessUrl:(state,actions)=>{
      state[actions.payload.name]=actions.payload.value;
    },
    resetFinal:(state,actions)=>{
      state={...initialState}
    }
  },
});

export const {
  setTranslation,
  setMailTemplateValues,
  updateTranslation,
  updateMandatoryTrans,
  removeTrans,
  appendTransvalue,resetFinal,removeMail,setSuccessUrl,setMailTemplateDefault
} = onboardFinal.actions;
export default onboardFinal.reducer;
