
import Applicationhome from "./components/Application_admin/Application_home";
import Applicationowner from "./components/Application_owner/Application_home";
import Reqhome from "./components/Request/req_home";
import Requesthome from "./components/Myrequest/req_home";
import Accesshome from "./components/Application Access/Access_home";
import Onboard from "./components/Myrequest/onboardapp";
import Userhome from "./components/User/User_home";
import PreloadUser from "./components/Preload_user";
import Dashboard from "./components/Dashboard/Dashboard1";
import ClientCreationAdmin from "./components/Client Creation Admin";
import Rolenotfound from "./components/Rolenotfound";
import WhiteList from "./components/Whitelist";
import MailTemplate from "./components/Mail Template";
import UpdateTranslation from "./components/Update Translation";
import UserAdd from "./components/User/userAdd";


const allRoutes = [
  {
  path: `/dashboard`,
  component: Dashboard,
  exact: true,
},
  {
  path: `/applicationowner`,
  component: Applicationowner,
  exact: true,
},
  {
  path: `/applicationadmin`,
  component: Applicationhome,
  exact: true,
},
  {
  path: `/user`,
  component: UserAdd,
  exact: true,
},
  {
  path: `/request`,
  component: Reqhome,
  exact: true,
},
  {
  path: `/access`,
  component: Accesshome,
  exact: true,
},
  {
  path: `/myrequest`,
  component: Requesthome,
  exact: true,
},
  {
  path: `/preloaduser`,
  component: PreloadUser,
  exact: true,
},
  {
  path: `/mailtemplate`,
  component: MailTemplate,
  exact: true,
},
  {
  path: `/updatetranslation`,
  component: UpdateTranslation,
  exact: true,
},
  {
  path: `/clientcreation`,
  component: ClientCreationAdmin,
  exact: true,
},
  {
  path: `/whitelist`,
  component: WhiteList,
  exact: true,
},
//   {
//   path: `/rolenotfound`,
//   component: Rolenotfound,
//   exact: true,
// },
  {
  path: `/myrequest/onboard`,
  component: Onboard,
  exact: true,
},
  {
  path: `/request/onboard`,
  component: Onboard,
  exact: true,
},
]

export  {allRoutes};

