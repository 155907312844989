import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flag: { whitelist: false, clientCreation: false },
  onChange: { whitelist: true,clientCreation: true },
  whitelist: { appName: "", orginUrl: [], redirectUrl: [] },
  clientDetails: {application: "",client_name: "",client_uri: "",redirect_uris: [],},
};

export const tempSlice = createSlice({
  name: "temp",
  initialState,
  reducers: {
    changeFlag: (state, actions) => {
      if (!state.onChange[actions.payload]) {
        state.flag[actions.payload] = !state.flag[actions.payload];
      }
    },
    setOnChange: (state, actions) => {
      state.onChange[actions.payload.key] = actions.payload.value;
    },
    setWhitelist: (state, actions) => {
      Object.keys(actions.payload).map(
        (data) =>
          (state.whitelist = {
            ...state.whitelist,
            [data]: actions.payload[data],
          })
      );
    },
    changeClient:(state,actions)=>{
        Object.keys(actions.payload).map(
            (data) =>
              (state.clientDetails = {
                ...state.clientDetails,
                [data]: actions.payload[data],
              })
          );
          state.onChange.clientCreation=false;
    },
    resetWhitlist: (state, actions) => {
      state.whitelist = { appName: "", orginUrl: [], redirectUrl: [] };
    },
    resetClient: (state, actions) => {
      state.clientDetails = {application: "",client_name: "",client_uri: "",redirect_uris: []};
    },
  },
});

export const { setWhitelist, changeFlag, resetWhitlist, setOnChange,resetClient,changeClient } =
  tempSlice.actions;
export default tempSlice.reducer;
