import React,{useEffect,useMemo,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as constant from "../Constants/constant";
import axios from "axios";
import Countries from "../Data/Region";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Popup from "./settingpopup";
import Sidebar from "../Sidebar/Sidebar";
import Applicationdata from "../Data/Applicationdata";
import Collapsible from "react-collapsible";
import Popover from '@mui/material/Popover';
import UserPopover from "../userpopover";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Languages from "../Data/Languages";
import {
  changeHcpValidation,
  changeAllowOtherAppsToReg,
  addUrl,
  removeUrl,
  addRedirectURI,
  removeRedirectURI,
  changeLanguage,updateLanguage,
  changeRegion,
  updateRequestId,
  changeTriggerHcpEmail,
  changeSyncCPC,
  changeSyncDPN,
  updateAppData,changeAppname,
  changeSyncEmailConsent,
  updateLoader,
  setLoginUrl,setSocialLogin,
  deleteConsent,
  setRedirectUrl,
  removeLanguage,
  resetOnboard,
  onSubmit,
  onSubmitProfile,
  onSubmitMigrate,
  onSubmitRegister,
  updateFieldMapping,
  setAllowUpdates,updateOption,
  changeClientName
} from "../../store/slice";
import {
  setShow
} from "../../store/appslice";
import {removeMail, removeTrans, resetFinal, setMailTemplateValues,setMailTemplateDefault, setSuccessUrl} from "../../store/onboardfinal";
import ApiComponent from "./apicomponent";
import Consent from "./consent";
import OnboardModal from "../Popup/Onboardpopup";
import Collapse from "../Collapse/collapse";
import { sanitizeUserInput } from "../../Helper/sanitize";
import Validationpopup from "../Popup/validationpopup";
import { trimData, updateMapping } from "../../Helper/onboard";
import DraggableComponent from "../Draggable/draggable";
import AddTranslation from "../Update Translation/addtranslation";
import { getCountry, getLanguage } from "../../Helper/helper";
import MandatoryTrans from "../Popup/mandatoryTrans";



function Onboard() {
    const { data,clientName,completeButtonDisable} = useSelector(
    (state) => state.onboard
  );
  const { csrf, show } = useSelector((state) => state.app);
  const {info} = useSelector((state) => state.info);
  const { mailTemplate,successUrl } = useSelector(
    (state) => state.onboardFinal
  );
  const dispatch = useDispatch();
  const user = localStorage.getItem("username");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  // const [showIcon, setShowIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitModel, setSubmitModel] = useState(false);
  const [mandatoryTrans, setMandatoryTrans] = useState(false);
  axios.defaults.withCredentials = true;
  const handleSubmitModel = () => setSubmitModel((pre) => !pre);
  const handleTransModel = () => setMandatoryTrans((pre) => !pre);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getCountryName=(val)=>{
    const data=Languages.filter((l)=>l.code===val);
    return data[0].name;
  }

  

  const handleOptionChange = (event) => {
    const el = document.getElementById('chooseLanguages');
    const text = el.options[el.selectedIndex].innerHTML;  
    dispatch(changeLanguage(event.target.value));
    setSelectedOption(event.target.value);
    dispatch(
      updateLanguage({
        label: getCountryName(event.target.value),
        value: event.target.value,
      })
    );
  };

  const handleAddValue = () => {
    if (selectedOption && !selectedValues.includes(selectedOption)) {
      setSelectedValues([...selectedValues, selectedOption]);
      setSelectedOption(null);
    }
  }

  const handleRemoveValue = (value, index) => {
    dispatch(removeLanguage({index:index,value:value}));
    const val=selectedValues.filter(item => item !== value);
    setSelectedValues([...val]);
  }
  const Appdata = Applicationdata[0].fields;
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
  const [activeStep, setActivestep] = useState(0);
  const [tempFields, setTempFields] = useState({});


  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  const [expand5, setExpand5] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [expandAll1, setExpandAll1] = useState(false);
  const [expandAll2, setExpandAll2] = useState(false);

        useEffect(()=>{
              setExpand1(expandAll);
              setExpand2(expandAll);
              setExpand3(expandAll);
              setExpand4(expandAll);
        },[expandAll])
   // rerendering the onboard page for the final submiting
        useEffect(()=>{
          let temp="";
        },[submitModel])
  const navigate = useNavigate();
  const{role} = useSelector((state) => state.dashboard);
  const [input, setInput] = useState({
    originurl: "",
    redirecturl: "",
    country: "",
    outsideusa: "",
    language: "",
    hcpvalidation: "",
    allowotherapps: ""
  });
  const [error,setError]=useState({originurl:"",redirecturl:"",app_name:"",country:"",language:"",mailTemplate:"",successUrl:""});
 const [tiggerTrans,setTiggerTrans]=useState(false);

  const handleComplete = () => {
    setTiggerTrans(true);
    dispatch(onSubmitProfile({fields:data.options.fields,consent:data.options.consent.fields,flag:data.options.updateFlag}));
    dispatch(onSubmitMigrate({fields:data.options.fields,consent:data.options.consent.fields}));
    dispatch(onSubmitRegister({fields:data.options.fields,consent:data.options.consent.fields}));
    dispatch(onSubmit({social:data.options.social}));
    // handleSubmitModel();
    handleTransModel();
  };

  const handleClose = () =>{
    dispatch(resetOnboard());
    dispatch(resetFinal());
    role.toLowerCase() == "app owner"
 ? navigate(`/myrequest`,{replace:true})
 : navigate(`/request`,{replace:true});
  }


  const handleNextStep = () => {
    setActivestep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActivestep(activeStep - 1);
  };

  const handlegotoapplication = () => {
    setActivestep(activeStep === 0);
  };


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleChange(e) {
    setInput({ ...input, [e.target.name]: [trimData(e.target.value)] });
  }
  useEffect(() => {
    if(data.options.country && data.options.allowedLanguages.length){
      data.options.allowedLanguages.forEach((val) => {
        const country = data.options.country;
        const keys = `${val.value}-${country}`;
        dispatch(setMailTemplateDefault({obj: keys,}));
    });}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.options.allowedLanguages, data.options.country]);

  return (
    <main className={show ? "space-toggle" : null} style={{ margin: 0 }}>
      <aside
        style={{ background: "#045EB7" }}
        className={`sidebar ${show ? "show" : null}`}
      >
        <nav className="nav">
          <div>
            <div className="header-toggle" onClick={() => dispatch(setShow())}>
              <span
                style={{
                  paddingLeft: "25px",

                  paddingBottom: "30px",

                  color: "white",
                }}
                className="fas fa-bars"
              ></span>
            </div>

            <Sidebar show={show} />
          </div>
        </nav>
      </aside>

      <>
        <div className="maintop">
          <header
            style={{
              background: "white",
              zIndex: "-1",
              height: "7vh",
              padding: 0,
            }}
            className={`header ${show ? "space-toggle" : null}`}
          >
            <span>
              <img
                style={{ paddingLeft: "1rem", height: "40px" }}
                src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg"
                alt=""
              ></img>
            </span>
            <div>
              <span
                aria-describedby={id}
                role="button"
                className="material-symbols-outlined headercolor fs-1 me-3"
                onClick={handleClick}
              >
                account_circle
              </span>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <UserPopover />
              </Popover>
            </div>
          </header>
          <h6
            style={{ marginTop: "2rem", marginLeft: "2rem", color: "#045EB7" }}
          >
            <b className="user-select-none">Onboard Applications</b>
          </h6>

          <div className="onboard-container">
            <Box
              sx={{ width: "80%" }}
              style={{ paddingTop: "2rem", marginLeft: "4rem" }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel style={{ color: "#045EB7" }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {activeStep === 1 && (
              <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
                <div className="onboardwidth">
                  <div className="d-flex align-items-center">
                    {/* <button
                      to="/clientcreation"
                      className="fw-bold text-primary btn btn-primary"
                    >
                      Client
                    </button>
                    <div className="w-100 mx-3">
                      <marquee>
                        <sup className="text-danger">*</sup>
                        {info?.clientCreationMsg}
                      </marquee>
                    </div> */}
                    <div className="form-check form-switch my-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={expandAll}
                        onChange={(e) => setExpandAll(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Collapse
                      </label>
                    </div>
                  </div>

                  <Collapse
                    title="Application Details"
                    mandatory
                    isOpen={expand1}
                    setValue={setExpand1}
                  >
                    <div className="mt-3" style={{ marginLeft: "2rem" }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: "#045EB7",
                          }}
                        >
                          Application name
                        </b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.appName}
                        >
                          info
                        </span>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the application name"
                          name="name"
                          value={data.name}
                          style={{ width: "35%", height: "2rem" }}
                          onChange={(e) => {
                            dispatch(changeAppname(e.target.value));
                            if (e.target.value.length > 0) {
                              setError({ ...error, app_name: "" });
                            }
                          }}
                          onBlur={(e) => {
                            dispatch(changeClientName(""));
                            if (e.target.value.length > 0) {
                              axios
                                .get(
                                  `${process.env.REACT_APP_API_BASE_URL}/api/get-client`,
                                  {
                                    headers: {
                                      ...constant.headers,
                                      app_name: trimData(e.target.value),
                                    },
                                  }
                                )
                                .then((res) => {
                                  if (
                                    res.data &&
                                    res.data.data &&
                                    res.data.data.client_name !== ""
                                  ) {
                                    dispatch(
                                      changeClientName(
                                        trimData(res.data.data.client_name)
                                      )
                                    );
                                    setError({ ...error, app_name: "" });
                                  } else {
                                    setError({
                                      ...error,
                                      app_name:
                                        "Enter the valid application name",
                                    });
                                  }
                                })
                                .catch((error) => {
                                  console.log(
                                    sanitizeUserInput(JSON.stringify(error))
                                  );
                                  setError({
                                    ...error,
                                    app_name:
                                      "Enter the valid application name",
                                  });
                                });
                            }
                          }}
                        ></input>
                        {error.app_name !== "" && (
                          <div className="text-danger mx-2">
                            {error.app_name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-3" style={{ marginLeft: "2rem" }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: "#045EB7",
                          }}
                        >
                          Client Name
                        </b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.clientName}
                        >
                          info
                        </span>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client Name"
                          name="clientName"
                          value={clientName || ""}
                          disabled
                          style={{ width: "35%", height: "2rem" }}
                          // onChange={(e) =>
                          //   dispatch(
                          //     updateAppData({
                          //       key: e.target.name,
                          //       value: e.target.value,
                          //     })
                          //   )
                          // }
                        ></input>
                      </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "2rem" }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: "#045EB7",
                          }}
                        >
                          Application alias name
                        </b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.aliasName}
                        >
                          info
                        </span>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the alias name"
                          name="aliasName"
                          value={data?.options?.aliasName || ""}
                          style={{ width: "35%", height: "2rem" }}
                          onChange={(e) =>
                            dispatch(
                              updateAppData({
                                key: e.target.name,
                                value: e.target.value,
                              })
                            )
                          }
                        ></input>
                      </div>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "2rem" }}>
                      <div className="d-flex align-items-center mb-2">
                        <b
                          className="me-1"
                          style={{
                            color: "#045EB7",
                          }}
                        >
                          Owner name
                        </b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.appOwner}
                        >
                          info
                        </span>
                      </div>

                      <div className="my-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the owner name"
                          name="appOwner"
                          value={data?.options?.appOwner || ""}
                          style={{ width: "35%", height: "2rem" }}
                          onChange={(e) =>
                            dispatch(
                              updateAppData({
                                key: e.target.name,
                                value: e.target.value,
                              })
                            )
                          }
                        ></input>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    title="Country"
                    mandatory
                    isOpen={expand2}
                    setValue={setExpand2}
                  >
                    <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "25%" }}
                        options={Countries}
                        value={{
                          value: data.options.country,
                          label: getCountry(data.options.country),
                        }}
                        onChange={(e, v) => {
                          if(v){
                            dispatch(
                              changeRegion({
                                value: v.code,
                                label: v.label,
                              })
                            );
                            dispatch(setMailTemplateDefault({}));
                            setInput({ ...input, country: v.label });
                            setError({ ...error, country: "" });
                          }
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select a country"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {error.country !== "" && (
                        <div className="text-danger mx-2">{error.country}</div>
                      )}
                    </div>
                  </Collapse>
                  <Collapse title="Language" mandatory isOpen={expand3}>
                    <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                      <div className="d-flex align-items-center">
                        <select
                          value={data.options.language}
                          id="chooseLanguages"
                          onChange={handleOptionChange}
                          style={{
                            height: "2rem",
                            width: "35%",
                            border: "1px solid #ced4da",
                            borderRadius: "0.375rem",
                          }}
                        >
                          {Languages.map((option, index) => (
                            <option key={option.code} value={option.code}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        {data?.options?.allowedLanguages.map((val, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center mt-2"
                          >
                            <input
                              className="me-2"
                              type="radio"
                              role="button"
                              id={val.label}
                              name={val.label}
                              value={val.value}
                              data-toggle="tooltip"
                              title={
                                data.options.language === val.value
                                  ? "default"
                                  : ""
                              }
                              checked={data.options.language === val.value}
                              onChange={(e) => {
                                dispatch(changeLanguage(e.target.value));
                              }}
                            />
                            <label
                              style={{ margin: "0px" }}
                              htmlFor={val.label}
                            >
                              {val.label}
                            </label>
                            {data.options.language !== val.value && (
                              <span
                                type="button"
                                role="button"
                                style={{ fontSize: "20px", color: "red" }}
                                onClick={() => {
                                  handleRemoveValue(val.value, index);
                                  dispatch(removeTrans(val.value));
                                  dispatch(removeMail(val.value));
                                }}
                                className="material-symbols-outlined"
                              >
                                remove
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    title="Whitelist"
                    mandatory
                    isOpen={expand4}
                    setValue={setExpand4}
                  >
                    <div
                      style={{
                        marginTop: "2rem",

                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    >
                      <div className="mb-2">
                        <div className="d-flex align-items-center mb-2">
                          <b
                            className="me-1"
                            style={{
                              color: "#045EB7",
                            }}
                          >
                            Success URL
                          </b>
                          <span
                            className="material-symbols-outlined fs-6 text-info"
                            role="button"
                            data-toggle="tooltip"
                            title={info?.successUrl}
                          >
                            info
                          </span>
                        </div>

                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="eg: https://sample.com/"
                            name="successUrl"
                            value={successUrl ? successUrl : ""}
                            onChange={(e) =>
                              dispatch(
                                setSuccessUrl({
                                  value: trimData(e.target.value),
                                  name: e.target.name,
                                })
                              )
                            }
                            onBlur={(e) => {
                              try {
                                if (e.target.value.length > 0) {
                                  const url = new URL(e.target.value);
                                  if (url.protocol === "https:") {
                                    dispatch(
                                      setSuccessUrl({
                                        value: trimData(e.target.value),
                                        name: e.target.name,
                                      })
                                    );
                                    setError({ ...error, successUrl: "" });
                                  } else {
                                    setError({
                                      ...error,
                                      successUrl:
                                        "enter the secure protocol(https)",
                                    });
                                  }
                                }
                              } catch (e) {
                                setError({
                                  ...error,
                                  successUrl:
                                    "invalid URL (eg: https://sample.com/)",
                                });
                              }
                            }}
                            style={{ width: "35%", height: "2rem" }}
                          ></input>
                        </div>
                        {error.successUrl !== "" && (
                          <div className="text-danger mx-2">
                            {error.successUrl}
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "33rem" }}>
                          <div className="d-flex align-items-center mb-2">
                            <b
                              className="me-1"
                              style={{
                                color: "#045EB7",
                              }}
                            >
                              Origin URL
                            </b>
                            <span
                              className="material-symbols-outlined fs-6 text-info"
                              role="button"
                              data-toggle="tooltip"
                              title={info?.originUrl}
                            >
                              info
                            </span>
                          </div>

                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control w-100"
                              placeholder="eg: https://sample.com/"
                              name="originurl"
                              value={input.originurl}
                              onChange={handleChange}
                              style={{ width: "35%", height: "2rem" }}
                            ></input>

                            <div
                              className="ms-2 my-auto lh-1 fw-bold"
                              onClick={(e) => {
                                try {
                                  const url = new URL(input.originurl);
                                  if (url.protocol === "https:") {
                                    dispatch(addUrl(input.originurl));
                                    setInput({ ...input, originurl: "" });
                                    setError({ ...error, originurl: "" });
                                  } else {
                                    setError({
                                      ...error,
                                      originurl:
                                        "enter the secure protocol(https)",
                                    });
                                  }
                                } catch (e) {
                                  setError({
                                    ...error,
                                    originurl:
                                      "invalid URL (eg: https://sample.com/)",
                                  });
                                }
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                role="button"
                              >
                                add_circle
                              </span>
                            </div>
                          </div>
                          {error.originurl !== "" && (
                            <div className="text-danger mx-2">
                              {error.originurl}
                            </div>
                          )}
                          <div className="mt-1">
                            {data.url.map((data, index) => (
                              <div
                                key={index}
                                className="d-flex w-100 my-1 pe-1"
                              >
                                <div className="d-flex w-100 px-2 py-1 border rounded-2 me-4">
                                  <div className="ps-1 txt-overflow">
                                    {data}
                                  </div>

                                  <div
                                    className="ms-auto my-auto lh-1 fw-bold"
                                    onClick={() => dispatch(removeUrl(index))}
                                  >
                                    <span
                                      className="material-symbols-outlined"
                                      role="button"
                                    >
                                      remove
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="ms-3" style={{ width: "33rem" }}>
                          <div className="d-flex align-items-center mb-2">
                            <b
                              className="me-1"
                              style={{
                                color: "#045EB7",
                                // borderBottom: "1px solid #045EB7",
                              }}
                            >
                              Redirect URL
                            </b>
                            <span
                              className="material-symbols-outlined fs-6 text-info"
                              role="button"
                              data-toggle="tooltip"
                              title={info?.redirectUrl}
                            >
                              info
                            </span>
                          </div>

                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control w-100"
                              placeholder="eg: https://sample.com/"
                              name="redirecturl"
                              value={input.redirecturl}
                              onChange={handleChange}
                              style={{ width: "35%", height: "2rem" }}
                            ></input>

                            <div
                              className="ms-2 my-auto lh-1 fw-bold"
                              onClick={(e) => {
                                try {
                                  const url = new URL(input.redirecturl);
                                  if (url.protocol === "https:") {
                                    dispatch(addRedirectURI(input.redirecturl));
                                    setInput({ ...input, redirecturl: "" });
                                    setError({ ...error, redirecturl: "" });
                                  } else {
                                    setError({
                                      ...error,
                                      redirecturl:
                                        "enter the secure protocol(https)",
                                    });
                                  }
                                } catch (e) {
                                  setError({
                                    ...error,
                                    redirecturl:
                                      "invalid URL (eg: https://sample.com/)",
                                  });
                                }
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                role="button"
                              >
                                add_circle
                              </span>
                            </div>
                          </div>
                          {error.redirecturl !== "" && (
                            <div className="text-danger mx-2">
                              {error.redirecturl}
                            </div>
                          )}
                          <div className="mt-1">
                            {data.redirectURI.map((data, index) => (
                              <div
                                key={index}
                                className="d-flex w-100 my-1 pe-1"
                              >
                                <div className="d-flex w-100 px-2 py-1 border rounded-2 me-4">
                                  <div className="ps-1 txt-overflow">
                                    {data}
                                  </div>

                                  <div
                                    className="ms-auto my-auto lh-1 fw-bold"
                                    onClick={() =>
                                      dispatch(removeRedirectURI(index))
                                    }
                                  >
                                    <span
                                      className="material-symbols-outlined"
                                      role="button"
                                    >
                                      remove
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="mb-2">
                  <button
                    className="onboardbtn2"
                    style={{ width: "123px", height: "41px" }}
                    onClick={() => {
                      setExpandAll(false);
                      setExpandAll1(false);
                      handlePreviousStep();
                    }}
                  >
                    Previous
                  </button>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="onboardbtn2"
                    style={{ width: "123px", height: "41px" }}
                    onClick={() => {
                      if (
                        data.name === "" ||
                        clientName === "" ||
                        data.options.country === "" ||
                        (data.options.allowedLanguages === "" &&
                          data.options.language === "") ||
                        successUrl === "" ||
                        data.url.length < 1 ||
                        data.redirectURI < 1 ||
                        error.originurl !== "" ||
                        error.redirecturl !== ""
                      ) {
                        if (data.name === "") {
                          setError({
                            ...error,
                            app_name: "Application name Required.",
                          });
                          setExpand1(true);
                        } else if (clientName === "") {
                          setError({
                            ...error,
                            app_name: "Enter the valid application name",
                          });
                          setExpand1(true);
                        } else if (data.options.country == "") {
                          setError({ ...error, country: "Country Required." });
                          setExpand2(true);
                        } else if (successUrl === "") {
                          setError({
                            ...error,
                            successUrl: "Success URL Required.",
                          });
                          setExpand4(true);
                        } else if (
                          data.redirectURI < 1 ||
                          data.url.length < 1
                        ) {
                          if (data.redirectURI < 1) {
                            setError({
                              ...error,
                              redirecturl: "Redirect URL Required.",
                            });
                          }
                          if (data.url.length < 1) {
                            setError({
                              ...error,
                              originurl: "Origin URL Required.",
                            });
                          }
                          setExpand4(true);
                        } else if (
                          error.originurl !== "" ||
                          error.redirecturl !== "" ||
                          error.successUrl !== ""
                        ) {
                          setExpand4(true);
                        } else {
                          alert("Please enter all required fields.");
                        }
                      } else if (
                        data.options.country !== "" &&
                        data.options.allowedLanguages !== "" &&
                        data.options.language !== ""
                      ) {
                        handleNextStep();
                      } else {
                        alert("Please enter all required fields.");
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {activeStep === 2 && (
              <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
                {(data.app_access.toLowerCase() == "api" ||
                  data.app_access.toLowerCase() == "all") && (
                  <div className="onboardwidth">
                    <div className="d-flex justify-content-end">
                      <div className="form-check form-switch my-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={expandAll1}
                          onChange={(e) => setExpandAll1(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        >
                          Collapse
                        </label>
                      </div>
                    </div>

                    <Collapse title="All Fields" isOpen={expandAll1}>
                      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                        <div className="d-flex align-items-center mb-2">
                          <b
                            className="me-1 user-select-none"
                            style={{
                              color: "#045EB7",
                              // borderBottom: "1px solid #045EB7",
                            }}
                          >
                            User Required Fields
                          </b>
                          <span
                            className="material-symbols-outlined fs-6 text-info"
                            role="button"
                            data-toggle="tooltip"
                            title={info?.userField}
                          >
                            info
                          </span>
                        </div>
                        <ApiComponent />
                      </div>
                    </Collapse>
                    <Collapse title="Consent" isOpen={expandAll1}>
                      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                        <Consent country={input.country}></Consent>
                      </div>
                    </Collapse>
                    <Collapse title="Social Validation" isOpen={expandAll1}>
                      <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                        <div className="d-flex align-items-center mb-2">
                          <b
                            className="me-1 user-select-none"
                            style={{
                              color: "#045EB7",
                              // borderBottom: "1px solid #045EB7",
                            }}
                          >
                            OneKey Connect
                          </b>
                          <span
                            className="material-symbols-outlined fs-6 text-info"
                            role="button"
                            data-toggle="tooltip"
                            title={info?.oneKey}
                          >
                            info
                          </span>
                        </div>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={data.options.country}
                              id="flexCheckDefault"
                              checked={
                                data.options.social &&
                                data.options.social.includes(
                                  data.options.country
                                )
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                dispatch(
                                  setSocialLogin({
                                    value: e.target.value,
                                    flag: e.target.checked,
                                  })
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {getCountry(data.options.country)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}

                <div className="d-flex mb-2">
                  <button
                    className="onboardbtn2"
                    style={{ width: "123px", height: "41px" }}
                    onClick={() => {
                      setExpandAll(false);
                      setExpandAll1(false);
                      handlePreviousStep();
                    }}
                  >
                    Previous
                  </button>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    <button
                      className={"onboardbtn2"}
                      style={{ width: "123px", height: "41px" }}
                      onClick={() => handleNextStep()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 0 && (
              <div
                style={{
                  marginLeft: "2rem",
                  marginTop: "4rem",
                  marginBottom: "2rem",
                }}
              >
                <h6 style={{ color: "#045EB7" }}>
                  Restrictions
                </h6>
                <div className="d-flex align-items-center mb-2">
                    <NavLink
                      to="/clientcreation"
                      className="fw-bold text-white btn btn-primary"
                    >
                      Client Creation
                    </NavLink>
                    <div className="w-100 mx-3 bold">
                      <marquee>
                        <sup className="text-danger">*</sup>
                        {info?.clientCreationMsg}
                      </marquee>
                    </div>
                  </div>
              

                <div
                  className="onboardwidth pt-2 pb-3"
                  style={{
                    border: "1px  #045EB7",

                    boxShadow: "1px 1px 7px 0px #045EB7",

                    paddingLeft: "3rem",
                  }}
                >
                  <div className="row py-3">
                    <div className="col col-sm-8 col-md-6 col-lg-4">
                      <div>Restrictions</div>
                    </div>

                    <div className="col-2">
                      <div>Mandatory</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Global UI</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.globalui}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={true}
                            type="checkbox"
                            role="button"
                            id="flexCheckDefault"
                            name="globalui"
                            disabled
                            // onChange={() => dispatch(changeHcpValidation())}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">HCP validation</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.hcpValidation}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              data.options?.hcpvalidation === true && "checked"
                            }
                            type="checkbox"
                            role="button"
                            id="flexCheckDefault"
                            name="hcpvalidation"
                            onChange={() => dispatch(changeHcpValidation())}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Allow other apps</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.allowOtherAppsToReg}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={
                              data.options?.allowOtherAppsToReg === true &&
                              "checked"
                            }
                            id="flexCheckDefault"
                            value="yes"
                            name="allowotherapps"
                            onChange={() =>
                              dispatch(changeAllowOtherAppsToReg())
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">HCP Validation mail</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.hcpValidationMail}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data.options?.triggerHcpSuccessEmail}
                            id="flexCheckDefault"
                            value="yes"
                            name="allowotherapps"
                            onChange={(e) =>
                              dispatch(changeTriggerHcpEmail(e.target.checked))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Sync email consent</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.syncEmailConsent}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={
                              data.options?.syncEmailConsent === true &&
                              "checked"
                            }
                            id="flexCheckDefault"
                            value="yes"
                            name="syncEmailConsent"
                            onChange={() => dispatch(changeSyncEmailConsent())}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Update Profile</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.profileFlag}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.updateFlag?.profile}
                            id="flexCheckDefault"
                            name="profileflag"
                            onChange={(e) =>
                              dispatch(
                                setAllowUpdates({
                                  name: "profile",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Update email</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.emailFlag}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.updateFlag?.email}
                            id="flexCheckDefault"
                            name="emailflag"
                            onChange={(e) =>
                              dispatch(
                                setAllowUpdates({
                                  name: "email",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Update password</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.passwordFlag}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.updateFlag?.password}
                            id="flexCheckDefault"
                            name="passwordflag"
                            onChange={(e) =>
                              dispatch(
                                setAllowUpdates({
                                  name: "password",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Quick register</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.quickRegister}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.allowPrepopulate}
                            id="flexCheckDefault"
                            name="passwordflag"
                            onChange={(e) =>
                              dispatch(
                                updateOption({
                                  name: "allowPrepopulate",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">Tokenized</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.Tokenized}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.allowTokenized}
                            id="flexCheckDefault"
                            name="passwordflag"
                            onChange={(e) =>
                              dispatch(
                                updateOption({
                                  name: "allowTokenized",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-sm-8 col-md-6 col-lg-4 d-flex align-items-center">
                      <div className="py-2 me-1">OTP Login</div>
                      <div
                        className="material-symbols-outlined fs-6 text-info"
                        role="button"
                        data-toggle="tooltip"
                        title={info?.OtpLogin}
                      >
                        info
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="button"
                            checked={data?.options?.allowOtp}
                            id="flexCheckDefault"
                            name="passwordflag"
                            onChange={(e) =>
                              dispatch(
                                updateOption({
                                  name: "allowOtp",
                                  flag: e.target.checked,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="onboardbtn2 mt-3 me-2"
                  style={{ width: "123px", height: "41px" }}
                  onClick={() => {
                    role == "App Owner"
                      ? navigate(`/applicationowner`)
                      : navigate(`/applicationadmin`);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="onboardbtn2"
                  style={{ width: "123px", height: "41px", marginTop: "2rem" }}
                  onClick={() => {
                    setExpandAll1(false);
                    setExpandAll(false);
                    handleNextStep();
                  }}
                >
                  Next
                </button>
              </div>
            )}

            {activeStep === 3 && (
              <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
                <div className="onboardwidth">
                  <div className="d-flex justify-content-end">
                    <div className="form-check form-switch my-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={expandAll2}
                        onChange={(e) => {
                          setExpandAll2(e.target.checked);
                          setExpand5(e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Collapse
                      </label>
                    </div>
                  </div>
                  <Collapse title="Field Mapping" isOpen={expandAll2}>
                    <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                      <DraggableComponent setTempFields={setTempFields} />
                    </div>
                  </Collapse>
                  <Collapse
                    title="Mail Template"
                    mandatory
                    isOpen={expand5}
                    setValue={setExpand5}
                  >
                    <div style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                      {error.mailTemplate !== "" && (
                        <div className="text-danger fw-bold mb-2">
                          {error?.mailTemplate}
                        </div>
                      )}
                      {data.options.allowedLanguages.map((lan, index) => {
                        const country = data.options.country;
                        const keys = `${lan.value}-${country}`;
                        return (
                          <div key={index} className="me-4">
                            <div className="h4 collapse_header">
                              {getLanguage(lan.value)}
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <b
                                  className="me-1"
                                  style={{
                                    color: "#045EB7",
                                    // borderBottom: "1px solid #045EB7",
                                  }}
                                >
                                  Amgen URL
                                </b>
                                <span
                                  className="material-symbols-outlined fs-6 text-info"
                                  role="button"
                                  data-toggle="tooltip"
                                  title={info?.amgenUrl}
                                >
                                  info
                                </span>
                              </div>
                              <input
                                className="form-control"
                                name="amgen_url"
                                placeholder="eg: https://sample.com/"
                                value={
                                  Object.keys(mailTemplate).length !== 0 &&
                                  keys in mailTemplate &&
                                  Object.keys(mailTemplate[keys]).length !==
                                    0 &&
                                  "amgen_url" in mailTemplate[keys]
                                    ? mailTemplate[keys]["amgen_url"]
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMailTemplateValues({
                                      obj: keys,
                                      key: e.target.name,
                                      value: trimData(e.target.value),
                                    })
                                  )
                                }
                                onBlur={(e) => {
                                  try {
                                    if (e.target.value.length > 0) {
                                      const url = new URL(e.target.value);
                                      if (url.protocol === "https:") {
                                        dispatch(
                                          setMailTemplateValues({
                                            obj: keys,
                                            key: e.target.name,
                                            value: trimData(e.target.value),
                                          })
                                        );
                                        setError({ ...error, amgen_url: "" });
                                      } else {
                                        setError({
                                          ...error,
                                          amgen_url:
                                            "enter the secure protocol(https)",
                                        });
                                      }
                                    } else {
                                      setError({ ...error, amgen_url: "" });
                                    }
                                  } catch (e) {
                                    setError({
                                      ...error,
                                      amgen_url:
                                        "invalid URL (eg: https://sample.com/)",
                                    });
                                  }
                                }}
                              ></input>
                              {error.amgen_url !== "" && (
                                <div className="text-danger mx-2">
                                  {error.amgen_url}
                                </div>
                              )}
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <b
                                  className="me-1"
                                  style={{
                                    color: "#045EB7",
                                    // borderBottom: "1px solid #045EB7",
                                  }}
                                >
                                  Terms of use URL
                                </b>
                                <span
                                  className="material-symbols-outlined fs-6 text-info"
                                  role="button"
                                  data-toggle="tooltip"
                                  title={info?.touUrl}
                                >
                                  info
                                </span>
                              </div>
                              <input
                                className="form-control"
                                name="terms_of_use_url"
                                placeholder="eg: https://sample.com/"
                                value={
                                  Object.keys(mailTemplate).length !== 0 &&
                                  keys in mailTemplate &&
                                  Object.keys(mailTemplate[keys]).length !==
                                    0 &&
                                  "terms_of_use_url" in mailTemplate[keys]
                                    ? mailTemplate[keys]["terms_of_use_url"]
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMailTemplateValues({
                                      obj: keys,
                                      key: e.target.name,
                                      value: trimData(e.target.value),
                                    })
                                  )
                                }
                                onBlur={(e) => {
                                  try {
                                    if (e.target.value.length > 0) {
                                      const url = new URL(e.target.value);
                                      if (url.protocol === "https:") {
                                        dispatch(
                                          setMailTemplateValues({
                                            obj: keys,
                                            key: e.target.name,
                                            value: trimData(e.target.value),
                                          })
                                        );
                                        setError({
                                          ...error,
                                          terms_of_use_url: "",
                                        });
                                      } else {
                                        setError({
                                          ...error,
                                          terms_of_use_url:
                                            "enter the secure protocol(https)",
                                        });
                                      }
                                    } else {
                                      setError({
                                        ...error,
                                        terms_of_use_url: "",
                                      });
                                    }
                                  } catch (e) {
                                    setError({
                                      ...error,
                                      terms_of_use_url:
                                        "invalid URL (eg: https://sample.com/)",
                                    });
                                  }
                                }}
                              ></input>
                              {error.terms_of_use_url !== "" && (
                                <div className="text-danger mx-2">
                                  {error.terms_of_use_url}
                                </div>
                              )}
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <b
                                  className="me-1"
                                  style={{
                                    color: "#045EB7",
                                    // borderBottom: "1px solid #045EB7",
                                  }}
                                >
                                  Privacy URL
                                </b>
                                <span
                                  className="material-symbols-outlined fs-6 text-info"
                                  role="button"
                                  data-toggle="tooltip"
                                  title={info?.privacyUrl}
                                >
                                  info
                                </span>
                              </div>
                              <input
                                className="form-control"
                                name="privacy_url"
                                placeholder="eg: https://sample.com/"
                                value={
                                  Object.keys(mailTemplate).length !== 0 &&
                                  keys in mailTemplate &&
                                  Object.keys(mailTemplate[keys]).length !==
                                    0 &&
                                  "privacy_url" in mailTemplate[keys]
                                    ? mailTemplate[keys]["privacy_url"]
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMailTemplateValues({
                                      obj: keys,
                                      key: e.target.name,
                                      value: trimData(e.target.value),
                                    })
                                  )
                                }
                                onBlur={(e) => {
                                  try {
                                    if (e.target.value.length > 0) {
                                      const url = new URL(e.target.value);
                                      if (url.protocol === "https:") {
                                        dispatch(
                                          setMailTemplateValues({
                                            obj: keys,
                                            key: e.target.name,
                                            value: trimData(e.target.value),
                                          })
                                        );
                                        setError({
                                          ...error,
                                          lprivacy_url: "",
                                        });
                                      } else {
                                        setError({
                                          ...error,
                                          privacy_url:
                                            "enter the secure protocol(https)",
                                        });
                                      }
                                    } else {
                                      setError({ ...error, privacy_url: "" });
                                    }
                                  } catch (e) {
                                    setError({
                                      ...error,
                                      privacy_url:
                                        "invalid URL (eg: https://sample.com/)",
                                    });
                                  }
                                }}
                              ></input>
                              {error.privacy_url !== "" && (
                                <div className="text-danger mx-2">
                                  {error.privacy_url}
                                </div>
                              )}
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <b
                                  className="me-1"
                                  style={{
                                    color: "#045EB7",
                                    // borderBottom: "1px solid #045EB7",
                                  }}
                                >
                                  Team DL
                                </b>
                                <span
                                  className="material-symbols-outlined fs-6 text-info"
                                  role="button"
                                  data-toggle="tooltip"
                                  title={info?.teamDl}
                                >
                                  info
                                </span>
                              </div>
                              <input
                                className="form-control"
                                name="team_dl"
                                placeholder="eg: samplemail@mailinator.com"
                                value={
                                  Object.keys(mailTemplate).length !== 0 &&
                                  keys in mailTemplate &&
                                  Object.keys(mailTemplate[keys]).length !==
                                    0 &&
                                  "team_dl" in mailTemplate[keys]
                                    ? mailTemplate[keys]["team_dl"]
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMailTemplateValues({
                                      obj: keys,
                                      key: e.target.name,
                                      value: trimData(e.target.value),
                                    })
                                  )
                                }
                                onBlur={(e) => {
                                  try {
                                    if (e.target.value.length > 0) {
                                      if (
                                        constant.emailRegex.test(
                                          e.target.value.trim()
                                        )
                                      ) {
                                        dispatch(
                                          setMailTemplateValues({
                                            obj: keys,
                                            key: e.target.name,
                                            value: trimData(e.target.value),
                                          })
                                        );
                                        setError({ ...error, team_dl: "" });
                                      } else {
                                        setError({
                                          ...error,
                                          team_dl: "enter the valid email",
                                        });
                                      }
                                    } else {
                                      setError({ ...error, team_dl: "" });
                                    }
                                  } catch (e) {
                                    setError({
                                      ...error,
                                      team_dl:
                                        "invalid URL (eg: https://sample.com/)",
                                    });
                                  }
                                }}
                              ></input>
                              {error.team_dl !== "" && (
                                <div className="text-danger mx-2">
                                  {error.team_dl}
                                </div>
                              )}
                            </div>
                            <div className="mb-4">
                              <div className="d-flex align-items-center mb-2">
                                <b
                                  className="me-1"
                                  style={{
                                    color: "#045EB7",
                                    // borderBottom: "1px solid #045EB7",
                                  }}
                                >
                                  Team Name
                                </b>
                                <span
                                  className="material-symbols-outlined fs-6 text-info"
                                  role="button"
                                  data-toggle="tooltip"
                                  title={info?.teamName}
                                >
                                  info
                                </span>
                              </div>
                              <input
                                className="form-control"
                                name="team_name"
                                placeholder=""
                                value={
                                  Object.keys(mailTemplate).length !== 0 &&
                                  keys in mailTemplate &&
                                  Object.keys(mailTemplate[keys]).length !==
                                    0 &&
                                  "team_name" in mailTemplate[keys]
                                    ? mailTemplate[keys]["team_name"]
                                    : ""
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMailTemplateValues({
                                      obj: keys,
                                      key: e.target.name,
                                      value: trimData(e.target.value),
                                    })
                                  )
                                }
                                onBlur={(e) => {
                                  try {
                                    if (e.target.value.length > 0) {
                                      dispatch(
                                        setMailTemplateValues({
                                          obj: keys,
                                          key: e.target.name,
                                          value: trimData(e.target.value),
                                        })
                                      );
                                    } else {
                                      setError({ ...error, team_name: "" });
                                    }
                                  } catch (e) {
                                    setError({
                                      ...error,
                                      team_name:
                                        "invalid URL (eg: https://sample.com/)",
                                    });
                                  }
                                }}
                              ></input>
                              {error.team_name !== "" && (
                                <div className="text-danger mx-2">
                                  {error.team_name}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                  <Collapse title="Verbiage update" isOpen={expandAll2}>
                    <AddTranslation />
                  </Collapse>
                  <div className="d-flex mb-2">
                    <button
                      className="onboardbtn2"
                      style={{ width: "123px", height: "41px" }}
                      onClick={() => {
                        setExpandAll(false);
                        setExpandAll1(false);
                        handlePreviousStep();
                      }}
                    >
                      Previous
                    </button>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <div>
                      <button
                        className={
                          completeButtonDisable
                            ? "onboardbtn2 opacity-50"
                            : "onboardbtn2"
                        }
                        style={{ width: "123px", height: "41px" }}
                        disabled={completeButtonDisable}
                        onClick={() => {
                          if(Object.keys(mailTemplate).length){
                            if(checkForNullOrEmptyValues(mailTemplate)){
                              setError({
                                        ...error,
                                        mailTemplate: "Please fill all the fields.",
                                      });
                                      setExpand5(true);
                            }else{
                              setError({ ...error, mailTemplate: "" });
                              setExpandAll2(false);
                              setExpand5(false);
                              dispatch(updateFieldMapping(updateMapping(tempFields)));
                              handleComplete();
                            }
                          }else{
                              setError({
                                ...error,
                                mailTemplate: "Please fill all the fields.",
                              });
                              setExpand5(true);
                          }
                        }}
                      >
                        Complete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <MandatoryTrans
          tiggerTrans={tiggerTrans}
          modalIsOpen={mandatoryTrans}
          handleSubmitModel={handleSubmitModel}
          setTiggerTrans={setTiggerTrans}
          closeModal={handleTransModel}
        ></MandatoryTrans>
        <Popup
          modalIsOpen={submitModel}
          closeModal={handleSubmitModel}
          setShowModal={setShowModal}
          setModalBody={setModalBody}
        ></Popup>
        <OnboardModal
          body={modalBody}
          modalIsOpen={showModal}
          closeModal={handleClose}
        ></OnboardModal>
      </>
    </main>
  );
}

export default Onboard;


// Function to check if any value in the nested object is null or empty
function checkForNullOrEmptyValues(obj) {
  return Object.values(obj).some(value => {
    if (typeof value === 'object' && value !== null) {
      return checkForNullOrEmptyValues(value);
    }
    return value === "" || value === null;
  });
}

