import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import axios from "axios";
import * as constant from "../Constants/constant";
import Dropzone from "react-dropzone";
import MessagePopup from "../Popup/Messagepopup";
// import { fabClasses } from "@mui/material";
import { sanitizeUserInput } from "../../Helper/sanitize";
import * as XLSX from 'xlsx';
import validator from 'validator';

export default function Preload({ list }) {
  const [data, setData] = useState([]);
  const [item, setItem] = useState("");
  const [submit, setSubmit] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [country, setCountry] = useState("");
  const [isAppLoader,setAppLoader]=useState(false);
  const [newAppList,setAppList]=useState([]);
  const user = localStorage.getItem("username");
  const {info,instruction} = useSelector((state) => state.info);
  const { csrf } = useSelector((state) => state.app);
  // const role = localStorage.getItem("role");
  // const{role} = useSelector((state) => state.dashboard);
  const{ countryListNew} = useSelector((state) => state.dashboard);
  const [messageIsOpen, setMessageIsOpen] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  axios.defaults.withCredentials = true;

  const [hasRows, setHasRows] = useState(false);



  const handleFileUpload = (files) => {
    // const file = e.target.files[0];
 
    if (files) {
      const reader = new FileReader();
 
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
 
        // Assuming the first sheet is your target sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
 
        // Convert the worksheet to an array of objects
        const excelData = XLSX.utils.sheet_to_json(worksheet);
 
        // Check if there is at least one row
        setHasRows(excelData.length > 0);
      };
 
      reader.readAsArrayBuffer(files);
    }
  };


    function openmessagepopup() {
        setMessageIsOpen(true);
      }
      function closemessagepopup() {
        setMessageIsOpen(false);
      }

const [message,setMessage]=useState({
        title:"",
        body:"",
        success:false
    })

    useEffect(() => {
      if(countryListNew.length>0){
        setCountry(countryListNew[0].value);
      }
    }, []);
  
    useEffect(() => {
      setAppLoader((pre)=>!pre);
        if(country){
         axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`, {
          headers: { ...constant.headers },
        }).then((res) => {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/country-app-list`,
              { country: country },
              {
                headers: {
                  ...constant.headers,
                  "csrf-token": res.data.data.csrfToken,
                },
                withCredentials: true,
              }
            )
            .then((response) => {
              if(typeof response.data.data === "object" &&  Object.keys(response.data.data).length>0 ){
                setAppList(response.data.data.apps);
            }else{
              setAppList([]);
            }
            setAppLoader((pre)=>!pre);
            })
            .catch((er) => {
              console.log("errorr ",sanitizeUserInput(JSON.stringify(er)));
              setAppList([]);
              setAppLoader((pre)=>!pre);

            });
        }).catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)), "errorItem");
          setAppList([]);
          setAppLoader((pre)=>!pre);
        })
      }else{
        setAppList([]);
        setAppLoader((pre)=>!pre);
      }
    },[country]);

  useEffect(() => {
    if (item === "") {
      return;
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-app`,
          { application_name: item },
          {
            headers: { ...constant.headers,"csrf-token": csrf },
          }
        )
      .then((response) => {
        const steps = response.data.data.fields.register.steps;
        let headList = []
        for(const step in response.data.data.fields.register.steps){
          const lst = steps[step].fields.map((data) => data.name)
          // console.log(lst)
          const fld = lst.filter((data)=>data!=="password" && data!=="password1" && data!=="confirmPassword")
          headList = headList.concat(fld);
        }
        const val = response.data.data.fields.register.steps[0].fields.map(
          (data) =>data.label);
          const v1=val.filter((data)=>data!=="Password" && data!=="Confirm Password");
          headList.push("country")
          headList.push("application_name")
        setFieldsData([headList]);
        // console.log(headList);

      })
.catch((error) => {
          console.log(sanitizeUserInput(JSON.stringify(error)));
        });
    }
  }, [item]);
  const handleSubmit = async(event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/uploading-preload`,
        data: formData,
        headers: { 
          ...constant.headers,
        "csrf-token": csrf ,
          "Content-Type": "multipart/form-data"
         },
      });
      // console.log(response,"response")
      setMessage(prevState => ({
        ...prevState,
        title: "Preload Users uploaded Successfully",
        body:"Users preload request submitted successfully, waiting for approval. Thanks for using passport!",
        success:true
      }));
      openmessagepopup();
    } catch(error) {
      console.log(sanitizeUserInput(JSON.stringify(error)))
    }
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <>
    <div className="d-flex h-100">
    <div className="w-100">
      <section className="mb-5">
        <p className="primary">Step 1:</p>
        <div className="d-flex w-100 justify-conyent-between align-items-end">
        <div className="me-2">
          <div className="d-flex align-items-center mb-2">
                        <b className="me-1" style={{
                                // color: "#045EB7",
                              }}>Select Country</b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.country}
                        >
                          info
                        </span>
                      </div>
            <div className="dropdown">
              <select
                name="language"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                // className="form-select"
                aria-label="Default select example"
                        style={{
                          border: "1px solid #ced4da",
                          width: "150px",
                          borderRadius: "5px",
                          height: "2rem",
                        }}
              >
                <option value="">Select Country</option>
                {countryListNew.map((x, index) => (
                 <option key={index} value={x.value}>
                 {x.label}
               </option>
                   ))}
              </select>
            </div>
          </div>
          <div className="me-2">
          <div className="d-flex align-items-center mb-2">
                        <b className="me-1" style={{
                                // color: "#045EB7",
                              }}>Select Application</b>
                        <span
                          className="material-symbols-outlined fs-6 text-info"
                          role="button"
                          data-toggle="tooltip"
                          title={info?.preloadSelectApplication}
                        >
                          info
                        </span>
                      </div>
            <div className="dropdown">
              <select
                name="language"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                aria-label="Default select example"
                        style={{
                          border: "1px solid #ced4da",
                          width: "150px",
                          borderRadius: "5px",
                          height: "2rem",
                        }}
              >
                { !isAppLoader &&  <option value="">Select Application</option>}
                { isAppLoader && <option value="">Loading...</option>}
                {newAppList.map((data, index) => (
                  <option key={index} value={data.application_name}>
                    { data?.aliasName}
                  </option>
                   ))}
              </select>
            </div>
          </div>
          <div className="">
            {item ? 
            <CSVLink
              data={fieldsData}
              filename={"preload"+user.slice(0, 3)+item}
              className="btn px-3 border border-secondary"
              style={ { color: "#fff", backgroundColor: "#045EB7" }}
            >
              Download me
            </CSVLink> : <button className="btn px-3 border border-secondary" style={{ color: "#606060", backgroundColor: "#F4F4F4" }}> Download me
              </button>}
            {/* <button
              className="btn px-3 border border-secondary"
              style={
                item === ""
                  ? { color: "#606060", backgroundColor: "#F4F4F4" }
                  : { color: "#fff", backgroundColor: "#045EB7" }
              }
            >
              Download Template
            </button> */}
          </div>
        </div>
        {item &&  <p className="secondary mt-1">
         <span className="text-danger">**</span> <span className="secondary">please fill application_name column with <span className="text-danger">{item}</span> </span>
        </p>}
      </section>
      <section>
        <p className="mb-2 primary">Step 2:</p>
        <p className="secondary">
          Please upload the completed file with required template format.
        </p>
        <p className="mt-4 mb-1 primary">Upload your files here</p>
        <div style={{ width: "max-content" }}>
          <Dropzone
            multiple={false}
            accept={".csv"}
            onDrop={(files) => {
              setSubmit(false);
              handleFileUpload(files[0]);
              setData(files);
              
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p
                  className="my-auto d-flex align-items-center rounded px-5 py-3 secondary"
                  style={{
                    width: "max-content",
                    borderWidth: "1px",
                    borderStyle: "dashed",
                  }}
                >
                  <span className="material-symbols-outlined fs-1">folder</span>
                  Drag your files here or 
                  <span className="ms-1 primary">browse</span>
                </p>
              </div>
            )}
          </Dropzone>
        </div>
        {data.map((data, index) => (
          <>{!submit && <div  className="text-primary" key={index}>{data.name}</div>}
          {submit && <div className="text-danger" key={index}>xxx.csv does not contain any values</div>}</>
        ))}
        {(isButtonLoading) ? <div className="w-100">
              <button  type="button" className="btn mt-5 rounded-1 px-4 tab"
                style={{ marginLeft: "10rem", background:"#045EB7",color:"white", width:'100px' }}
                >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div> : <div className="w-100">
        <button
          className="btn mt-5 rounded-1 px-4 tab"
          style={{ marginLeft: "10rem", background:"#045EB7",color:"white", width:'100px' }}
          onClick={(async()=>{
            setIsButtonLoading(true)

            const formData = new FormData();
            formData.append("file", data[0]);
            formData.append("request_type","preload")
            formData.append("application_name",item)
            formData.append("email",user)
            // let name = 'preload' + validator.escape(user.slice(0, 3));
            let name = 'preload' + (user.slice(0, 3).match(/[a-zA-Z0-9]{3}/) || []).join('');
            let time = new Date().toISOString();
            const querystr = `?name=${name}&time=${time}`;
            // const querystr = "?name=preload"+user.slice(0, 3)+"&time="+new Date().toISOString();

            if(hasRows){

              if(item!=""){
                try {
                  let baseUrl = process.env.REACT_APP_API_BASE_URL;
                  if (validator.isURL(baseUrl)) {
                    setIsButtonLoading(true)
                    const response = await axios({
                      method: "post",
                      url: `${process.env.REACT_APP_API_BASE_URL}/api/uploading-preload${querystr}`,
                      data: formData,
                      headers: { 
                        ...constant.headers,
                        "csrf-token": csrf ,
                        "Content-Type": "multipart/form-data"
                      },
                    });
                    // console.log(response,"response")
                    if(response.data && response.data.error && response.data.error.message){
                    setIsButtonLoading(false)
    
                      alert(response.data.error.message)
                    }else if(response.data && response.data.data){
    
                      setMessage(prevState => ({
                        ...prevState,
                        title: "Preload Users uploaded Successfully",
                        body:"Users preload request submitted successfully, waiting for approval. Thanks for using passport!",
                        success:true
                      }));
                      setIsButtonLoading(false)
    
                      openmessagepopup();
                      // alert( "Request Id: " + response.data.data.requestId + ", " +response.data.data.message + " the file!")
                    }
                    setData([]);
                  }
                } catch(error) {
                  setIsButtonLoading(false)
                  console.log(sanitizeUserInput(JSON.stringify(error)))
                }
              }else{
                setIsButtonLoading(false)
                alert("Select application!")
              }

            }else{
              setSubmit(true);
              setIsButtonLoading(false)
            }
            
            
          })}
          disabled={ (item !== "" && data.length) ? false:true}
        >
          Submit
        </button>
        </div>}
      </section>
     </div>
<div className="w-100 px-2 overflow-auto">
  <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
      Instructions
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse show w-100" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
        
        {instruction.preload.map((data,index)=>(<div key={index} className="d-flex"> <div className="dot mt-1 me-2" /> <div className="lh-sm">{data}</div> </div>))}
       </div>
    </div>
  </div>

</div>
</div>
    </div>
    <MessagePopup 
                    modalIsOpen={messageIsOpen} 
                    closeModal={closemessagepopup}
                    title={message.title }
                    body={message.body}
                    success={message.success}
                    />
    </>
  );
}
