import React,{useEffect,useState} from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Homepage from "./components/homepage/homepage";
import Signinpage from "./components/signinpage/signinpage";
import TestPage from "./components/Myrequest/test";
import Signinfail from "./components/signinpage/Signinfailpage";
import { oktaConfig } from "./config";
import { RequiredAuth, UserInfo,RoleVerify } from "./components/secureRoute";
import Logout from "./components/signinpage/logout";
import { useSelector, useDispatch } from "react-redux";
import { getCsrfToken } from "./Helper/helper";
import { setToken } from "./store/appslice";
import Draftpopup from "./components/Whitelist/draftpopup";
import { changeFlag } from "./store/tempslice";
import { allRoutes } from "./Routes";
import * as constant from "./components/Constants/constant";
import axios from 'axios';
import {setInformation, setPreloadInstruction} from "../src/store/info";
import Rolenotfound from "./components/Rolenotfound";
import ClientDraft from "./components/Client Creation Admin/clientDraft";
import Signin from "./components/signinpage/Signin";

const oktaAuth = new OktaAuth(oktaConfig);
function App() {
  const {csrf} = useSelector((state) => state.app);
  const navigate = useNavigate();
  //let role = localStorage.getItem('role');
  const dispatch = useDispatch();
  const originalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const { flag } = useSelector((state) => state.temp);

  useEffect(()=>{
    // document.addEventListener('contextmenu', function(e) {
    //   e.preventDefault();
    // });

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-app`,{application_name:"dashboard"},
            {
                headers: { ...constant.headers,"csrf-token": csrf },
            }
            )
            .then((response) => {
                dispatch(setInformation(response.data.data.options.info));
                dispatch(setPreloadInstruction(response.data.data.options.instruction.preload));
            })
            .catch(error => {console.log(error)}) 

  },[])

  function closeWhitelistPopup() {
    dispatch(changeFlag("whitelist"));
  }
useEffect(()=>{
  const interval = setInterval(() => {
    getCsrfToken().then((data)=>{
      dispatch(setToken(data));
    })
  }, 1200000);
  return () => clearInterval(interval);
},[])
  return (

      <div>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="login/callback" element={<LoginCallback />} />
            {allRoutes.map((data,index)=>
              <Route key={index} path="" element={<RequiredAuth />}>
                <Route path="" element={<UserInfo />}>
                  <Route path="" element={<RoleVerify />}>
                    <Route path={data.path} element={<data.component />} />
                  </Route>
                </Route>
              </Route>
            )}
            <Route path="login" element={<Signinpage />} />
            <Route path="notavailable" element={<TestPage />} />
            <Route path="*" element={<TestPage />} />
            <Route path="notfound" element={<Signinfail />} />
            <Route path="rolenotfound" element={<Rolenotfound />} />
            <Route path="logout" element={<Logout />} />
          </Routes>
        </Security>
        <Draftpopup 
          modalIsOpen={flag?.whitelist} 
          closeModal={closeWhitelistPopup}></Draftpopup>
          <ClientDraft />
      </div>
  );
}

export default App;
