import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { changeNewField, addNewRegisterField,updateTranslation} from "../../store/slice";
import { trimData } from "../../Helper/onboard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    background: "white"
  },
};

export default function AddFieldPopup({ modalIsOpen, closeModal }) {
  const { newField, data, translations, onboard } = useSelector((state) => state.onboard);
  const dispatch = useDispatch();
  const [tempvalue, setTempValue] = useState("");
  const [error, setError] = useState("");
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");

  const fieldType = ["text", "dropdown"];
  

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div style={{ maxHeight: "40vw" }}>
          <span
            type="button"
            className="material-symbols-outlined text-white"
            style={{
              float: "right",
              background: "#AAAAAA",
              borderRadius: "15px",
            }}
            onClick={closeModal}
          >
            close
          </span>
          <div className="container text-dark mb-3">
            <p className="fs-4 fw-light pe-none" style={{ color: "#045eb7" }}>
              Add new field
            </p>
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <div className="">Type</div>
              </div>
              <div className="col-4">
                <div className=" dropdown align-self-center">
                  <button
                    className="btn border py-1 border-secondary w-100 rounded-1 d-flex justify-content-between align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {newField.type}<i className="fa fa-chevron-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {fieldType.map((data, index) => (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            dispatch(changeNewField({ option: 1, type: data }))
                          }
                        >
                          {data}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <div className="">Name</div>
              </div>
              <div className="col-4">
                <input
                  className="form-control ps-2 w-100"
                  name="name"
                  placeholder="Enter field name"
                  value={newField?.name ? newField?.name : ""}
                  onChange={(e) => {
                    dispatch(
                      changeNewField({
                        option: 0,
                        name: e.target.name,
                        value: trimData(e.target.value),
                      })
                    );
                  }}
                ></input>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <div className="">Label</div>
              </div>
              <div className="col-4">
                <input
                  className="form-control ps-2 w-100"
                  name="label"
                  placeholder="Enter label"
                  defaultValue={newField.label}
                  onBlur={(e)=>{
                      setLabel({...label,"en":trimData(e.target.value)})
                  }}
                  onChange={(e) => {
                    dispatch(
                      changeNewField({
                        option: 0,
                        name: e.target.name,
                        value:e.target.value,
                      })
                    );
                    
                  }}
                ></input>
              </div>
              {/* <div className="col-5">
              <div className="d-flex add-field overflow-auto">
                    {data?.options?.allowedLanguages?.map((data, index) => {
                      if(data.value!= "en") {
                        return <input key={index}
                          className="form-control me-1 mb-1" 
                          name="label" 
                          placeholder={`Label - ${data.label}`} 
                          onBlur={(e)=>{
                            const temp = {...label};
                            temp[data.value] = e.target.value;
                            setLabel(temp);
                          }}
                      ></input>
                    }}
                    )}
                  </div>
              </div> */}
            </div>
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <div className="">Placeholder</div>
              </div>
              <div className="col-4">
                <input
                  className="form-control ps-2 w-100"
                  name="placeholder"
                  placeholder="Enter placeholder"
                  defaultValue={newField.placeholder}
                  onBlur={(e)=>{
                    setPlaceholder({...placeholder,"en":trimData(e.target.value)})
                }}
                  onChange={(e) => {
                    dispatch(
                      changeNewField({
                        option: 0,
                        name: e.target.name,
                        value: e.target.value,
                      })
                    );
                  }}
                ></input>
              </div>
              {/* <div className="col-5">
                  <div className="d-flex add-field overflow-auto">
                    {data?.options?.allowedLanguages?.map((data, index) => {
                      if(data.value!= "en") {
                        return <input key={index}
                          className="form-control me-1 mb-1" 
                          name="placeholder" 
                          placeholder={`Placeholder - ${data.label}`} 
                          onBlur={(e)=>{
                            const temp = {...placeholder};
                            temp[data.value] = e.target.value;
                            setPlaceholder(temp);
                          }}
                        ></input>
                      }
                    })}
                  </div>
              </div> */}
            </div>
            {newField.type.toLowerCase() === "dropdown" && (
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <div className="">Values</div>
                </div>
                <div className="col-4 ">
                  <div className="d-flex">
                    <input
                      className="form-control ps-2 w-100"
                      type="text"
                      name="values"
                      placeholder="add values"
                      value={tempvalue}
                      onChange={(e) => setTempValue(e.target.value)}
                    ></input>
                    <div
                      className="ms-3 my-auto lh-1 fw-bold"
                      onClick={() => {
                        if (tempvalue != "") {
                          dispatch(
                            changeNewField({ option: 2, value: trimData(tempvalue)})
                          );
                        }
                        setTempValue("");
                      }}
                    >
                      <span className="material-symbols-outlined" role="button">
                        add_circle
                      </span>
                    </div>
                  </div>
                  <div className="d-flex my-2 add-field overflow-auto">
                    {newField.values?.map((data, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center px-2 my-1 me-1 border rounded-pill"
                      >
                        <p className="m-0 ps-1">{data.value}</p>
                        <span
                          role="button"
                          className="ms-1 material-symbols-outlined fs-6 text-danger"
                          onClick={() =>
                            dispatch(
                              changeNewField({ option: 3, index: index })
                            )
                          }
                        >
                          do_not_disturb_on
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <div className="">Is mandatory?</div>
              </div>
              <div className="col-4" style={{paddingLeft:'36px'}}>
                <input
                  className="form-check-input"
                  role="button"
                  type="checkbox"
                  checked={newField.validations.required === true && "checked"}
                  onChange={() => dispatch(changeNewField({ option: 4 }))}
                  id="flexCheckDefault"
                />
              </div>
            </div>

            <div className="py-2">
              <button
                className="btn px-4 btn-outline-primary"
                onClick={() => {
                  // dispatch(updateTranslation([label,placeholder]));
                  if (
                    newField.name !== "" &&
                    newField.label !== "" &&
                    newField.placeholder !== ""
                  ) {
                    if(newField.type==="dropdown" && newField.values===null){
                      setError("Values mandatory!");
                    }else{
                    setError("");
                    if (
                      data.options.fields.filter(
                        (d, i) => d.name == newField.name
                      ).length >= 1
                    ) {
                      setError("name already exists !");
                    } else {
                      setError("");
                      dispatch(addNewRegisterField());
                      closeModal();
                    }
                 } } else {
                    setError("name,label,placeholder is mandatory!");
                  }
                }}
              >
                Add
              </button>
              {error !== "" && <div className="text-danger mt-2">{error}</div>}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
