export const updateProfile = (fields, flag) => {
  const skipFields = ["password1"];
  const newData = fields.filter((d, i) => !skipFields.includes(d.name));
  const updateFields = newData.map((data) => {
    if (data.name === "email") {
      const temp = { ...data };
      temp["flag"] = flag.email;
      return temp;
    } else if (data.name === "password") {
      let temp = JSON.parse(JSON.stringify({ ...data }));
      temp["flag"] = flag.password;
      delete temp["passwordPolicy"];
      delete temp.validations.min;
      return temp;
    } else {
      return data;
    }
  });
  return [...updateFields];
};
export const consentUpdate = (fields) => {
  const skipFields = ["termsandcondition"];
  const newData = fields.filter((d, i) => !skipFields.includes(d.name));
  return newData;
};

export const updateMigrate = (fields) => {
  const skipFields = ["password", "password1"];
  const newData = fields.filter((d, i) => !skipFields.includes(d.name));
  const updateFields = newData.map((data) => {
    if (data.name === "email") {
      const temp = { ...data };
      temp["disabled"] = true;
      return temp;
    } else {
      return data;
    }
  });
  return [...updateFields];
};

export const updateMapping = (fields) => {
  const final = Object.keys(fields).map((data) => {
    return {
      data: fields[data]["items"].map((d) => d.id),
      section: fields[data]["name"],
    };
  });
  return final;
};

export const updateMigrateMapping = (fields) => {
  const skipFields = ["password", "password1"];
  const final = fields.map((data) => {
    return {
      data: data.data.filter((d) => !skipFields.includes(d)),
      section: data.section,
    };
  });
  return final;
};
export const updateProfileMapping = (fields) => {
  const skipFields = ["password1"];
  const final = fields.map((data) => {
    return {
      data: data.data.filter((d) => !skipFields.includes(d)),
      section: data.section,
    };
  });
  return final;
};

export const trimData = (data) => data.trim();
