import React, { useState,useEffect } from "react";
import Sidebar from '../Sidebar/Sidebar';
import AmgenLogo from '../Assets/Group1.svg';
import axios from "axios";
import * as constant from '../Constants/constant';
import { useSelector, useDispatch } from "react-redux";
import { setSuccessApplicationList,setFailureApplicationList } from "../../store/mailslice";
import {setSuccessAppnameAndLag,setFailureAppnameAndLag} from "../../store/mailslice";
import Popover from '@mui/material/Popover';
import UserPopover from "../userpopover";
import ForgerockMail from "./forgerockemail";
import History from "./history";
import Request from "./request";
import Loader from "../Loader/loader";
import Template from "./mailtemplate";
import { setAllowedMailApplicationlist } from "../../store/frmails";
import {
  setShow
} from "../../store/appslice"
import { sanitizeUserInput } from "../../Helper/sanitize";

export default function MailTemplate() {
  
  const [mailcountry, setMailCountry] = useState("");
  const [tab, setTab] = useState(0);
  const [approveLoading, setApprove] = useState(false);
  const dispatch = useDispatch();
  const user = localStorage.getItem("username");
  const { csrf, show } = useSelector((state) => state.app);
  axios.defaults.withCredentials = true;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/get-user`,
      { email: user },
      {
        headers: {
          ...constant.headers,
          "csrf-token":csrf,
        },
      }
    )
.then((response) => {
  dispatch(
    setSuccessAppnameAndLag({
      name: "",country: "", language:""
    })
)
dispatch(
  setFailureAppnameAndLag({
    name: "",country: "", language: ""
  })
)
  const temp=response.data.data.data
 dispatch(setAllowedMailApplicationlist(temp));
    // dispatch(setSuccessApplicationList(response.data.data.data));
    // dispatch(setFailureApplicationList(response.data.data.data));
    // let a = response.data.data.data;
    // let b = a.map((data)=>{(data.application_name.includes("demoTwo"))});
})
.catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))});
}, []);

  return (<div>
    <main className={show ? 'space-toggle' : null} style={{margin:0, height:"100vh"}}>
    
    <aside style={{ background: "#045EB7" }} className={`sidebar ${show ? 'show' : null}`}>
        <nav className='nav'>
            <div>
                <div className='header-toggle' onClick={() => dispatch(setShow())}>
                    <span className='fas fa-bars sidebarfirst'></span>
                </div>
                <Sidebar show={show}></Sidebar>
            </div>
        </nav>
    </aside>
        <div className="bodyfirstpreload">

        <header style={{ background: "white",zIndex:"-1" ,height:"7vh",padding:0}} className={`header ${show ? 'space-toggle' : null}`}>
        <span><img style={{ paddingLeft: "1rem",height:"40px" }} src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg" alt=""></img></span>
        <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}
        
            <span 
                                aria-describedby={id} 
                                role="button" 
                                class="material-symbols-outlined headercolor fs-1 me-3" 
                                onClick={handleClick}
                                >account_circle
                            </span>
                            
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                            >
                                <UserPopover/>
                            </Popover>
        </div>
        </header>
        
        <div className="px-4 py-3" style={{ backgroundColor: "#edf2f6", height:"93vh" }}>
          <div className="ms-4 fw-bold pb-2" style={{color:"#0463c3"}}>Mail Template</div>
      <div className="ms-5 d-flex">
        {/* <div
          role="button"
          className={
            tab === 0
              ? "active-tab bg-white rounded-top fw-bold px-4 py-2 "
              : "tab rounded-1 fw-bold px-4 py-2"
          }
          onClick={() => setTab(0)}
        >
          Template
        </div> */}
        {/* <div
          role="button"
          className={
            tab === 1
              ? "active-tab ms-2 bg-white rounded-top fw-bold px-4 py-2 "
              : "tab ms-2 rounded-1 fw-bold px-4 py-2"
          }
          onClick={() => setTab(1)}
        >
          Request
        </div>
        <div
          role="button"
          className={
            tab === 2
              ? "active-tab ms-2 bg-white rounded-top fw-bold px-4 py-2 "
              : "tab ms-2 rounded-1 fw-bold px-4 py-2"
          }
          onClick={() => setTab(2)}
        >
          History
        </div> */}
      </div>
      <div className="bodyfirstpreload overflow-auto">
      <div className=" mx-0">
      <div className="rounded bg-white">
        {/* {tab === 0 && <ForgerockMail />} */}
        {tab === 0 && <Template />}
        {/* {tab === 1 && <Request setApprove={setApprove}/>}
        {tab === 2 && <History/>} */}
        </div>
        </div>
      </div>
        </div>
        </div>
</main >
{approveLoading && <Loader />}
</div>
  );
}