import DataTable from 'react-data-table-component';
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dna } from  'react-loader-spinner';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { customStyles } from './customStyletable';



function MyComponent(props) {
    const { Loader } = useSelector((state) => state.onboard);
    const data = props.data;
    const columns = props.columns;
    const [rows, setRows] = React.useState([]);
    const [perPage, setPerPage] = React.useState(props.paginationPerPage);
    const [loading, setLoading] = React.useState(true);
    const [totalRows, setTotalRows] = useState(props.paginationPerPage);
    const [maxPage, setMaxPage] = React.useState(false);
    const dispatch = useDispatch();

    const [pending, setPending] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(data);
      setPending(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

    React.useEffect(() => {
        // setRows(data);
        setLoading(false);
        setMaxPage(false)
    }, []);
    const handlePageChange = (page) => {
        const maxp =  (Math.ceil(data.length/perPage))
        if(page === maxp && data.length > 9 && props.setMaxLength){
            setMaxPage(true)
        }if(page === (maxp-1) && data.length > 9 && props.setMaxLength){
            setMaxPage(false)
        }else if(!props.setMaxLength){
            setMaxPage(false)
        }
      };
    const handlePerRowsChange = async (newPerPage, page) => {
        const maxp =  (Math.ceil(data.length/perPage))
		setPerPage(newPerPage);
        if(page === maxp && data.length > 9 && props.setMaxLength){
            setMaxPage(true)
        }else{
            setMaxPage(false)
        }
	};


    const tableData = {
       columns:props.columns,
        data:props.data
    };

    return (
        <div>
            {(typeof(data)==="string") ? 
            <Stack spacing={1.5}>
                <Skeleton variant="rounded"  animation="wave" height={40} />
                <Stack spacing={0}>
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                    <Skeleton variant="rounded"  animation="wave" height={30} />
                </Stack>
            </Stack>
            :

            <div>
                    <DataTableExtensions
                    {...tableData}
                    exportHeaders = {true}
                    fileName = {props.fileName}
                    filterDigit = {0}
                    filterPlaceholder = "Search Table"
                    print={false}
                    filter={props.filter}
                    export={props.export}
                    >
                        
                        <DataTable 
                            columns={props.columns}
                            data={props.data}
                            sortable={true}
                            highlightOnHover 
                            responsive ={true}
                            fixedHeader = {props.header}
                            pagination
                            customStyles={customStyles}
                            fixedHeaderScrollHeight ={props.tableHeight}
                            progressPending={pending}
                            paginationPerPage={perPage}
                            // paginationTotalRows={totalRows}
	                		onChangeRowsPerPage={handlePerRowsChange}
                            //rowsPerPage={4}
                            striped  
                            onChangePage={handlePageChange}
                        />
                        </DataTableExtensions>
                        {
                            maxPage ? 
                                <div 
                                className="text-dark d-flex justify-content-end mb-1"
                                >** By default, we only load up to 10000 user details</div> 
                            : ""
                        }
            </div>
            }
    
    </div>
    );
};

export default MyComponent;