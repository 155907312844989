import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import tick from '../Assets/check-mark.png';
import error from '../Assets/cancel.png'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: 'white',
        color: 'white'
    },
};

function ClientCreationPopup({ modalIsOpen, closeModal,data }) {

    function handleClose() {
        closeModal()
    }

    let subtitle;



    return (
        <div className='position-relative w-50'>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div>
                    <button onClick={() => {
                            handleClose()
                            }} style={{ textDecoration: "none", border: "none", borderRadius:"16px", marginRight:"1rem", marginTop:"1rem" }} className="material-symbols-outlined position-absolute top-0 end-0">close
                    </button>
                    <div className="font-weight-bold text-primary">Client Creation Request</div><br/>
                    <div className="text-dark">
                        <div className="row">
                            <div className="col-5">Application</div>:
                            <div className="ms-2 col-5">{data?.data?.application}</div>
                        </div>
                        <div className="row">
                            <div className="col-5">Client Name</div>:
                            <div className="ms-2 col-5">{data?.data?.client_name}</div>
                        </div>
                        <div className="row">
                            <div className="col-5">Sign in URL</div>:
                            <div className="ms-2 col-5">{data?.data?.client_uri}</div>
                        </div>
                        <div className="row">
                            <div className="col-5">Redirect in URL</div>:
                            <div className="ms-2 col-5">
                            {data?.data?.redirect_uris.map((data)=>
                                <div>{data}</div>)}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ClientCreationPopup;