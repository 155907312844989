import React from "react";
import Modal from "react-modal";
import '../style.css';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    background: "white",
    color: "#6c757d",
  },
};

function OnboardModal({ modalIsOpen, closeModal, body }) {
  // let subtitle;

  // function afterOpenModal() {
  //   subtitle.style.color = "white";
  // }

  return (
    <div className="position-relative">
      <Modal
        isOpen={modalIsOpen}
         //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <span
            type="button"
            class="material-symbols-outlined text-white"
            style={{
              float: "right",
              background: "#AAAAAA",
              borderRadius: "15px",
            }}
            onClick={closeModal}
          >
            close
          </span>

          <div class="container mb-3">
            <p className="h4 fw-bold" style={{ color: "#045eb7" }}>
            Onboarding request submitted
            </p>

            <div className="">
                {body}
            </div>

            <div className="d-flex pt-4" style={{ color: "#045eb7", justifyContent:"right"}}>
              <button onClick={closeModal} className="btn btn-primary">Yes</button>
              <button onClick={closeModal} className="ms-2 btn btn-danger">No</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OnboardModal;
