import React,{useState,useEffect,useRef} from 'react';
import ForgerockMail from './forgerockemail';
import * as constant from '../Constants/constant';
import { useSelector, useDispatch } from "react-redux";
import {
    setSuccessAppnameAndLag,
    setFailureAppnameAndLag,
  } from "../../store/mailslice";
import Dynamicmailadmin from '../Dynamic_mail/dynamicmailadmin';
import { resetLanguage,resetCountry, setCountry, setLanguage } from '../../store/frmails';

export default function Template() {
    const [options, setOptions] = useState("");
    const [viewTab, setViewTab] = useState(0);
    const [template,setTemplate]=useState(0);
    const[preval,setPreval] = useState(0);
    const [payload,setPayload]=useState({
        app_name:"",
        locale:"",
        activity:""
    });
    const [loading, setLoading] = useState(false);
    const{allowedApp,country,laguage,initialLoader,appValue} = useSelector((state) => state.frmails);
    const{ role,appList} = useSelector((state) => state.dashboard); 
    const {successMailContent} = useSelector((state) => state.mailtemplate);
    const dispatch = useDispatch();
    const ref = useRef();

useEffect(()=>{
    setOptions("");
    setViewTab(0);
},[successMailContent.application_name,successMailContent.country,successMailContent.language]);

 function changeTemplate(e){
    switch (e.target.value) {
        case "1":
            setTemplate(Number(e.target.value));
            setPreval(e.target.value);
            setViewTab(2);
                break;
        case "2":
            setTemplate(Number(e.target.value));
            setPreval(e.target.value);
            setViewTab(2);
                break;
        case "3":
            setTemplate(Number(e.target.value));
            setPreval(e.target.value);
            setViewTab(2);
                break;
        case "4":
            setTemplate(Number(e.target.value));
            setPreval(e.target.value);
            setViewTab(2);
                break;
        case "EmailUpdateConfirmation":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
        case "PasswordUpdateConfirmation":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
        case "ProfileUpdateConfirmation":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
        case "Registration":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
        case "ResetPassword":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
        case "SendOTP":
                  setPayload(prevState => ({...prevState,activity: e.target.value }));
                  setViewTab(1);
                break;
                default:
                    setViewTab(0);
                    break;
 }
}
  return (
    <div>
       
        <div className="d-flex py-1 mt-2 mx-5 shadow-sm mb-4">
                                <div class="me-3 d-flex align-items-center form-check">
                                        <label class="form-check-label" htmlFor="flexRadioDefault1">
                                        Application<sup className="text-danger">*</sup>
                                        </label>
                                        <select class="form-select mx-2" aria-label="Default select example"
                                        value={successMailContent.application_name}
                                        onChange={(e) => {
                                            if(e.target.value!==""){
                                            dispatch(setCountry({"app":e.target.value,"value":appValue}));
                                            
                                            dispatch(
                                                setSuccessAppnameAndLag({
                                                  name: e.target.value,country: "", language:""
                                                })
                                            )
                                            dispatch(
                                              setFailureAppnameAndLag({
                                                name: e.target.value,country: "", language: ""
                                              })
                                          )
                                                setPayload(prevState => ({
                                                    ...prevState,
                                                    app_name: e.target.value
                                                }));
                                                dispatch(resetLanguage());
                                            }else{
                                                dispatch(
                                                    setSuccessAppnameAndLag({
                                                      name: "",country: "", language:""
                                                    })
                                                )
                                                dispatch(
                                                  setFailureAppnameAndLag({
                                                    name: "",country: "", language: ""
                                                  })
                                              )
                                                dispatch(resetCountry());
                                                dispatch(resetLanguage());
                                            }
                                        }
                                        }
                                            >
                                            <option value="">Select Application</option>
                                            {appList?.map ((data,index) => {
                                            return <option key={index} value={data?.appName}>{data?.aliasName}</option>
                                            })}
                                            </select>
                                </div>
                                <div class="me-3 d-flex align-items-center form-check">
                                        <label class="form-check-label" htmlFor="flexRadioDefault1">
                                        Country<sup className="text-danger">*</sup>
                                        </label>
                                        <select class="form-select mx-2" aria-label="Default select example"
                                        value={successMailContent.country}
                                        onChange={(e) => {
                                            dispatch(setLanguage({"name":e.target.value,"country":country}));
                                            dispatch(
                                                setSuccessAppnameAndLag({
                                                  country: e.target.value, language: ""
                                                })
                                            );
                                            dispatch(
                                              setFailureAppnameAndLag({
                                                country: e.target.value, language: ""
                                              })
                                          );
                                         
                                        }}
                                        >
                                        <option value="">Select Country</option>
                                            
                                            {country.map((data,index)=>  <option key={index} value={data.value}>{data.label}</option>)}
                                        
                                        </select>
                                </div>

                                <div class="me-3 d-flex align-items-center form-check">
                                        <label class="form-check-label" htmlFor="flexRadioDefault1">
                                        Language<sup className="text-danger">*</sup>
                                        </label>
                                        <select class="form-select mx-2" aria-label="Default select example"
                                         value={successMailContent.language}
                                        onChange={(e) => {
                                            dispatch(
                                                setSuccessAppnameAndLag({
                                                    language: e.target.value,
                                                })
                                            )
                                            dispatch(
                                              setFailureAppnameAndLag({
                                                language: e.target.value,
                                              })
                                          )
                                            setPayload(prevState => ({
                                                ...prevState,
                                                locale: e.target.value
                                            }));
                                        }}
                                        >
                                        <option value="">Select Language</option>
                                           {laguage.map((data,index)=> <option key={index} value={data.Value}>{data.Label}</option>)}
                                        </select>
                                </div>

                                <div class="me-3 d-flex align-items-center form-check">
                                        <label class="form-check-label" htmlFor="flexRadioDefault1">
                                        Template<sup className="text-danger">*</sup>
                                        </label>
                                        <select class="form-select mx-2" aria-label="Default select example"
                                        value={options}
                                        onChange={(e) => {
                                          setOptions(e.target.value);
                                        changeTemplate(e);
                                        }}
                                        >
                                        <option value="">Select Template</option>
                                            <option value={1}>Validation success notification</option>
                                            <option value={2}>Validation failure notification</option>
                                            <option value={3}>User inactivity notification</option>
                                            <option value={4}>User deactivated notification</option>
                                            <option value="Registration">User register notification</option>
                                            <option value="EmailUpdateConfirmation">Email update notification</option>
                                            <option value="PasswordUpdateConfirmation">Password update notification</option>
                                            <option value="ProfileUpdateConfirmation">Profile update notification</option>
                                            <option value="ResetPassword">Reset password notification</option>
                                            <option value="SendOTP">Send OTP notification</option>
                                        </select>
                                </div>
                                <div>
                                    {(viewTab ===1 && successMailContent.application_name && successMailContent.country && successMailContent.language ) &&  <div class="me-3 d-flex align-items-center form-check">
                                    <button class= "btn btn-primary" style={{width:"5em"}}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        :<div
                                        onClick={(event) => {
                                            setLoading(true)
                                            ref.current.handleActivityChange();
                                        }}
                                        >Get</div>}
                                    </button>
 
                                </div>}
                                </div>
                               
                            </div>
       {viewTab ===1 && <ForgerockMail ref={ref} payload={payload} setPayload={setPayload} setLoading={setLoading} /> }
       {(viewTab ===2 && successMailContent.application_name && successMailContent.country && successMailContent.language) &&  
       <Dynamicmailadmin template={template} setTemplate={setTemplate} preval={preval} setPreval={setPreval} /> }
        </div>
  )
}
