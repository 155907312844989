import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  csrf:"",
  show:false,
};

export const appSlice = createSlice({
    name: "appDetails",
    initialState,
    reducers: {
      setToken: (state, actions) => {
        state.csrf = actions.payload;
      },
      setShow: (state, actions) => {
        state.show = !state.show
      }
    }
});


export const {setToken,setShow } = appSlice.actions;
  export default appSlice.reducer;