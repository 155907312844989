import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import tick from '../Assets/check-mark.png';
import error from '../Assets/cancel.png'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: 'white',
        color: 'white'
    },
};

function MessagePopup({ modalIsOpen, closeModal, title, body, success}) {

      function handleClose() {
        closeModal()
      }
    return (
        <div className='position-relative'>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <div>
                <button onClick={() => {
                        handleClose()
                        }} style={{ textDecoration: "none", border: "none", borderRadius:"16px", marginRight:"1rem", marginTop:"1rem" }} className="material-symbols-outlined position-absolute top-0 end-0">close</button>
                <div style={{color:"#0463c3"}} className="font-weight-bold h3">{title}</div>
                <div style={{textAlign:"center",paddingTop:"2rem",color:"black"}}>
                
                <div class="d-flex align-items-center">
                    <div class="">
                        {success && <img src={tick} title="done icons" alt="" width="120" height="120"/>}
                        {!success && <img src={error} title="done icons" alt="" width="120" height="120"/>}
                    </div>
                    <div class="ms-3 pt-3">
                        <div>{body}</div>
                    </div>
                </div>

                </div>

                </div>
            </Modal>
        </div>
    );
}

export default MessagePopup;