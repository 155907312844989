import React, { Fragment } from "react";
import { useState } from "react";
import AmgenLogo from '../Assets/Group1.svg';
import "./index.css";
import Sidebar from "../Sidebar/Sidebar";
import MyComponent from "../Table/Table";
import Requestdata from "../Data/Requestdata";
import axios from 'axios';
import Viewpopup from "../Popup/Viewpopup";
import Editpopup from "../Popup/Editpopup";
import * as constant from '../Constants/constant';
import { updateLoader } from "../../store/slice";
import { useSelector, useDispatch } from "react-redux";
import '../style.css';
import Popover from '@mui/material/Popover';
import UserPopover from "../userpopover";
import {
    setShow
} from "../../store/appslice"
import { sanitizeUserInput } from "../../Helper/sanitize";


function Accesshome() {

    const [data, setData] = useState("");
    const [accessreq, setAccessreq] = useState("");
    const [options, setOptions] = useState([]);
    const [r, setR] = useState("");
    const user = localStorage.getItem("username");
    // const role = localStorage.getItem("role");
    const{role} = useSelector((state) => state.dashboard);
    const { Loader } = useSelector((state) => state.onboard);
    const { csrf, show } = useSelector((state) => state.app);
    axios.defaults.withCredentials = true;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const manoption = [{"application_name":"demoApp","status":"Active"},{"application_name":"dashboard","status":"Active"},{"application_name":"demoOne","status":"Active"},{"application_name":"globalnewflow","status":"Active"},{"application_name":"testDemoDemo","status":"Active"},{"application_name":"EnbrelResources","status":"Active"},{"application_name":"AmgenAssistEU","status":"Active"},{"application_name":"demodemo","status":"Active"},{"application_name":"AmgenBrand","status":"Active"},{"application_name":"agGMIFrance","status":"Active"},{"application_name":"testDemotest","status":"Active"},{"application_name":"demoThree","status":"Active"},{"application_name":"AmgenCompass","status":"Active"},{"application_name":"GMIUS","status":"Active"},{"application_name":"AmgenISS","status":"Active"},{"application_name":"demoTwo","status":"Active"},{"application_name":"GMIFR","status":"Active"},{"application_name":"agGMISpain","status":"Active"},{"application_name":"AmgenC360Hub","status":"Active"}]

    React.useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/selfservice/api/csrftoken`,
        {
        headers: { ...constant.headers },
        }            
        )
        
        .then((res) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,[{key:"email", value:user}],
        {
            headers: { ...constant.headers,"csrf-token": res.data.data.csrfToken },
        }
        )
        .then((response) => {
            setData(response.data.data);
            
            dispatch(updateLoader());
        })
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-all-app-list`,
        {
            headers: { ...constant.headers,"csrf-token": res.data.data.csrfToken },
        }
        )
        .then((response) => {setOptions(response.data.data)})
        .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
    }) .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
        return () => {dispatch(updateLoader())}
    }, []);

    const handleChange =(event) => {
        let value = event.target.value;
        setAccessreq(value)
    }

    const handleRequest = () => {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/create-request`,{
            application_name :accessreq,
            request_type : "access",
            email : localStorage.getItem("username")
        },
        {
            headers: { ...constant.headers,"csrf-token": csrf },
        }
        )
        .then((response) => {
            dispatch(updateLoader());
            setAccessreq("")
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,[{key:"email", value:user}],
            {
                headers: { ...constant.headers,"csrf-token": csrf },
            }
            )
            .then((response) => {
                setData(response.data.data);
                dispatch(updateLoader());
            })
            .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
        })
        .catch(error => {
            setAccessreq("")
            console.log(sanitizeUserInput(JSON.stringify(error)))
        }) 
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal(e,r) {
        setR(r);
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    function openModal2(e,r) {
        setR(r);
        setIsOpen2(true);
    }
    function closeModal2() {
        setIsOpen2(false);
    }
    function handledelete(r) {
        dispatch(updateLoader());

        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/delete-user-request?request_id=${r.request_id}`,
            {
                headers: { ...constant.headers,"csrf-token": csrf },
            }
            )
            .then((response) => {
                

                axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-request`,[{key:"email", value:user}],
            {
                headers: { ...constant.headers,"csrf-token": csrf },
            }
            )
            .then((response) => {
                setData(response.data.data);
                dispatch(updateLoader());
            })
            .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
            })
            .catch(error => {console.log(sanitizeUserInput(JSON.stringify(error)))}) 
    }

    



    const req_data = data.data;

    const columns = [
        {
            name: 'Request ID',
            cell: (row) => row.request_id,
            
        },
        {
            name: 'Application Name',
            cell: (row) => row.application_name,
            
        },
        {
            name: 'Request created on',
            cell: (row) => row.requested_date,
        },
        {
            name: 'Status',
            cell: (row) => row.status,
        },
        {
            name: 'Actions',
            cell : (r => {
                
                return (
                    <div>
                        { r.status == "pending" ?
                        <>
                    <span type="button" class="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="View" onClick={(e) => {openModal(e,r)}}>visibility</span>
                    {/* <span type="button" class="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Edit" onClick={(e) => {openModal2(e,r)}}>edit</span>
                    <span type="button" class="material-symbols-outlined iconfontsize" data-toggle="tooltip" title="Delete" onClick={() => 
                        {
                            handledelete(r)}
                        }>delete</span> */}
                    </>
                    :
                    <>
                    <span type="button" class="material-symbols-outlined iconfontsize" onClick={(e) => {openModal(e,r)}}>visibility</span>
                    
                    </>
                    }
                    </div>
                )
            })
        },
        
    ];

    
    return (

        <main className={show ? 'space-toggle' : null} style={{margin:0}}>
            

            <aside style={{ background: "#045EB7" }} className={`sidebar ${show ? 'show' : null}`}>
                <nav className='nav'>
                    <div>
                        <div className='header-toggle' onClick={() => dispatch(setShow())}>
                            <span className='fas fa-bars sidebarfirst'></span>
                        </div>
                        <Sidebar show={show}></Sidebar>
                    </div>
                </nav>
            </aside>
                <div className="maintop">
                <header style={{ background: "white",zIndex:"-1" ,height:"7vh",padding:0}} className={`header ${show ? 'space-toggle' : null}`}>
        <span><img style={{ paddingLeft: "1rem",height:"40px" }} src="https://amgen-passport-cdn-public-resources.s3.eu-central-1.amazonaws.com/passport/logo/amgen-blue-new.svg" alt=""></img></span>
        <div>
            {/* <span className="material-symbols-outlined headercolor">search</span>&nbsp;&nbsp;
            <span className="material-symbols-outlined headercolor">notifications</span>&nbsp;&nbsp; */}
            {/* <span className="material-symbols-outlined headercolor" type="button" data-toggle="tooltip" title={user}>account_circle</span> */}
        
            <span 
                                aria-describedby={id} 
                                role="button" 
                                class="material-symbols-outlined headercolor fs-1 me-3" 
                                onClick={handleClick}
                                >account_circle
                            </span>
                            
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                            >
                                <UserPopover/>
                            </Popover>
        </div>
        </header>
        <div className="ms-4 mt-2 fw-bold" style={{color:"#0463c3"}}>Application Acess Request</div>



                    <div className="bodyfirst">
                        <div style={{ padding: " 2rem 0rem 0rem 2rem", display: "flex" }}>
                            <div>
                                <div style={{ padding: "2rem 0 1rem 2rem" }}>Select application from list to create request</div>



                                <div class="dropdown" style={{ paddingLeft: "2rem" }}>
                                    <select class="form-select" aria-label="Default select example" placeholder="Select Application" style={{ width: "21rem", height: "35px", marginBottom: "2rem" }} 
                                    onChange={(event) => {handleChange(event)}}>
                                        <option selected>Select Application</option>
                                        {manoption.map ((option) => {
                                            return <option>{option["application_name"]}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="separator">Or</div>

                                <div style={{ marginLeft: "2rem" }}>Enter new application name</div>

                                <input style={{ marginBottom: "4rem", marginLeft: "2rem", width: "21rem", border: "1px solid", borderRadius: "5px", height: "2rem" }} value={accessreq} onChange={(event) => {handleChange(event)}} type="text"></input> <br></br>

                                <button style={{marginLeft: "5rem", width:"223px", height:"57px", background:"white", color:"#035EB7", border:"1px #035EB7 solid"}} onClick={() => {handleRequest()}}>Request Access</button>

                            </div>


                            <div class="vl"></div>

                            <div className="accesstable w-100 me-2">
                                <h6 style={{ color: "#045EB7" }}><b>My Request history</b></h6>
                                <MyComponent fileName={"My_request"} tableHeight="80vh" columns={columns} data={data} export={true} filter={true}/>
                                <Viewpopup modalIsOpen={modalIsOpen} r={r} closeModal={closeModal}></Viewpopup>
                                <Editpopup modalIsOpen2={modalIsOpen2} r={r} closeModal2={closeModal2}></Editpopup>

                            </div>
                        </div>
                    </div>
                </div>
        </main>
    )
}

export default Accesshome;
