import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allowedApp: [],
  appValue: [],
  laguage: [],
  country: [],
  orginalUrl: [],
  redirectUrl: [],
};

export const frmailsSlice = createSlice({
  name: "frmails",
  initialState,
  reducers: {
    addOrginUrl: (state, actions) => {
      if (actions.payload !== "") {
        state.orginalUrl.push(actions.payload);
      }
    },
    removeOrginUrl: (state, actions) => {
      state.orginalUrl = state.orginalUrl.filter(
        (d, i) => i !== actions.payload
      );
    },
    addRedirectUrl: (state, actions) => {
      if (actions.payload !== "") {
        state.redirectUrl.push(actions.payload);
      }
    },
    removeRedirectUrl: (state, actions) => {
      state.redirectUrl = state.redirectUrl.filter(
        (d, i) => i !== actions.payload
      );
    },
    setAllowedMailApplicationlist: (state, actions) => {
      let adminApp = actions.payload?.map((data) => data.application_name);
      state.appValue = actions.payload;
      state.allowedApp = adminApp;
    },
    setCountry: (state, actions) => {
      if(actions.payload.app!==""){
        const ctry = actions.payload?.value.filter(
          (data, index) => data.application_name === actions.payload.app
        );
        if(ctry[0].mailBlastCountries!==undefined){
          state.country = ctry[0].mailBlastCountries;
        }else{
          state.country = [];
        }
      }else{
        state.country = [];
      }
      
    },
    resetLanguage: (state, actions) => {
      state.laguage = [];
    },
    setLanguage: (state, actions) => {
      if(actions.payload.name!==""){
      const lan = actions.payload.country.filter(
        (data, index) => data.value === actions.payload.name
      );
      state.laguage = lan[0]?.supportedlang;
      }else{
        state.laguage =[];
      }
    },
    resetCountry: (state, actions) => {
      state.country = [];
    },
  },
});

export const {
  addOrginUrl,
  addRedirectUrl,
  removeOrginUrl,
  removeRedirectUrl,
  setAllowedMailApplicationlist,
  setCountry,
  resetCountry,
  resetLanguage,
  setLanguage,
} = frmailsSlice.actions;
export default frmailsSlice.reducer;
